import { SET_TOKEN,DELETE_TOKEN,CHECK_LOGIN,CHECK_LOGIN_PIN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actionTypes';

const initialState = {
  loginError: "aaa",
  message: null, 
  loading: false,
  token: null,
  content: null
}

const login = (state = initialState, action) => {
    switch (action.type) {
      case CHECK_LOGIN:
          state = {
              ...state,
              loading: true
          }
          break;
          case CHECK_LOGIN_PIN:
              state = {
                  ...state,
                  loading: true
              }
              break;
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false
            }
            break;

        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;

        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            }
            break;
        case SET_TOKEN:
          state = {
            ...state,
            ...action.payload,
          }
          break;
        case DELETE_TOKEN:
          state = {
            token: null,
            content: null
          }
          break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;
