import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table,Nav, NavItem, NavLink, UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { AvForm, AvField, AvInput,AvRadioGroup,AvRadio } from "availity-reactstrap-validation";
import 'react-slidedown/lib/slidedown.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SlideDown } from "react-slidedown";

import { apiRequest, getAccessToken, getLoggedInUser } from '../../helpers/backend_helper';
import { logoutUser, apiError } from '../../store/actions';

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [day,month,year].join('-');
}

class Quotes extends Component {
  componentDidMount() {
    apiRequest("GET","quotes").then(res => {
        return res.data
    }).then((data) => {

      this.setState({ data: data, loaded:1})
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Quotes", link : "#" },
            ],
            isAlertOpen : false,
            modal_static : false,
            data : [],
            hidden:true,
            loaded:0,
            error:0,
            errormsg:"",
            trustedPlatform:false,
            modal_data:{
              id:"new",
              name:"",
              username:"",
              email:"",
              type:"admin"
            },
            modal_error:0,
            modal_delete:false,
            delete_error:0,
            delete_user:"",
            signedOu:0,
            activeTab:0
        }
    }

    downloadQuote = (quoteId) => {
      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push("id[]" + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
      }
      const authToken = getAccessToken();
      window.open(process.env.REACT_APP_BASE_URL+'admin/quotes/'+quoteId+"?&access_token="+authToken, '_blank',["noopener"]);
    }

    toggleTab = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
            activeTab: tab,
        });
      }
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div  className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Quotes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                          {this.state.errormsg}
                        </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                      <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink onClick={() => { this.toggleTab('0'); }} className={classnames({ active: this.state.activeTab == '0' }, "font-weight-bold p-3")}>Active<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => (new Date(el.expires).getTime() > new Date().getTime())).length}</span></NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { this.toggleTab('1'); }} className={classnames({ active: this.state.activeTab == '1' }, "p-3 font-weight-bold")}>Expired<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => (new Date(el.expires).getTime() <= new Date().getTime())).length}</span></NavLink>
                                        </NavItem>
                                      </Nav>
                                        <div  className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{borderCollapse:"collapse", borderSpacing : 0, width:"100%"}}>
                                                <thead  className="thead-light">
                                                    <tr>
                                                        <th>Quote Number</th>
                                                        <th>Email</th>
                                                        <th>Name</th>
                                                        <th>Created</th>
                                                        <th>Expires</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.data.length == 0)?(
                                                      <tr><td colSpan={5} className="text-center">No quotes</td></tr>
                                                    ) :
                                                      this.state.data.filter((el)=>{
                                                        if(new Date(el.expires).getTime() > new Date().getTime() && this.state.activeTab == 0) {
                                                          return 1;
                                                        } else if (new Date(el.expires).getTime() <= new Date().getTime() && this.state.activeTab == 1) {
                                                          return 1;
                                                        }
                                                      }).map((quote, key) =>{
                                                        return <tr key={key}>
                                                            <td>{quote.quoteNo}</td>
                                                            <td>{quote.email}</td>
                                                            <td>{quote.firstname} {quote.lastname}</td>
                                                            <td>{formatDate(quote.created)}</td>
                                                            <td>{formatDate(quote.expires)}</td>
                                                            <td><Button color="primary" outline onClick={()=>{this.downloadQuote(quote.quoteId)}}><i className='mr-2 ri-file-download-line'></i>Quote PDF</Button></td>
                                                        </tr>
                                                      })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Quotes));
