import React from "react";
import { Route, Redirect } from "react-router-dom";

import { getLoggedInUser } from "../helpers/backend_helper";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAdminOnly,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      let user = getLoggedInUser();
      if (
        isAuthProtected &&
        (user == null || (user?.role != "admin" && isAdminOnly == true))
      ) {
        if (props.location.pathname.split("?")[0] != "/login") {
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: "?page=" + props.location.pathname,
                state: { from: props.location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
      }

      return (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
