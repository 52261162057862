import React, { Component } from 'react';
import { Button } from "reactstrap";

//Import images
import errorImg from "../../assets/images/error-img.png";

class Error404 extends Component {

  goBack = ()=>window.history.back()
    render() {
        return (
            <React.Fragment>
              <div className="preloader">
                <div className="text-center loader">
                    <h1 className="font-weight-bold text-error mb-3"><img src={errorImg} alt="" className="error-img w-25"/></h1>
                    <h3 className="">Sorry, page not found</h3>
                    <div className="mt-5 text-center">
                      <Button outline color="primary" onClick={()=>this.goBack()}>Go back</Button>
                    </div>
                </div>
              </div>
            </React.Fragment>
        );
    }
}

export default Error404;
