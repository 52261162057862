import React, { Component } from 'react';
import { Alert, Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledTooltip, Input, Label, Button, Dropdown, DropdownItem, DropdownToggle, ButtonGroup, UncontrolledDropdown, DropdownMenu } from "reactstrap";
import classnames from 'classnames';

import { ToastContainer, toast } from 'react-toastify/dist/react-toastify.js';
import 'react-toastify/dist/ReactToastify.min.css';

import { MDBDataTable } from "mdbreact";
import print from './print'
import { Datatable } from "../../components/Datatable";
import "./datatables.scss";
import {
  updateOrderNum
} from "../../store/actions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getAccessToken, getLoggedInUser } from '../../helpers/backend_helper';

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [day,month,year].join('-');
}

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Orders", link : "#" },
            ],
            activeTab:'1',
            filter: ['new','processing'],
            loaded:0,
            error:0,
            errormsg:"",
            data:[],
            checkAll:0,
            dropdown:false,
            loadingPackingSlip: false,
            loadingLabels: false,
            loadingOverview: false,
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    componentDidMount(){
      //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
      const search = this.props.location.search;
      const tab = new URLSearchParams(search).get("tab");
      if(tab) {
        this.toggleTab(tab)
      }
      console.log(tab)

      apiRequest("GET","orders").then(res => {
          return res.data
      }).then((data) => {
        var data1 = data.map((el) => {return {checkbox:0, ...el}})
        this.setState({ data: data1, loaded:1 })
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }

    toggleTab = (tab) => {
      if(parseInt(tab) < 1 || parseInt(tab) > 4){
        tab = "1"
      }
      if (this.state.filter !== tab) {
        var filter
        switch(tab){
          case '1':
            filter= ['new','processing'];
            break;
          case '2':
            filter= ['ready','completed'];
            break;
          case '3':
            filter= ['archived'];
            break;
          case '4':
            filter= ['on-hold'];
            break;
        }
        var checkAll = 1
        let fields = this.state.data.filter((el) => filter.includes(el.status.toLowerCase()))
        if(fields.length == 0 || fields.map((el)=>el.checkbox).includes(0)){
          checkAll = 0
        }
          this.setState({
              activeTab: tab,
              filter:filter,
              checkAll:checkAll
          });
      }
      const search = this.props.history.location.search;
      let params = new URLSearchParams(search)
      params.set("tab",tab)
      this.props.history.replace(this.props.history.location.pathname+"?"+params)
    }

    updateStates = (state) => {
      const data = this.state.data.filter((el) => el.checkbox == 1).map(el=>{
        if(state == "ship"){
          return {
            id: el.order,
            state: el.shippingType
          }
        } else {
          return {
            id: el.order,
            state: state
          }
        }
      })
      apiRequest("POST",`orders/state`,data).then(res => {
        const newData = this.state.data.map(el=>{
          if(el.checkbox == 1){
            if(state == "ship"){
              return {
                ...el,
                checkbox: 0,
                status: el.shippingType
              }
            } else {
              return {
                ...el,
                checkbox: 0,
                status: state
              }
            }
          } else {
            return el
          }
        })
        this.props.updateOrderNum(newData.filter((el) => ["new","processing"].includes(el.status.toLowerCase())).reduce((a,b) => a+1,0))
        this.setState({
            data: newData,
            checkAll: 0,
        });
      }).catch(err=>{
        if (!err.response) {
          toast.error("Can't connect to admin API, try again later.")
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 404) {
          toast.error("Order cannot be found, try refreshing the page.")
          return;
        } else if (err.response.status === 500) {
          toast.error("An internal server error occured, try again later.")
          return;
        }
        toast.error("An unknown error occured, try again later.")
      })
    }

    materialOverview = () => {
      this.setState({loadingOverview: true})
      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push("id[]" + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
      }

      let params = this.state.data.filter((el)=>{return parseInt(el.checkbox)}).map((el)=>{
        return el.order
      })
      const token = getAccessToken()
      print({
        url: process.env.REACT_APP_BASE_URL+'admin/material-overview?'+encodeQueryData(params)+"&access_token="+token,
        type: "overview",
        onLoadingEnd: () => this.setState({loadingOverview: false}),
        onError: () => {
          toast.error('An error occured while generating the overview, try again later.');
          this.setState({loadingOverview: false})
        },
      })
      // window.open(, '_blank',["noopener"]);
    }

    getLabels = (ids) => {
      this.setState({loadingLabels: true})
      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push("id[]" + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
      }

    
      const token = getAccessToken()
      print({
        url: process.env.REACT_APP_BASE_URL+'admin/labels?'+encodeQueryData(ids)+"&access_token="+token,
        type: "label",
        onLoadingEnd: () => this.setState({loadingLabels: false}),
        onError: (e) => {
          if(e == "Not Found") {
            toast.warning('No labels found.');
            this.setState({loadingLabels: false})
            return
          }
          console.error(e)
          toast.error('An error occured while retrieving labels, try again later.');
          this.setState({loadingLabels: false})
        },
      })
    }

    createLabels = (announce = true) => {
      this.setState({loadingLabels: true})
      const data = this.state.data.filter((el) => el.checkbox == 1).map(el=>{
        return {
          id: el.order
        }
      })
      apiRequest("POST",`orders/labels`,{ids:data, announce}).then(res => {
        console.log(res.data)
        const newData = this.state.data.map(el=>{
          if(el.checkbox == 1){
            return {
              ...el,
              checkbox: 0,
              parcel_status: res.data.labels.find((el2)=>el2.id == el.order)?.message || el.parcel_status
            }
          } else {
            return el
          }
        })

        for (let i in res.data.errors){
          console.error(res.data.errors[i])
          toast.error(res.data.errors[i].message)
        }
        this.setState({
            data: newData,
            checkAll: 0,
        });

        let params = res.data.labels.map((el)=>{
          return el.id
        })
        if(announce) {
          this.getLabels(params)
        }
        this.setState({loadingLabels: false})
      }).catch(err=>{
        this.setState({loadingLabels: false})
        if (!err.response) {
          console.log(err)
          toast.error("Can't connect to admin API, try again later.")
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 404) {
          toast.error("Order cannot be found, try refreshing the page.")
          return;
        } else if (err.response.status === 500) {
          toast.error("An internal server error occured, try again later.")
          return;
        }
        toast.error("An unknown error occured, try again later.")
      })
    }

    packingSlips = () => {
      this.setState({loadingPackingSlip: true})
      function encodeQueryData(data) {
        const ret = [];
        for (let d in data)
          ret.push("id[]" + '=' + encodeURIComponent(data[d]));
        return ret.join('&');
      }

      let params = this.state.data.filter((el)=>{return parseInt(el.checkbox)}).map((el)=>{
        return el.order
      })
      const token = getAccessToken()
      print({
        url: process.env.REACT_APP_BASE_URL+'admin/packing_slip?'+encodeQueryData(params)+"&access_token="+token,
        type: "packing_slip",
        onLoadingEnd: () => this.setState({loadingPackingSlip: false}),
        onError: () => {
          toast.error('An error occured while generating the packing slips, try again later.');
          this.setState({loadingPackingSlip: false})
        },
      })
      // window.open(process.env.REACT_APP_BASE_URL+'admin/packing_slip?'+encodeQueryData(params)+"&access_token="+token, '_blank',["noopener"]);
    }

    checkboxes = (event) => {
      let id = event.target.getAttribute('data-id')
      if(id == "all"){
        let data = this.state.data
        let checkAll = this.state.checkAll
        var filter = this.state.filter
        data.forEach(function(part, index) {
          if(filter.includes(data[index].status.toLowerCase()))
            data[index].checkbox = !checkAll?1:0;
        },data);

        this.setState({data:data,checkAll:(!checkAll?1:0)})
      } else {
        let data = this.state.data
        let index = data.findIndex((el)=>el.order == id)
        data[index].checkbox = event.target.checked?1:0
        var checkAll = 1

        if(data.filter((el) => this.state.filter.includes(el.status.toLowerCase())).map((el)=>el.checkbox).includes(0)){
          checkAll = 0
        }
        this.setState({data:data,checkAll:checkAll})
      }
    }
    clickEv = (event,to) => {
      if(event.target.className == "custom-control-label" || event.target.className == "custom-control-input form-check-input"){
        return
      }
      if(window.event.ctrlKey){
        window.open(to,"_blank")
      } else {
        this.props.history.push(to)
      }
    }

    dropdown =  ()=>{
      this.setState({
        dropdown: !this.state.dropdown
      })
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        const columns = [
          {
            Header: <div className="custom-control custom-checkbox"> <Input type="checkbox" className="custom-control-input" id="ordercheck" data-id="all" checked={this.state.checkAll} onChange={this.checkboxes}/><Label className="custom-control-label" htmlFor="ordercheck">&nbsp;</Label></div>,
            accessor: 'checkbox',
            orderAccessor: 'order',
            sortable: false,
            Cell: ({value, row, column})=>{
              let order = row.original[column.orderAccessor]
              return <div className="custom-control custom-checkbox">
                 <Input type="checkbox" className="custom-control-input" id={"ordercheck"+order} data-id={order} checked={value} onChange={this.checkboxes}/>
                 <Label className="custom-control-label" htmlFor={"ordercheck"+order}>&nbsp;</Label>
              </div>
            }
          },{
            Header: "Order",
            accessor: 'order',
            Cell: ({value})=>{
              return <Link to={"/orders/"+value} className="text-dark font-weight-bold">{value}</Link>
            }
          },{
            Header: "Customer",
            accessor: 'customer',
          },{
            Header: "Company",
            accessor: 'company',
          },{
            Header: "Order Date",
            accessor: 'orderDate',
            Cell: ({value, row, column}) => {
              return formatDate(value)
            }
          },{
            Header: "Due Date",
            accessor: 'dueDate',
            Cell: ({value, row, column}) => {
              return formatDate(value)
            },
          },{
            Header: "Total",
            accessor: 'price',
            Cell: ({value, row, column}) => {
              return <span>&euro; {value || "0.00"}</span>
            }
          },{
            Header: "Shipping type/status",
            accessor: 'shippingType',
            parcelStatusAccessor: 'parcel_status',
            Cell: ({value, row, column}) => {
              let parcelStatus = row.original[column.parcelStatusAccessor]

              return (
                <div>
                  <div className="badge-soft-info badge font-size-14">
                    {value=="Shipped"?"Ship":"Pick up"}
                  </div>
                  {parcelStatus?
                    <div className="ml-2 badge-soft-warning badge font-size-14">
                      {parcelStatus}
                    </div>
                  :null}
                </div>
              )

            }
          },
          {
            Header: "Status",
            accessor: 'status',
            shippingTypeAccessor: 'shippingType',
            Cell: ({value,row, column}) => {
              let shippingType = row.original[column.shippingTypeAccessor]
              var color;
              var text;
              switch(value.toLowerCase()){
                case "new":
                  color = "badge-soft-danger badge font-size-14";
                  text= "New";
                  break;
                case "on-hold":
                  color = "badge-soft-dark badge font-size-14";
                  text= "On Hold";
                  break;
                case "processing":
                  color = "badge-soft-warning badge font-size-14";
                  text= "Processing";
                  break;
                case "ready":
                  color = "badge-soft-info badge font-size-14";
                  text= "Ready";
                  break;
                case "completed":
                  color = "badge-soft-success badge font-size-14";
                  text= shippingType;
                  break;
                default:
                  color = "badge-soft-light badge font-size-14";
                  text= value.charAt(0).toUpperCase() + value.slice(1);
                  break;
              }
              return <div className={color}>{text}</div>
            }
          },
        ]

        const formattedData = this.state.data.filter((el) => this.state.filter.includes(el.status.toLowerCase())).map((el,i)=>{
          return {
          checkbox:el.checkbox,
          // <div className="custom-control custom-checkbox">
          //        <Input type="checkbox" className="custom-control-input" id={"ordercheck"+el.order} data-id={el.order} checked={el.checkbox} onChange={this.checkboxes}/>
          //        <Label className="custom-control-label" htmlFor={"ordercheck"+el.order}>&nbsp;</Label>
          //    </div>,
          order: el.order,
          customer: el.customer,
          company: el.company,
          orderDate: el.orderDate,
          dueDate: el.dueDate,
          price: el.price,
          shippingType: el.shippingType,
          status: el.status,
          parcel_status: el.parcel_status,
          clickEvent: (ev) => {
            if(ev.target.getElementsByClassName("custom-control custom-checkbox").length != 0 ) {
              return
            } else if(ev.target.tagName == "A") {
              return
            }
            this.clickEv(ev,"/orders/"+el.order)
          }
            
        }})
        const checked = this.state.data.reduce((a,b) => a+(parseInt(b.checkbox)||0),0)
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                      <Breadcrumbs title="Orders" breadcrumbItems={this.state.breadcrumbItems} />
                      <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                        {this.state.errormsg}
                      </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="pt-0">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('1'); }} className={classnames({ active: this.state.activeTab === '1' }, "font-weight-bold p-3")}>Open<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => ["new","processing"].includes(el.status.toLowerCase())).length}</span></NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('2'); }} className={classnames({ active: this.state.activeTab === '2' }, "p-3 font-weight-bold")}>Completed<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => ["completed","ready"].includes(el.status.toLowerCase())).length}</span></NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('3'); }} className={classnames({ active: this.state.activeTab === '3' }, " p-3 font-weight-bold")}>Archived<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => ["archived"].includes(el.status.toLowerCase())).length}</span></NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('4'); }} className={classnames({ active: this.state.activeTab === '4' }, " p-3 font-weight-bold")}>On Hold<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => ["on-hold"].includes(el.status.toLowerCase())).length}</span></NavLink>
                                            </NavItem>
                                            <div className={`card popup ${checked>0 && "show"}`}>
                                              <div><p className="m-0 font-weight-bold">{checked>1?`${checked} Orders selected`:`${checked} Order selected`}</p></div>
                                              <Row className="buttons">
                                                <Col xs={12} sm={6} md={6} lg={"auto"} className="text-center pb-1 px-1">
                                                  <Dropdown isOpen={this.state.dropdown} toggle={this.dropdown} direction={"up"}>
                                                    <DropdownToggle className="btn-sm" color="success">Set State <i className='fas fa-chevron-up font-size-10'></i></DropdownToggle>
                                                    <DropdownMenu>
                                                      <DropdownItem onClick={e=>this.updateStates("new")}>
                                                        <div className='badge-soft-danger badge font-size-14 w-100'>New</div>
                                                      </DropdownItem>
                                                      <DropdownItem onClick={e=>this.updateStates("processing")}>
                                                        <div className='badge-soft-warning badge font-size-14 w-100'>Processing</div>
                                                      </DropdownItem>
                                                      <DropdownItem onClick={e=>this.updateStates("ready")}>
                                                        <div className='badge-soft-info badge font-size-14 w-100'>Ready</div>
                                                      </DropdownItem>
                                                      <DropdownItem onClick={e=>this.updateStates("completed")}>
                                                        <div className='badge-soft-success badge font-size-14 w-100'>Shipped/Picked up</div>
                                                      </DropdownItem>
                                                      <DropdownItem onClick={e=>this.updateStates("archived")}>
                                                        <div className='badge-soft-light badge font-size-14 w-100'>Archived</div>
                                                      </DropdownItem>
                                                      <DropdownItem onClick={e=>this.updateStates("on-hold")}>
                                                        <div className='badge-soft-dark badge font-size-14 w-100'>On Hold</div>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </Dropdown>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={"auto"} className="text-center pb-1 px-1">
                                                  <Button size="sm" color="primary" className="waves-effect waves-light" disabled={this.state.loadingOverview} onClick={e=>this.materialOverview()}>{this.state.loadingOverview?<div className="spinner-grow mr-1"></div>:<i className="fas fa-list mr-1"></i>}Create overview</Button>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={"auto"} className="text-center pb-1 px-1">
                                                  <Button size="sm" color="info" className="waves-effect waves-light" disabled={this.state.loadingPackingSlip} onClick={e=>this.packingSlips()}>{this.state.loadingPackingSlip?<div className="spinner-grow mr-1"></div>:<i className="fas fa-print mr-1"></i>}Packing Slips</Button>
                                                </Col>
                                                <Col xs={12} sm={6} md={6} lg={"auto"} className="text-center pb-1 px-1">
                                                  <UncontrolledDropdown direction={"up"}>
                                                    <ButtonGroup>
                                                      <Button size="sm" color="warning" className="waves-effect waves-light" disabled={this.state.loadingLabels} onClick={e=>this.createLabels()}>{this.state.loadingLabels?<div className="spinner-grow mr-1"></div>:<i className="fas fa-dolly mr-1"></i>}Create Labels</Button>
                                                      <DropdownToggle className="btn-sm" color="warning"><i className='fas fa-chevron-up font-size-10'></i></DropdownToggle>
                                                    </ButtonGroup>
                                                    <DropdownMenu>
                                                      <DropdownItem disabled={this.state.loadingLabels} onClick={e=>this.createLabels(false)}>
                                                        <span role="button">Create, don't announce</span>
                                                      </DropdownItem>
                                                      <DropdownItem disabled={this.state.loadingLabels} onClick={e=>this.getLabels(this.state.data.filter(el=> el.checkbox==1).map(el=> el.order))}>
                                                        <span role="button">Get created labels</span>
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                </Col>
                                              </Row>
                                            </div>
                                        </Nav>
                                        {/* <MDBDataTable hover responsive data={data} entries={20} className="mt-4" /> */}
                                        <Datatable history={this.props.history} resetPage={this.state.activeTab} columns={columns} data={formattedData}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
                <ToastContainer
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                >
                </ToastContainer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return { ...state.Layout };
  };

export default withRouter(connect(mapStateToProps, { logoutUser, apiError,updateOrderNum })(Orders));
