import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table,UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField, AvInput,AvRadioGroup,AvRadio } from "availity-reactstrap-validation";
import 'react-slidedown/lib/slidedown.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SlideDown } from "react-slidedown";

import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Customers extends Component {
  componentDidMount() {
    apiRequest("GET","coupons").then(res => {
      if(res.status == 200){
        return res.data
      }
    }).then((data) => {
      this.setState({ data: data, loaded:1 })
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

  parseCoupon = (values) => {
    var code = values.code;
    var discountType = values.discountType;
    var discountVal = values.discountVal;
    var validUntil = values.validUntil;
    var discount;
    if(discountType=="shipping"){
      discount = `<b>Free shipping</b> on the order`
    } else if(discountType=="amount"){
      discount = `<b>&euro;${discountVal}</b> off on the order`
    } else {
      discount = `<b>${discountVal}%</b> off on cutting and engraving time`
    }
    var color="";
    if(new Date().getTime() > new Date(validUntil)){
      color="text-danger"
    }

    let usesColor = values.usesLeft==0 && values.uses!=0?"text-danger":""

    let uses = values.uses==0?"Unlimited":`${values.uses} (${values.usesLeft} left)`

    return {
      code: code,
      discount: discount,
      date: this.formatDate(validUntil),
      uses,
      usesColor,
      color:color
    }
  }

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day,month,year].join('/');
}

formatDate2 = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [year,month,day].join('-');
}
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Coupons", link : "#" },
            ],
            isAlertOpen : false,
            modal_static : false,
            data : [],
            hidden:true,
            loaded:0,
            error:0,
            errormsg:"",
            code:"",
            discountType:"",
            discountVal:"",
            validUntil:"",
            uses: 0,
            usesLeft: 0,
            id:"new",
            modal_error:0,
            delete_coupon:"",
            modal_delete:false,
            delete_error:0
        }
        this.addCoupon.bind(this);
    }


    discountType = (event, value) => {
      if(value == undefined)
        return
      if(value == "percentage" || value == "amount"){
        if(this.state.hidden){
          this.setState({hidden:false,discountType: value})
        }
      } else {
        if(!this.state.hidden)
          this.setState({hidden:true,discountType: value})
      }
    }
    genCode = () => {
      this.setState({code:Math.random().toString(36).substr(2, 14)})
    }

    updateData = (event,i) => {
      this.setState({ [event.target.name]: i})
    }
    newCoupon = () => {
      this.setState({
        modal_static: true,
        isAlertOpen : false,
        hidden:true,
        discountType:"",
        discountVal:"",
        validUntil:"",
        uses: 0,
        usesLeft: 0,
        id:"new" })
    }

    editCoupon = (i) => {
      var data = {...this.state.data[i]}
      data.validUntil = this.formatDate2(data.validUntil)
      console.log(data)
      this.setState({
        modal_static: true,
        isAlertOpen : false,
        hidden:data.discountType=="shipping"?true:false,
        ...data })
    }

    addCoupon = (event, values) => {
      //Assign values to the variables
      var code = values.code;
      var discountType = values.discountType;
      var discountVal = values.discountVal;
      var validUntil = values.validUntil;
      let uses = values.uses || 0;
      let usesLeft = values.usesLeft || 0;

      var data = {
        code:code,
        discountType:discountType,
        discountVal:discountVal,
        validUntil:validUntil,
        uses: parseInt(uses),
        usesLeft: parseInt(usesLeft),
        id:this.state.id
      }

      apiRequest("POST","coupons",{data}).then(res => {
        if(res.status == 200){
          if(res.data.error == 1){
            this.setState({ modal_error: res.data.msg || "An error occured, Try again later." })
            return
          }
          let allData = this.state.data;
          if(this.state.id == "new"){
            allData.push(data)
          }else{
            if(discountType == "shipping")
              data.discountVal = null
            allData[allData.findIndex((el)=>el.id==this.state.id)] = data
          }
          this.setState({isAlertOpen:true,modal_error:false})
          setTimeout(() => {
              this.setState({
              modal_static: false
            })
          }, 1000);
        }
      }).catch(err=>{
        if (!err.response) {
          this.setState({ modal_error:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({modal_error:0}),1500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ modal_error:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({modal_error:0}),1500)
          return;
        }
        this.setState({ modal_error:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({modal_error:0}),1500)
      })

      //this.setState({ modal_static: false })
    }

    deleteCoupon = () => {
      //let allData = [...this.state.data];
      //var index = allData.findIndex((el)=>el.code==this.state.delete_coupon)
      apiRequest("DELETE","coupons",{data: this.state.delete_coupon}).then(res => {
        if(res.status == 200){
          if(res.data.error == 1){
            this.setState({ delete_error: res.data.msg || "An error occured, Try again later." })
            return
          }
          let allData = [...this.state.data];
          var index = allData.findIndex((el)=>el.code==this.state.delete_coupon)
          allData.splice(index, 1);
          this.setState({data:allData,delete_error:0,modal_delete: false})
        }
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div  className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Coupons" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Link to="#" onClick={this.newCoupon} className="btn btn-success mb-2"><i  className="mdi mdi-plus mr-2"></i> Add Coupon</Link>
                                        </div>
                                        <div  className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{borderCollapse:"collapse", borderSpacing : 0, width:"100%"}}>
                                                <thead  className="thead-light">
                                                    <tr>
                                                        <th style={{width:"20px"}}>
                                                            <div  className="custom-control custom-checkbox">
                                                                <Input type="checkbox"  className="custom-control-input" id="customercheck"/>
                                                                <Label  className="custom-control-label" htmlFor="customercheck">&nbsp;</Label>
                                                            </div>
                                                        </th>
                                                        <th>Coupon</th>
                                                        <th>Discount</th>
                                                        <th># of uses</th>
                                                        <th>Valid until</th>
                                                        <th style={{width:"120px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.data.length == 0)?(
                                                      <tr><td colSpan={5} className="text-center">No coupons</td></tr>
                                                    ) :
                                                      this.state.data.map((customerData, key) =>{
                                                            customerData = this.parseCoupon(customerData)
                                                              return(<tr key={key}>
                                                                  <td>
                                                                      <div  className="custom-control custom-checkbox">
                                                                          <Input type="checkbox"  className="custom-control-input" id={"couponcheck" + key}/>
                                                                          <Label  className="custom-control-label" htmlFor={"couponcheck" + key}>&nbsp;</Label>
                                                                      </div>
                                                                  </td>

                                                                  <td>{customerData.code}</td>
                                                                  <td dangerouslySetInnerHTML={{
                                                                    __html: customerData.discount
                                                                  }}/>
                                                                  <td className={customerData.usesColor}>{customerData.uses}</td>
                                                                  <td className={customerData.color}>
                                                                     {customerData.date}
                                                                  </td>
                                                                  <td>
                                                                  <Link to="#" onClick={()=>{this.editCoupon(key)}}  className="mr-3 text-primary" id={"edit"+key}><i  className="mdi mdi-pencil font-size-18"></i></Link>
                                                                      <UncontrolledTooltip target={"edit"+key} placement="top">
                                                                          Edit
                                                                      </UncontrolledTooltip>
                                                                      <Link to="#"  className="text-danger" onClick={() => this.setState({ modal_delete: true,delete_coupon:customerData.code })} id={"delete"+key}><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                      <UncontrolledTooltip target={"delete"+key} placement="top">
                                                                          Delete
                                                                      </UncontrolledTooltip>
                                                                  </td>
                                                              </tr>)
                                                          })

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Modal
                          isOpen={this.state.modal_static}
                          toggle={this.tog_static}
                          backdrop="static"
                          centered
                          size = "lg"
                        >
                            <ModalHeader toggle={() => this.setState({ modal_static: false })}>
                            Add Coupon
                            </ModalHeader>
                            <ModalBody>
                            <AvForm onValidSubmit={this.addCoupon}>
                              <Row>
                                <Col lg={12}>
                                <Alert color="success" isOpen={this.state.isAlertOpen}>
                                    {this.state.id!="new"?"Coupon updated":"Coupon added"}
                                </Alert>
                                <FormGroup>
                                  <Label htmlFor="code">Code</Label>
                                  <InputGroup>
                                    <AvInput
                                        name="code"
                                        type="text"
                                        className="form-control"
                                        id="code"
                                        placeholder="Coupon code"
                                        value={this.state.code}
                                        onChange={this.updateData}
                                        required
                                    />
                                    <InputGroupAddon addonType="append">
                                      <Button color="primary" onClick={this.genCode}><i className="fas fa-sync-alt genCode" title="Generate Code" ></i></Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <AvRadioGroup name="discountType" className="bg-transparent" required onChange={this.discountType} value={this.state.discountType}>
                                      <AvRadio label="% off on cutting and engraving time" value="percentage" />
                                      <AvRadio label="&euro; off on the order" value="amount" />
                                      <AvRadio label="Free shipping on the order" value="shipping" />
                                    </AvRadioGroup>
                                </FormGroup>
                                <SlideDown closed={this.state.hidden}>
                                  <div className="overflow-auto">
                                    <FormGroup className="">
                                          <Label htmlFor="discountVal">Value</Label>
                                            <AvInput
                                                name="discountVal"
                                                type="number"
                                                className="form-control"
                                                id="discountVal"
                                                placeholder="Coupon code"
                                                onChange={this.updateData}
                                                required={!this.state.hidden}
                                                value={this.state.discountVal}
                                            />
                                      </FormGroup>
                                    </div>
                                  </SlideDown>
                                  <FormGroup>
                                      <Label htmlFor="validUntil">Valid until</Label>
                                        <AvInput
                                            name="validUntil"
                                            type="date"
                                            className="form-control"
                                            id="validUntil"
                                            placeholder="Coupon code"
                                            onChange={this.updateData}
                                            required
                                            value={this.state.validUntil}
                                        />
                                  </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="uses"># of uses</Label>
                                          <AvInput
                                              name="uses"
                                              type="number"
                                              className="form-control"
                                              id="uses"
                                              placeholder="0"
                                              min="0"
                                              onChange={this.updateData}
                                              value={this.state.uses}
                                          />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                        <Label htmlFor="usesLeft"># of uses left</Label>
                                          <AvInput
                                              name="usesLeft"
                                              type="number"
                                              className="form-control"
                                              id="usesLeft"
                                              placeholder="0"
                                              min="0"
                                              onChange={this.updateData}
                                              value={this.state.usesLeft}
                                          />
                                    </FormGroup>
                                  </Col>
                              </Row>
                              {this.state.modal_error!=0?<Alert color="danger">
                                {this.state.modal_error}
                              </Alert>:""}
                              <ModalFooter>
                                  <Button type="button" color="light" onClick={() => this.setState({ modal_static: false }) }>Cancel</Button>
                                  <Button type="submit" color="primary">{this.state.id=="new"?"Add":"Save"}</Button>
                              </ModalFooter>

                            </AvForm>

                            </ModalBody>
                            </Modal>

                            <Modal
                              isOpen={this.state.modal_delete}
                              backdrop="static"
                              centered
                              size = "md"
                            >
                              <ModalHeader toggle={() => this.setState({ modal_delete: false })}>
                              Delete user
                              </ModalHeader>
                              <ModalBody>
                                Are you sure you want to delete <strong>{this.state.delete_coupon}</strong>?
                              </ModalBody>
                              {this.state.delete_error!=0?<Alert color="danger">
                                {this.state.delete_error}
                              </Alert>:""}
                              <ModalFooter>
                                  <Button type="button" color="light" onClick={() => this.setState({ modal_delete: false }) }>Cancel</Button>
                                  <Button type="submit" color="danger" onClick={this.deleteCoupon}>Delete</Button>
                              </ModalFooter>
                            </Modal>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Customers));
