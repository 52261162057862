import React, { Component } from "react";
import { Link } from "react-router-dom";
import history from "../../../history.js";
import {
  Dropdown,
  Alert,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
} from "reactstrap";
import SimpleBar from "simplebar-react";

//i18b
import { withNamespaces } from "react-i18next";

import { apiRequest } from "../../../helpers/backend_helper";
class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      loaded: false,
      error: null,
      data: [],
      retrying: null,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
    apiRequest("GET", "orders/problems")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setState({ data: data, loaded: true });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: "Can't connect to admin API, try again later.",
          });
          return;
        }
        console.error(err.response.data);
        if (err.response.status === 500) {
          this.setState({
            error: "An internal server error occured, try again later.",
          });
          return;
        }
        this.setState({ error: "An unknown error occured, try again later." });
      });
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  retry = (order) => {
    this.setState({ retrying: order });
    let retryOrder = order;
    apiRequest("POST", "orders/" + order + "/retry")
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.redirect) {
          this.props.history.push("/orders/" + data.redirect);
          this.setState({ retrying: null });
          return;
        }
        let orderIdx = this.state.data.findIndex(
          (order) => order.order === retryOrder
        );
        console.log(orderIdx);
        let orders = this.state.data;
        if (data.error) {
          console.error(data.error);
          orders[orderIdx].step = data.step;
          this.setState({ data: orders, retrying: null });
        } else {
          orders.splice(orderIdx, 1);
          this.setState({ data: orders, retrying: null });
        }

        this.setState({ retrying: null });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ retrying: null });
        if (!err.response) {
          this.setState({
            error: "Can't connect to admin API, try again later.",
          });
          return;
        }
        console.error(err.response.data);
        if (err.response.status === 500) {
          this.setState({
            error: "An internal server error occured, try again later.",
          });
          return;
        }
        this.setState({ error: "An unknown error occured, try again later." });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
          >
            <i className="ri-notification-3-line"></i>
            {this.state.data.length > 0 && <span className="noti-dot"></span>}
          </DropdownToggle>
          <DropdownMenu
            right
            className="dropdown-menu-lg p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Alerts")} </h6>
                </Col>
              </Row>
            </div>
            <SimpleBar style={{ maxHeight: "230px" }}>
              <Alert
                isOpen={this.state.error != null}
                color="danger"
                className="mx-2"
              >
                {this.state.error}
              </Alert>
              {this.state.data.length == 0 && (
                <div className="text-center text-muted pb-3">No alerts</div>
              )}
              {this.state.data.map((el) => (
                <div
                  key={el.order}
                  onClick={() => {
                    this.retry(el.order);
                  }}
                  className="text-reset notification-item"
                >
                  <Media
                    className={
                      this.state.retrying == el.order ? "disabled" : ""
                    }
                  >
                    <div className="avatar-xs mr-3">
                      {this.state.retrying != el.order ? (
                        <span className="avatar-title text-white bg-danger rounded-circle font-size-16">
                          <i className=" ri-error-warning-line"></i>
                        </span>
                      ) : (
                        <span className="avatar-title text-white bg-danger rounded-circle font-size-16">
                          <div
                            role="status"
                            style={{ height: "1rem", width: "1rem" }}
                            className="spinner-grow text-white"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </span>
                      )}
                    </div>
                    <Media body>
                      <h6 className="mt-0 mb-1">#{el.order} Failed</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          Order created, but {el.step?.name}. (err.{" "}
                          {el.step?.id})
                        </p>
                        <p className="mb-0">
                          {this.state.retrying == el.order
                            ? "Retrying"
                            : "Click to retry."}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </div>
              ))}
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
