import React, { Component, useEffect, useRef, useState } from "react";
import {
  Alert,
  Table,
  Container,
  Card,
  CustomInput,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  InputGroupText,
  InputGroupAddon,
  Input,
  InputGroup,
  Label,
  Button,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import print from "./print";
import { ToastContainer, toast } from "react-toastify/dist/react-toastify.js";
import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { updateOrderNum } from "../../store/actions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Error404 from "../../components/Common/Error404";

import { withRouter, Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser, apiError } from "../../store/actions";
import { withNamespaces } from "react-i18next";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import {
  apiRequest,
  getAccessToken,
  getLoggedInUser,
} from "../../helpers/backend_helper";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

var fileDownload = require("js-file-download");
const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

const InlineEdit = ({ isOpen, initialData, close, orderId }) => {
  const ref = useRef(null);
  const [data, setData] = useState("");

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const saveData = () => {
    apiRequest("POST", `orders/${orderId}/update-info`, data)
      .then((res) => {
        if (res.error) {
          // this.setState({
          //     error:1, errormsg:"An error occured while updating state."
          // });
          toast.error("An error occured while saving info.");
          return;
        }
        close(res.data);
        // var state = this.state.data
        // var index = state.designs.findIndex(el => el.ident == ident)
        // state.designs[index].done = !state.designs[index].done
        // this.setState({data:state})
      })
      .catch((err) => {
        console.error(err);
        if (!err.response) {
          toast.error("Can't connect to admin API, try again later.");
          return;
        }

        if (err.response.status == 404) {
          toast.error("Order cannot be found, try refreshing the page.");
          return;
        } else if (err.response.status === 500) {
          toast.error("An internal server error occured, try again later.");
          return;
        }
        toast.error("An unknown error occured, try again later.");
      });
  };

  const updateData = (ev) => {
    let newData = { ...data };
    newData[ev.target.name] = ev.target.value;
    setData(newData);
  };
  return (
    <Modal isOpen={isOpen} backdrop="static" centered size="md">
      <ModalHeader toggle={() => close()}>Edit info</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <Label className="mb-1">Firstname</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="firstname"
              onChange={updateData}
              value={data.firstname}
            />
          </Col>
          <Col md="6">
            <Label className="mb-1">Lastname</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="lastname"
              onChange={updateData}
              value={data.lastname}
            />
          </Col>
          <Col md="12">
            <Label className="mb-1">Company</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="company"
              onChange={updateData}
              value={data.company}
            />
          </Col>
          <Col md="12">
            <Label className="mb-1">Phone number</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="phone"
              onChange={updateData}
              value={data.phone}
            />
          </Col>
          <Col md="12">
            <Label className="mb-1">Address</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="address"
              onChange={updateData}
              value={data.address}
            />
          </Col>
          <Col md="4">
            <Label className="mb-1">Zipcode</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="zipcode"
              onChange={updateData}
              value={data.zipcode}
            />
          </Col>
          <Col md="5">
            <Label className="mb-1">City</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="city"
              onChange={updateData}
              value={data.city}
            />
          </Col>
          <Col md="3">
            <Label className="mb-1">Country</Label>
            <select
              className="form-control mb-1"
              name="country"
              onChange={updateData}
              value={data.country}
            >
              <option value="NL">NL</option>
              <option value="BE">BE</option>
              <option value="DE">DE</option>
            </select>
          </Col>
          <Col md="12">
            <Label className="mb-1">Email</Label>
            <input
              type="text"
              className="form-control mb-1"
              name="email"
              onChange={updateData}
              value={data.email}
            />
          </Col>
          {data.taxNumber != null && (
            <Col md="12">
              <Label className="mb-1">Tax number</Label>
              <input
                type="text"
                className="form-control mb-1"
                name="taxNumber"
                onChange={updateData}
                value={data.taxNumber}
              />
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" size="sm" color="success" onClick={saveData}>
          Save
        </Button>
        <Button type="button" size="sm" color="dark" onClick={() => close()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

class Orders extends Component {
  constructor(props) {
    super(props);
    //this.state.orderId = "-"
    this.state = {
      breadcrumbItems: [
        { title: "Het Laser Lokaal", link: "/" },
        { title: "Orders", link: "/orders" },
        { title: this.props.match.params.id, link: "#" },
      ],
      activeTab: 0,
      data: [],
      files: [],
      loaded: 0,
      error: 0,
      errormsg: "",
      orderId: 0,
      selected: -1,
      rand: 0, //Math.round(Math.random()*3),
      refund_modal: false,
      vatcompare_loading: false,
      vatcompare_modal: false,
      vatData: {},
      refundValue: 0,
      editInfoModal: false,
      editInfo: {
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        address: "",
        zipcode: "",
        city: "",
        country: "",
        email: "",
      },
    };
    this.fileUpload = React.createRef();
    this.addFile = this.addFile.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.match.params.id == this.state.orderId) return;
    this.setState({ loaded: 0, error: 0, orderId: props.match.params.id });
    apiRequest("GET", `orders/${props.match.params.id}`)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        this.setFavicon(data.state);
        var activeTab = 0;
        switch (data.state) {
          case "on-hold":
            activeTab = 1;
            break;
          case "processing":
            activeTab = 2;
            break;
          case "ready":
            activeTab = 3;
            break;
          case "completed":
            activeTab = 4;
            break;
          case "archived":
            activeTab = 5;
            break;
        }
        this.setState({
          data: data,
          loaded: 1,
          orderId: props.match.params.id,
          breadcrumbItems: [
            { title: "Het Laser Lokaal", link: "/" },
            { title: "Orders", link: "/orders" },
            { title: props.match.params.id, link: "#" },
          ],
          message: "",
          activeTab: activeTab,
          selected: -1,
        });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: "Can't connect to admin API, try again later.",
          });
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({ error: 404 });
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: "An internal server error occured, try again later.",
          });
          return;
        }
        this.setState({ error: "An unknown error occured, try again later." });
      });
  }

  setFavicon(type) {
    let icon = "";
    switch (type) {
      case "new":
        icon = "/favicons/Favicon_New.png";
        break;
      case "on-hold":
        icon = "/favicons/Favicon_Hold.png";
        break;
      case "processing":
        icon = "/favicons/Favicon_Processing.png";
        break;
      case "ready":
        icon = "/favicons/Favicon_Ready.png";
        break;
      case "completed":
        icon = "/favicons/Favicon_Completed.png";
        break;
      case "archived":
        icon = "/favicons/Favicon_Archived.png";
        break;
      default:
        icon = "/favicons/Favicon.png";
        break;
    }

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = icon;
  }

  minTommss(minutes) {
    var sign = minutes < 0 ? "-" : "";
    var hour = Math.floor(Math.abs(minutes / 60));
    var min = Math.floor(Math.abs(minutes % 60));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return (
      sign +
      (hour != 0 ? (hour < 10 ? "0" : "") + hour + ":" : "") +
      (min < 10 ? "0" : "") +
      min +
      ":" +
      (sec < 10 ? "0" : "") +
      sec
    );
  }

  packing_slip = () => {
    this.setState({ loadingPackingSlip: true });
    function encodeQueryData(data) {
      const ret = [];
      for (let d in data) ret.push("id[]" + "=" + encodeURIComponent(data[d]));
      return ret.join("&");
    }

    let params = [this.state.orderId];
    const token = getAccessToken();
    print({
      type: "packing_slip",
      url:
        process.env.REACT_APP_BASE_URL +
        "admin/packing_slip?" +
        encodeQueryData(params) +
        "&access_token=" +
        token,
      onLoadingEnd: () => this.setState({ loadingPackingSlip: false }),
      onError: (e) => {
        if (e == "Not Found") {
          toast.warning("No label found.");
          this.setState({ loadingPackingSlip: false });
          return;
        }
        console.error(e);
        toast.error(
          "An error occured while retrieving label, try again later."
        );
        this.setState({ loadingPackingSlip: false });
      },
    });
  };

  addNote = () => {
    apiRequest("POST", `orders/${this.state.orderId}/notes`, {
      message: this.state.message,
    })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        var state = this.state.data;
        state.notes = data.notes;
        this.setState({ data: state, message: "" });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  toggleTab = (tab) => {
    var state = "new";

    switch (tab) {
      case 1:
        state = "on-hold";
        break;
      case 2:
        state = "processing";
        break;
      case 3:
        state = "ready";
        break;
      case 4:
        state = "completed";
        break;
      case 5:
        state = "archived";
        break;
    }
    apiRequest("POST", `orders/${this.state.orderId}/state`, { state: state })
      .then((res) => {
        if (res.error) {
          this.setState({
            error: 1,
            errormsg: "An error occured while updating state.",
          });
          return;
        }
        if (
          (tab == 0 || tab == 1) &&
          (this.state.activeTab == 2 || this.state.activeTab == 3)
        ) {
          this.props.updateOrderNum(this.props.orderNum + 1);
        } else if (
          (tab == 2 || tab == 3) &&
          (this.state.activeTab == 0 || this.state.activeTab == 1)
        ) {
          this.props.updateOrderNum(this.props.orderNum - 1);
        }
        this.setFavicon(state);
        this.setState({
          activeTab: tab,
        });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  shippingLabel = (announce = true) => {
    this.setState({ loadingLabels: true });
    apiRequest("POST", `orders/${this.state.orderId}/label`, { announce })
      .then((res) => {
        if (res.data.error) {
          toast.error("An error occured while creating shipping label.");
          return;
        }
        var data = this.state.data;
        data.parcel_status = res.data.success;
        this.setState({ data: data });
        if (announce) {
          this.getLabel();
        }
        this.setState({ loadingLabels: false });
      })
      .catch((err) => {
        this.setState({ loadingLabels: false });
        console.error(err);
        if (!err.response) {
          toast.error("Can't connect to admin API, try again later.");
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          toast.error("Order cannot be found, try refreshing the page.");
          return;
        } else if (err.response.status === 500) {
          toast.error(
            "An error occured while creating label, try again later."
          );
          return;
        }
        toast.error("An unknown error occured, try again later.");
      });
  };

  componentWillUnmount() {
    this.setFavicon("");
  }

  getLabel = () => {
    this.setState({ loadingLabels: true });
    function encodeQueryData(data) {
      const ret = [];
      for (let d in data) ret.push("id[]" + "=" + encodeURIComponent(data[d]));
      return ret.join("&");
    }

    const token = getAccessToken();
    print({
      url:
        process.env.REACT_APP_BASE_URL +
        "admin/labels?" +
        encodeQueryData([this.state.orderId]) +
        "&access_token=" +
        token,
      type: "label",
      onLoadingEnd: () => this.setState({ loadingLabels: false }),
      onError: (e) => {
        if (e == "Not Found") {
          toast.warning("No label found.");
          this.setState({ loadingLabels: false });
          return;
        }
        console.error(e);
        toast.error(
          "An error occured while retrieving label, try again later."
        );
        this.setState({ loadingLabels: false });
      },
    });
  };

  addFile = (ev) => {
    const data = new FormData();
    const files = this.state.files;
    for (let i = 0; i < files.length; i++) {
      data.append(`files`, files[i]);
    }
    apiRequest("POST", `orders/${this.state.orderId}/files`, data)
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        var state = this.state.data;
        state.files = data;
        this.setState({ data: state, rand: !this.state.rand });
        this.fileUpload.value = "";
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  removeFile = (file) => {
    //data.append('files', this.state.files[0])
    apiRequest("DELETE", `orders/${this.state.orderId}/files`, { file: file })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        var state = this.state.data;
        state.files = data;
        this.setState({ data: state });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  download = (file, filename) => {
    apiRequest(
      "GET",
      `orders/${this.state.orderId}/download/${file}`,
      undefined,
      { responseType: "blob" }
    )
      .then((res) => {
        let name = filename;
        if (!filename.endsWith(".zip")) {
          name = filename.substr(0, filename.lastIndexOf(".")) + ".svg";
        }
        fileDownload(res.data, name);
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({ error: 1, errormsg: "File can't be found." });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  compareVatData = () => {
    this.setState({
      vatcompare_loading: true,
    });
    apiRequest("GET", `orders/${this.state.orderId}/vat`)
      .then((res) => {
        if (res.error) {
          this.setState({
            error: 1,
            errormsg: res.error || "An error occured while verifying VAT.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }
        // let state = this.state.data;
        // state.vat_verification = false;
        // this.setState({ data: state });
        console.log(res.data);
        this.setState({
          vatcompare_modal: true,
          vatData: res.data,
          vatcompare_loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          vatcompare_loading: false,
        });
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }
        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
      });
  };

  verifyVat = () => {
    apiRequest("POST", `orders/${this.state.orderId}/vat_verified`, {
      vat: this.state.data.customer.tax_number,
    })
      .then((res) => {
        if (res.error) {
          this.setState({
            error: 1,
            errormsg: res.error || "An error occured while verifying VAT.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }
        let state = this.state.data;
        state.vat_verification = false;
        this.setState({ data: state, vatcompare_modal: false });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }
        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
      });
  };

  done = (ev, ident) => {
    const authToken = getLoggedInUser();
    apiRequest("POST", `orders/${this.state.orderId}/done/${ident}`, {
      state: ev.target.checked ? 1 : 0,
    })
      .then((res) => {
        if (res.error) {
          this.setState({
            error: 1,
            errormsg: "An error occured while updating state.",
          });
          return;
        }
        var state = this.state.data;
        var index = state.designs.findIndex((el) => el.ident == ident);
        state.designs[index].done = !state.designs[index].done;
        this.setState({ data: state });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  openRefundModal = () => {
    this.setState({
      refundValue: roundTo(
        this.state.data.price.total -
          (this.state.data.price.refunds?.reduce(
            (acc, val) => acc + val.value,
            0
          ) || 0),
        2
      ),
      refund_modal: true,
    });
  };

  createRefund = () => {
    apiRequest("POST", `orders/${this.state.orderId}/refund`, {
      value: this.state.refundValue,
    })
      .then(async (res) => {
        if (res.error) {
          this.setState({
            error: 1,
            errormsg: "An error occured while creating refund.",
          });
          return;
        }

        let data = await res.data;
        let state = this.state.data;
        state.price.refunds = data.refunds;
        state.status = data.status;
        this.setState({ data: state, refund_modal: false });
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            refund_modal: false,
            error: 1,
            errormsg: "Can't connect to admin API, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 4500);
          return;
        }

        console.error(err.response.data);
        if (err.response.status == 404) {
          this.setState({
            refund_modal: false,
            error: 1,
            errormsg: "Order cannot be found, try refreshing the page.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        } else if (err.response.status === 500) {
          this.setState({
            error: 1,
            errormsg: "An internal server error occured, try again later.",
          });
          setTimeout(() => this.setState({ error: 0 }), 1500);
          return;
        }
        this.setState({
          refund_modal: false,
          error: 1,
          errormsg: "An unknown error occured, try again later.",
        });
        setTimeout(() => this.setState({ error: 0 }), 1500);
      });
  };

  editInfo = (field, newEntry = false) => {
    if (field == "customer") {
      let data = this.state.data.customer;
      this.setState({
        editInfoModal: true,
        editInfo: {
          column: "invoice",
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.send_invoices_to_email,
          phone: data.phone,
          address: data.address1,
          city: data.city,
          zipcode: data.zipcode,
          country: data.country,
          company: data.company_name,
          taxNumber: this.state.data.taxNumber || "",
        },
      });
    } else {
      if (newEntry) {
        this.setState({
          editInfoModal: true,
          editInfo: {
            column: "shipping",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            city: "",
            zipcode: "",
            country: "NL",
            company: "",
          },
        });
      } else {
        let data = this.state.data.shippingAddress;
        this.setState({
          editInfoModal: true,
          editInfo: {
            column: "shipping",
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            phone: data.phone,
            address: data.address,
            city: data.city,
            zipcode: data.zipcode,
            country: data.country,
            company: data.company,
          },
        });
      }
    }
  };

  render() {
    const userType = getLoggedInUser().role;
    var customer = this.state.data.customer;
    var shippingAddress = this.state.data.shippingAddress;
    var tableData = [];

    if (this.state.loaded == 0) {
      if (this.state.error == 404) {
        return <Error404 />;
      } else if (this.state.error) {
        return (
          <React.Fragment>
            <div className="page-content">
              <Alert color="danger">{this.state.error}</Alert>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <div className="preloader">
              <div className="loader">
                <div className="status">
                  <div role="status" className="spinner-grow text-primary">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    }

    var coupon = this.state.data.coupon;
    var discount = "";
    var discountType = "";
    if (coupon != "" && coupon != null) {
      discountType = coupon.discountType;
      switch (discountType) {
        case "percentage":
          discount = `${coupon.discountVal}% off on operations (€ ${this.state.data.price.discount})`;
          break;
        case "amount":
          discount = `€ ${coupon.discountVal} off`;
          break;
        case "shipping":
          discount = `Free shipping`;
          break;
      }
    }
    var discountVal = 0;
    var total = 0;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Orders"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Alert color="danger" isOpen={this.state.error != 0 ? true : false}>
              {this.state.errormsg}
            </Alert>
            <Alert
              color="danger"
              isOpen={this.state.data.vat_verification === true}
            >
              <div className="d-flex align-items-center">
                <span>
                  VAT number should be verified. Go to{" "}
                  <a
                    className="text-danger font-weight-bold"
                    href={this.state.data.invoice_link}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <u>Moneybird</u>
                  </a>{" "}
                  to verify.
                </span>
                <Button
                  size="sm"
                  color="danger"
                  className="ml-auto"
                  disabled={this.vatcompare_loading}
                  onClick={this.compareVatData}
                >
                  {this.state.vatcompare_loading ? (
                    <div className="spinner-grow mr-1"></div>
                  ) : (
                    <i className="fas fa-equals mr-1"></i>
                  )}
                  Compare
                </Button>
              </div>
            </Alert>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="">
                    <Row>
                      <Col xs={"auto"} className="mb-1 flex-grow-1">
                        <h5 className="d-inline-block mr-3">
                          Order {this.state.orderId}
                        </h5>
                        <Alert
                          className="d-inline-block py-1 mr-2 mb-0"
                          color={
                            this.state.data.shipping.type == "ship"
                              ? "info"
                              : "success"
                          }
                        >
                          {this.state.data.shipping.type == "ship"
                            ? "Ship"
                            : "Pickup"}
                        </Alert>
                        <Alert
                          className="d-inline-block py-1 mb-0"
                          color={
                            this.state.data.order.recycle == "all"
                              ? "success"
                              : this.state.data.order.recycle == "none"
                              ? "danger"
                              : "warning"
                          }
                        >
                          {this.state.data.order.recycle == "all"
                            ? "Recycle: All"
                            : this.state.data.order.recycle == "none"
                            ? "Recycle: None"
                            : "Recycle: Outer shape"}
                        </Alert>
                      </Col>
                      <Col xs={"auto"} className="mb-1">
                        <div className={"d-flex"}>
                          {this.state.data.shipping.type == "ship" ? (
                            <UncontrolledDropdown
                              className={""}
                              direction={"down"}
                            >
                              <ButtonGroup>
                                <Button
                                  size="sm"
                                  color="warning"
                                  onClick={() => this.shippingLabel()}
                                  className="d-inline"
                                  disabled={
                                    this.state.data.parcel_status != "" ||
                                    this.state.loadingLabels
                                  }
                                >
                                  {this.state.loadingLabels ? (
                                    <div className="spinner-grow mr-1"></div>
                                  ) : (
                                    <i className="fas fa-dolly mr-1"></i>
                                  )}
                                  {this.state.data.parcel_status ||
                                    "Create shipping label"}
                                </Button>
                                <DropdownToggle
                                  className="btn-sm"
                                  color="warning"
                                >
                                  <i className="fas fa-chevron-down font-size-10"></i>
                                </DropdownToggle>
                              </ButtonGroup>
                              <DropdownMenu>
                                <DropdownItem
                                  disabled={
                                    this.state.data.parcel_status != "" ||
                                    this.state.loadingLabels
                                  }
                                  onClick={(e) => this.shippingLabel(false)}
                                >
                                  <span role="button">
                                    Create, don't announce
                                  </span>
                                </DropdownItem>
                                <DropdownItem
                                  disabled={
                                    this.state.data.parcel_status == "" ||
                                    this.state.loadingLabels
                                  }
                                  onClick={(e) => this.getLabel()}
                                >
                                  <span role="button">Get label</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          ) : null}
                          {this.state.data.status == "paid" &&
                          this.state.data.paymentId != "on_account" &&
                          this.state.data.paymentId != "free" &&
                          userType == "admin" ? (
                            <Button
                              size="sm"
                              color="danger"
                              onClick={this.openRefundModal}
                              className="btn btn-danger d-inline ml-2"
                            >
                              <i className="mdi mdi-credit-card-refund-outline mr-1"></i>
                              Create Refund
                            </Button>
                          ) : null}
                          <Button
                            size="sm"
                            color="info"
                            onClick={this.packing_slip}
                            disabled={this.state.loadingPackingSlip}
                            className="btn btn-primary d-inline ml-2"
                          >
                            {this.state.loadingPackingSlip ? (
                              <div className="spinner-grow mr-1"></div>
                            ) : (
                              <i className="fas fa-print mr-1"></i>
                            )}
                            Packing Slip
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <Row>
                          <Col lg={6}>
                            <h6 className="d-inline">
                              {shippingAddress
                                ? this.props.t("Invoice info")
                                : this.props.t("Info")}
                            </h6>
                            {userType == "admin" ? (
                              <>
                                <i
                                  id="editInvoice"
                                  className="mdi mdi-pencil text-primary ml-1 cursor-pointer"
                                  onClick={() => this.editInfo("customer")}
                                ></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="editInvoice"
                                >
                                  Edit invoice info
                                </UncontrolledTooltip>
                              </>
                            ) : null}
                            {!shippingAddress && userType == "admin" ? (
                              <>
                                <i
                                  id="addShipping"
                                  className="fas fa-plus text-success ml-1 cursor-pointer"
                                  onClick={() =>
                                    this.editInfo("shipping", true)
                                  }
                                ></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target="addShipping"
                                >
                                  Add shipping address
                                </UncontrolledTooltip>
                              </>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            {shippingAddress ? (
                              <>
                                <h6 className="d-inline">
                                  {this.props.t("Shipping info")}
                                </h6>
                                {userType == "admin" ? (
                                  <>
                                    <i
                                      id="editShipping"
                                      className="mdi mdi-pencil text-primary ml-1 cursor-pointer"
                                      onClick={() => this.editInfo("shipping")}
                                    ></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="editShipping"
                                    >
                                      Edit shipping info
                                    </UncontrolledTooltip>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <p>
                              <InlineEdit
                                orderId={this.state.orderId}
                                isOpen={this.state.editInfoModal}
                                initialData={this.state.editInfo}
                                close={(data) => {
                                  this.setState({
                                    editInfoModal: false,
                                    data: { ...this.state.data, ...data },
                                  });
                                }}
                              />
                              {customer.firstname} {customer.lastname}
                              <br />
                              {customer.company_name != ""
                                ? customer.company_name
                                : ""}
                              {customer.company_name != "" ? <br /> : ""}
                              {customer.phone}
                              <br />
                              {customer.address1}
                              <br /> {customer.zipcode}, {customer.city},{" "}
                              {customer.country}
                              <br />
                              <a
                                href={
                                  "mailto:" + customer.send_invoices_to_email
                                }
                              >
                                {" "}
                                {customer.send_invoices_to_email}
                              </a>
                              {this.state.data.taxNumber != "" ? (
                                <React.Fragment>
                                  <br /> {this.state.data.taxNumber}
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </p>
                          </Col>
                          {shippingAddress ? (
                            <Col lg={6}>
                              <p>
                                {shippingAddress.firstname}{" "}
                                {shippingAddress.lastname}
                                <br />
                                {shippingAddress.company != "" ? (
                                  <React.Fragment>
                                    {shippingAddress.company}
                                    <br />
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                                {shippingAddress.phone}
                                <br />
                                {shippingAddress.address}{" "}
                                {shippingAddress.address2}
                                <br /> {shippingAddress.zipcode},{" "}
                                {shippingAddress.city},{" "}
                                {shippingAddress.country}
                                <br />
                                <a href={"mailto:" + shippingAddress.email}>
                                  {" "}
                                  {shippingAddress.email}
                                </a>
                              </p>
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col lg={6}>
                            <p>
                              Ordered on: {this.state.data.order.orderDate}
                              <br />
                              Due Date: {this.state.data.order.dueDate}
                              <br />
                            </p>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={8}>
                        <div className="order-state-wrapper">
                          <Nav pills justified className="progress-bar">
                            <NavItem
                              onClick={() => {
                                this.toggleTab(0);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 0,
                              })}
                            >
                              {this.props.t("New")}
                            </NavItem>
                            <NavItem
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 1,
                              })}
                            >
                              {this.props.t("On Hold")}
                            </NavItem>
                            <NavItem
                              onClick={() => {
                                this.toggleTab(2);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 2,
                              })}
                            >
                              {this.props.t("Processing")}
                            </NavItem>
                            <NavItem
                              onClick={() => {
                                this.toggleTab(3);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 3,
                              })}
                            >
                              {this.props.t("Ready")}
                            </NavItem>
                            <NavItem
                              onClick={() => {
                                this.toggleTab(4);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 4,
                              })}
                            >
                              {this.state.data.shipping.type == "ship"
                                ? "Shipped"
                                : "Picked up"}
                            </NavItem>
                            <NavItem
                              onClick={() => {
                                this.toggleTab(5);
                              }}
                              className={classnames({
                                active: this.state.activeTab >= 5,
                              })}
                            >
                              {this.props.t("Archived")}
                            </NavItem>
                          </Nav>
                        </div>
                      </Col>
                    </Row>
                    <div className=" overflow-auto">
                      <Table>
                        <thead>
                          <tr>
                            <th>{this.props.t("Done")}</th>
                            <th>{this.props.t("Quantity")}</th>
                            <th>{this.props.t("File")}</th>
                            <th>{this.props.t("Visualisation")}</th>
                            <th>{this.props.t("Material")}</th>
                            <th>{this.props.t("Cutting Time")}</th>
                            <th>{this.props.t("Price")}</th>
                            <th>{this.props.t("Layers")}</th>
                            <th>{this.props.t("Download")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.designs.map((design, key) => (
                            <React.Fragment key={key}>
                              <tr>
                                <td>
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      checked={design.done ? true : false}
                                      onChange={(ev) =>
                                        this.done(ev, design.ident)
                                      }
                                      id={`customCheck${key}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheck${key}`}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  {design.quantity != 1 ? (
                                    <u className="text-danger">
                                      {design.quantity}
                                    </u>
                                  ) : (
                                    <span>{design.quantity}</span>
                                  )}
                                </td>
                                <td>
                                  {design.file}
                                  {parseFloat(design.coords.scale) != 1 ? (
                                    <>
                                      <br />
                                      <b className="text-danger">
                                        <i
                                          className="fas fa-expand-alt mr-1"
                                          style={{ width: "18px" }}
                                        ></i>
                                        Custom scale
                                      </b>
                                    </>
                                  ) : null}
                                  {design.extra.top || design.extra.bot ? (
                                    <>
                                      <br />
                                      <b className="text-danger">
                                        <i
                                          className="fas fa-tape mr-1"
                                          style={{ width: "18px" }}
                                        ></i>
                                        Film/adhesive
                                      </b>
                                    </>
                                  ) : null}
                                </td>
                                <td>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}${design.visualisation}`}
                                  />
                                </td>
                                <td>
                                  {design.material.materialName}
                                  <br />
                                  {design.material.XY[0]}x
                                  {design.material.XY[1]}
                                  <br />
                                  Top:{" "}
                                  {design.extra.top == 1
                                    ? "Protective film"
                                    : "-"}
                                  <br />
                                  Bottom:{" "}
                                  {design.extra.bot == 1
                                    ? "Protective film"
                                    : design.extra.bot == 2
                                    ? "Adhesive film"
                                    : "-"}
                                </td>
                                <td>
                                  {this.minTommss(
                                    (
                                      design.estTime.raster +
                                      design.estTime.engrave +
                                      design.estTime.vector
                                    ).toFixed(2)
                                  )}{" "}
                                  minutes
                                  {design.quantity > 1 ? " per sheet" : ""}
                                  <br />
                                  {design.quantity > 1
                                    ? this.minTommss(
                                        (
                                          design.quantity *
                                          (design.estTime.raster +
                                            design.estTime.engrave +
                                            design.estTime.vector)
                                        ).toFixed(2)
                                      ) + " minutes total"
                                    : ""}
                                </td>
                                <td>
                                  {(() => {
                                    if (design.discount != 0) {
                                      var totalPrice = roundTo(
                                        roundTo(
                                          design.price.operations +
                                            design.price.additional +
                                            design.price.material,
                                          2
                                        ) *
                                          (1 - design.discount),
                                        2
                                      );
                                      var price = {
                                        operations: 0,
                                        material: +roundTo(
                                          design.price.material *
                                            (1 - design.discount),
                                          2
                                        ),
                                        additional: +roundTo(
                                          design.price.additional *
                                            (1 - design.discount),
                                          2
                                        ),
                                      };
                                      price.operations = +roundTo(
                                        totalPrice -
                                          price.material -
                                          price.additional,
                                        2
                                      );
                                      total +=
                                        design.quantity *
                                        (price.operations +
                                          price.material +
                                          price.additional);
                                      return (
                                        <React.Fragment>
                                          ({parseInt(design.discount * 100)}%
                                          staffelkorting)
                                          <br />
                                          Cutting and engraving costs: &euro;{" "}
                                          <del>
                                            {design.price.operations}
                                          </del>{" "}
                                          {price.operations}
                                          <br />
                                          Material: &euro;{" "}
                                          <del>
                                            {design.price.material}
                                          </del>{" "}
                                          {price.material}
                                          <br />
                                          Protective film/Adhesive film: &euro;{" "}
                                          <del>
                                            {design.price.additional}
                                          </del>{" "}
                                          {price.additional}
                                        </React.Fragment>
                                      );
                                    } else {
                                      total +=
                                        design.quantity *
                                        (design.price.operations +
                                          design.price.material +
                                          design.price.additional);
                                      return (
                                        <React.Fragment>
                                          Cutting and engraving costs: &euro;{" "}
                                          {design.price.operations}
                                          <br />
                                          Material: &euro;{" "}
                                          {design.price.material} <br />
                                          Protective film/Adhesive film: &euro;{" "}
                                          {design.price.additional}
                                        </React.Fragment>
                                      );
                                    }
                                  })()}
                                </td>
                                <td>
                                  <i
                                    onClick={() => {
                                      this.setState({
                                        selected:
                                          this.state.selected == key ? -1 : key,
                                      });
                                    }}
                                    className={
                                      this.state.selected == key
                                        ? "fas fa-arrow-down layers rotate-180"
                                        : "fas fa-arrow-down layers"
                                    }
                                  ></i>
                                </td>
                                <td>
                                  <Button
                                    outline
                                    color="primary"
                                    size="sm"
                                    onClick={() =>
                                      this.download(
                                        design.filename,
                                        design.file
                                      )
                                    }
                                  >
                                    Download
                                  </Button>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="2" className="p-0"></td>
                                <td colSpan="6" className="p-0">
                                  <SlideDown
                                    className={
                                      "overflow-hidden my-dropdown-slidedown"
                                    }
                                    closed={
                                      this.state.selected == key ? false : true
                                    }
                                  >
                                    <div>
                                      <Row>
                                        <Col xs="4">
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {design.coords.X ==
                                                  design.coords.allX
                                                    ? "Entire design"
                                                    : "Design"}
                                                  :
                                                </td>
                                                <td>
                                                  {design.coords.allX} x{" "}
                                                  {design.coords.allY}cm
                                                </td>
                                              </tr>
                                              {design.coords.X !=
                                              design.coords.allX ? (
                                                <tr>
                                                  <td>Enabled layers:</td>
                                                  <td>
                                                    {design.coords.X} x{" "}
                                                    {design.coords.Y}cm
                                                  </td>
                                                </tr>
                                              ) : null}
                                              <tr>
                                                <td>Scale:</td>
                                                <td>{design.coords.scale}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </Col>
                                        <Col xs="8">
                                          <table className="table design-colors text-center m-0">
                                            <thead>
                                              <tr>
                                                <th className="border-top-0">
                                                  Color
                                                </th>
                                                <th className="border-top-0">
                                                  Raster engrave
                                                </th>
                                                <th className="border-top-0">
                                                  Line engrave
                                                </th>
                                                <th className="border-top-0">
                                                  Cut
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {Object.keys(
                                                design.designColors
                                              ).map((color, i) => {
                                                var layer =
                                                  design.designColors[color];
                                                return (
                                                  <tr key={i}>
                                                    <td>
                                                      <div className="color-container mx-auto d-inline-block">
                                                        <div
                                                          className="color "
                                                          title="<%=j%>"
                                                          style={{
                                                            "--c": color,
                                                          }}
                                                        ></div>
                                                      </div>
                                                      <b
                                                        className="d-inline-block"
                                                        style={{
                                                          fontFamily:
                                                            "monospace",
                                                          textTransform:
                                                            "upperCase",
                                                        }}
                                                      >
                                                        &nbsp;{color}
                                                      </b>
                                                    </td>
                                                    <td>
                                                      {layer.raster == 1 ? (
                                                        <i className="fas fa-check"></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {layer.engrave == 1 ? (
                                                        <i className="fas fa-check"></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                    <td>
                                                      {layer.cut == 1 ? (
                                                        <i className="fas fa-check"></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </table>
                                        </Col>
                                      </Row>
                                    </div>
                                  </SlideDown>
                                </td>
                                <td colSpan="1" className="p-0"></td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          {discount != "" ? (
                            <tr>
                              <th colSpan={5}>&nbsp;</th>
                              <th>Coupon: {coupon.code}</th>
                              <th>{discount}</th>
                              <th colSpan={2}>&nbsp;</th>
                            </tr>
                          ) : null}
                          {this.state.data.price.surcharge != 0 ? (
                            <tr>
                              <th colSpan={5}>&nbsp;</th>
                              <th>Delivery surcharge</th>
                              <th>&euro; {this.state.data.price.surcharge}</th>
                              <th colSpan={2}>&nbsp;</th>
                            </tr>
                          ) : null}
                          <tr>
                            <th colSpan={5}>&nbsp;</th>
                            <th>
                              Total:{" "}
                              {this.minTommss(
                                this.state.data.designs.reduce(
                                  (a, b) =>
                                    a +
                                    (b.quantity *
                                      (b.estTime.raster +
                                        b.estTime.engrave +
                                        b.estTime.vector) || 0),
                                  0
                                )
                              )}{" "}
                              minutes
                            </th>
                            <th>
                              Total: &euro;{" "}
                              {roundTo(
                                this.state.data.price.subtotal -
                                  this.state.data.price.shipping,
                                2
                              )}{" "}
                              {}{" "}
                              {this.state.data.shipping.type == "ship"
                                ? `(+€ ${roundTo(
                                    this.state.data.shipping.price,
                                    2
                                  )} Shipping)`
                                : null}
                              {userType == "admin" &&
                              this.state.data.paymentId == "on_account" ? (
                                <Alert
                                  className="d-inline-block py-0 px-2"
                                  color={"danger"}
                                >
                                  On account
                                </Alert>
                              ) : null}
                            </th>
                            <th>&nbsp;</th>
                            <th>
                              <Button
                                outline
                                color="primary"
                                size="sm"
                                onClick={() =>
                                  this.download(
                                    "all",
                                    `Order_${this.state.orderId}.zip`
                                  )
                                }
                              >
                                Download all
                              </Button>
                            </th>
                          </tr>
                        </tfoot>
                      </Table>
                    </div>
                    <Row>
                      <Col lg={6} className="">
                        <Table className="mt-3">
                          <thead>
                            <tr>
                              <th style={{ width: "60%" }}>Note</th>
                              <th>By</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.notes.map((note, i) => {
                              var d = new Date(note.date),
                                date =
                                  [
                                    d.getDate().padLeft(),
                                    (d.getMonth() + 1).padLeft(),
                                    d.getFullYear(),
                                  ].join("/") +
                                  " at " +
                                  [
                                    d.getHours().padLeft(),
                                    d.getMinutes().padLeft(),
                                  ].join(":");
                              return (
                                <tr key={i}>
                                  <td style={{ whiteSpace: "pre-wrap" }}>
                                    {note.message}
                                  </td>
                                  <td>{note.user}</td>
                                  <td>{date}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <Input
                          type="textarea"
                          className="mb-1"
                          name="text"
                          id="exampleText"
                          value={this.state.message}
                          onChange={(ev) => {
                            this.setState({ message: ev.target.value });
                          }}
                        />
                        <Button
                          size="sm"
                          color="primary"
                          onClick={this.addNote}
                        >
                          Add note
                        </Button>
                      </Col>
                      <Col lg={6} className="">
                        <Table className="mt-3">
                          <thead>
                            <tr>
                              <th>Files</th>
                              <th>Date</th>
                              <th>Download</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.files.map((file, i) => {
                              var d = new Date(file.date || null),
                                date =
                                  [
                                    d.getDate().padLeft(),
                                    (d.getMonth() + 1).padLeft(),
                                    d.getFullYear(),
                                  ].join("/") +
                                  " at " +
                                  [
                                    d.getHours().padLeft(),
                                    d.getMinutes().padLeft(),
                                  ].join(":");
                              var download =
                                "attachment" +
                                file.location.substring(
                                  file.location.lastIndexOf("/"),
                                  file.location.length
                                );
                              return (
                                <tr key={i}>
                                  <td>{file.name}</td>
                                  <td>{date}</td>
                                  <td>
                                    <Button
                                      outline
                                      color="primary"
                                      size="sm"
                                      onClick={() =>
                                        this.download(download, file.name)
                                      }
                                    >
                                      Download
                                    </Button>
                                  </td>
                                  <td>
                                    <i
                                      onClick={(el) => {
                                        this.removeFile(file.location);
                                      }}
                                      className="fas fa-times text-danger"
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        <CustomInput
                          type="file"
                          key={this.state.rand}
                          multiple
                          id="fileUpload"
                          className="mb-1"
                          name="customFile"
                          innerRef={(node) => (this.fileUpload = node)}
                          onChange={(ev) => {
                            this.setState({ files: ev.target.files });
                          }}
                        />
                        <Button
                          size="sm"
                          color="primary"
                          onClick={(ev) => {
                            this.addFile(ev);
                          }}
                        >
                          Add file
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.vatcompare_modal}
          backdrop="static"
          centered
          size="md"
        >
          <ModalHeader
            toggle={() => this.setState({ vatcompare_modal: false })}
          >
            Compare invoice info
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <h6>Invoice info</h6>
                {customer.company_name != "" ? customer.company_name : ""}
                <br />
                {customer.address1}
                <br />
                {customer.city}
                <br />
                {customer.zipcode}
                <br />
                {customer.country}
              </Col>
              <Col>
                <h6>Invoice info (from EU database)</h6>
                {this.state.vatData.company}
                <br />
                {this.state.vatData.address}
                <br />
                {this.state.vatData.city}
                <br />
                {this.state.vatData.zipcode}
                <br />
                {this.state.vatData.country}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <a
              className="btn btn-primary btn-sm"
              href={this.state.data.invoice_link}
              rel="noreferrer noopener"
              target="_blank"
            >
              Edit (in Moneybird)
            </a>
            <Button
              type="button"
              size="sm"
              color="success"
              onClick={this.verifyVat}
            >
              Looks good!
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.refund_modal}
          backdrop="static"
          centered
          size="md"
        >
          <ModalHeader toggle={() => this.setState({ refund_modal: false })}>
            Create Refund
          </ModalHeader>
          <ModalBody>
            <Label className="w-100">
              <div className="d-flex">
                <div className="flex-grow-1">Amount</div>
                <div className="text-muted">
                  Max: &euro;{" "}
                  {roundTo(
                    parseFloat(this.state.data.price.total) -
                      (this.state.data.price.refunds?.reduce(
                        (acc, val) => acc + val.value,
                        0
                      ) || 0),
                    2
                  )}
                </div>
              </div>
            </Label>
            <div className="d-flex">
              <div className="flex-grow-1">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      className="bg-transparent pr-0"
                      style={{ lineHeight: 1 }}
                    >
                      &euro;
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="border-left-0 pl-1"
                    type="number"
                    placeholder={""}
                    value={this.state.refundValue}
                    min="0"
                    name="message"
                    onChange={(ev) => {
                      this.setState({ refundValue: ev.target.value });
                    }}
                  />
                </InputGroup>
              </div>
              <div className="ml-3">
                <Button color="primary" onClick={this.createRefund}>
                  Refund
                </Button>
              </div>
            </div>
            {["partially_refunded", "fully_refunded"].includes(
              this.state.data.status
            ) ? (
              <Table className="mt-3">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>User</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.price.refunds.map((refund, id) => (
                    <tr key={id}>
                      <td>{formatDate(refund.date)}</td>
                      <td>{refund.user}</td>
                      <td>&euro; {roundTo(refund.value, 2)}</td>
                    </tr>
                  ))}
                </tbody>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <strong>Total refunded:</strong>
                    </td>
                    <td>
                      <strong>
                        &euro;{" "}
                        {roundTo(
                          this.state.data.price.refunds?.reduce(
                            (acc, val) => acc + val.value,
                            0
                          ) || 0,
                          2
                        )}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="sm"
              color="dark"
              onClick={() => this.setState({ refund_modal: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        ></ToastContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.Layout };
};
// const mapStateToProps = state => ({ statee: state })

export default withRouter(
  connect(mapStateToProps, { logoutUser, apiError, updateOrderNum })(
    withNamespaces()(Orders)
  )
);
