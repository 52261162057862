import React, { Component } from 'react';
import { Alert,Table, Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledTooltip, Input, Label, Button,FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import classnames from 'classnames';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withRouter, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Orders extends Component {

    constructor(props) {
        super(props);
        //this.state.orderId = "-"
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Variables", link : "#" },
            ],
            activeTab:0,
            data:{},

            loaded:0,
            error:0,
            edited:0,
            success:0,
            errormsg:"",
            orderId:  this.props.match.params.id
        }
    }
    componentDidMount(){
      //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
      apiRequest("GET","variables").then(res => {
        return res.data
      }).then((data) => {
        this.setState({ data: data, dataOriginal: JSON.stringify(data), loaded:1 })
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }
    updateData = (ev) => {
      var data = this.state.data
      if(ev.target.name == "quantity" || ev.target.name == "discount"){
        data.bulkDiscount[parseInt(ev.target.id)][ev.target.name] = !isNaN(parseFloat(ev.target.value))?parseFloat(ev.target.value):""
      } else {
        data[ev.target.name] = !isNaN(parseFloat(ev.target.value))?parseFloat(ev.target.value):""
      }
      var edited = 0;
      if(this.state.dataOriginal != JSON.stringify(data)){
        edited=1;
      }
      this.setState({data:data,edited:edited})
    }

    save = () => {
      const data = this.state.data;
      apiRequest("POST","variables",{data}).then(res => {
        var data = res.data
        this.setState({ data: data,edited:0, dataOriginal: JSON.stringify(data), success:1 })
        setTimeout(()=>this.setState({success:0}),4500)
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 400) {
          this.setState({ error:1, errormsg:"One or more fields do not match settings schema."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        } else if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })
    }

    reset = () => {
      this.setState({data:JSON.parse(this.state.dataOriginal),edited:0})
    }

    render() {
      var customer = this.state.data.customer

      var tableData = []

      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Variables" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                    <Alert color="success" isOpen={this.state.success!=0?true:false} >
                      Successfully updated variables. These changes will take effect immediately.
                    </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="">
                                      <h5>Design settings</h5>
                                      <Row>
                                      <Col xs={4} className="mb-2">
                                        <Label>Startup price (per design)</Label>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <InputGroupText>&euro;</InputGroupText>
                                          </InputGroupAddon>
                                          <Input type="number" placeholder="Startup price" value={this.state.data.startup} min="0" name="startup" onChange={this.updateData}/>
                                        </InputGroup>
                                      </Col>
                                      <Col xs={4}  className="mb-2">
                                        <Label>Cutting Price (per minute)</Label>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <InputGroupText>&euro;</InputGroupText>
                                          </InputGroupAddon>
                                          <Input type="number" placeholder="Cutting Price" value={this.state.data.pricePerMinute} min="0" name="pricePerMinute" onChange={this.updateData}/>
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>/min</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>

                                      <Col xs={4} className="mb-2">
                                        <Label>Design offset from material edge</Label>
                                        <InputGroup>
                                          <Input type="number" placeholder="Offset from material" value={this.state.data.offset} min="0" name="offset" onChange={this.updateData}/>
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>cm</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>
                                      <Col xs={6} className="mb-2">
                                        <Label>Engraving Lineheight</Label>
                                        <InputGroup>
                                          <Input type="number" placeholder="Engraving Lineheight" value={this.state.data.lineheight} min="0" name="lineheight" onChange={this.updateData}/>
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>mm</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>
                                      <Col xs={6} className="mb-2">
                                        <Label>Engraving speed</Label>
                                        <InputGroup>
                                          <Input type="number" placeholder="Engraving speed" value={this.state.data.engraveSpeed} min="0" name="engraveSpeed" onChange={this.updateData}/>
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>mm/s</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>
                                      </Row>
                                      <Row className="border-top">
                                      <Col xs={6}>
                                      <h5 className="mt-2">Shipping settings</h5>
                                        <Row>
                                          <Col xs={4}>
                                            <Label>Size</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{'>'}</InputGroupText>
                                              </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={0} disabled={true}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.large} name="large" min="0" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                          <Col xs={8}>
                                            <Label>Shipping costs</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>&euro;</InputGroupText>
                                              </InputGroupAddon>
                                              <Input type="number" placeholder="Cost" value={this.state.data.shippingSmall} name="shippingSmall" min="0" onChange={this.updateData}/>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>&euro;</InputGroupText>
                                              </InputGroupAddon>
                                              <Input type="number" placeholder="Cost" value={this.state.data.shippingLarge} name="shippingLarge" min="0" onChange={this.updateData}/>
                                            </InputGroup>
                                          </Col>
                                          <Col xs={12}>
                                            <Label>Operations multiplier - Fast</Label>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Fast" value={this.state.data.operationMultiplier} name="operationMultiplier" min="0" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col xs={6} className="border-left">
                                        <h5 className="mt-2">Bulk discount</h5>
                                        <Row>
                                          <Col xs={4}>
                                            <Label>Quantity</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{'>'}</InputGroupText>
                                              </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[0].quantity} id="0" min="0" name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[1].quantity} id="1" min="0" name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[2].quantity} id="2" min="0" name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[3].quantity} id="3" min="0" name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[4].quantity} id="4" min="0"  name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input type="number" placeholder="0" value={this.state.data.bulkDiscount[5].quantity} id="5" min="0" name="quantity" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                          <Col xs={8}>
                                            <Label>Percentage Discount</Label>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[0].discount} id="0" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[1].discount} id="1" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[2].discount} id="2" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[3].discount} id="3" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[4].discount} id="4" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input type="number" placeholder="Discount" max="0.9" min="0" value={this.state.data.bulkDiscount[5].discount} id="5" name="discount" onChange={this.updateData}/>
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>
                                      </Row>
                                      <Button className="mr-2" onClick={this.save} color="primary" disabled={this.state.edited==0?true:false}>Save</Button>
                                      <Button color="warning" onClick={this.reset} outline disabled={this.state.edited==0?true:false}>Reset</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Alert color="info" isOpen={this.state.edited!=0?true:false} >
                          <b>Changes have been made</b>
                        </Alert>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(withNamespaces()(Orders)));
