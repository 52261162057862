import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";

const ConditionalLink = ({ children, to, condition }) => (to!="")
      ? <Link to={to}>{children}</Link>
      : <>{children}</>;

class MiniWidgets extends Component {
    renderSwitch(report) {
      switch(report.arrow) {
        case 'up':
          return <span className="badge badge-soft-success font-size-11 mr-1"><i className="mdi mdi-menu-up"> </i> {report.rate}</span>;
        case 'down':
          return <span className="badge badge-soft-danger font-size-11 mr-1"><i className="mdi mdi-menu-down"> </i> {report.rate}</span>;
        default:
          return <span className="badge badge-soft-secondary font-size-11 mr-1"><i className={report.arrow}> </i> {report.rate}</span>;
      }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.reports.map((report, key) =>
                                    <Col key={key} md={4}>
                                      <ConditionalLink to={report.link}>
                                        <Card>
                                            <CardBody>
                                                <Media>
                                                    <Media body className="overflow-hidden">
                                                        <p className="text-dark text-truncate font-size-14 mb-2">{report.title}</p>
                                                        <h4 className="mb-0">{report.value}</h4>
                                                    </Media>
                                                    <div className="text-primary">
                                                        <i className={report.icon + " font-size-24"}></i>
                                                    </div>
                                                </Media>
                                            </CardBody>

                                            <CardBody className="border-top py-3">
                                                <div className="text-truncate">
                                                    {this.renderSwitch(report)}
                                                    <span className="text-muted ml-2">{report.desc}</span>
                                                </div>
                                            </CardBody>
                                        </Card>
                                      </ConditionalLink>
                                    </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default MiniWidgets;
