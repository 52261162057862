import React, { Component } from 'react';
import { Alert,Table, Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledTooltip, Input, Label, Button,FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import classnames from 'classnames';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withRouter, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Orders extends Component {

    constructor(props) {
        super(props);
        //this.state.orderId = "-"
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Announcement", link : "#" },
            ],
            activeTab:0,
            data:{},

            loaded:0,
            error:0,
            edited:0,
            success:0,
            errormsg:"",
            orderId:  this.props.match.params.id
        }
    }
    componentDidMount(){
      //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");

      apiRequest("GET","announcement").then(res => {
        return res.data
      }).then((data) => {

        this.setState({ data: data, dataOriginal: JSON.stringify(data), loaded:1 })
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }
    neverExpire = (ev) => {
      var data = this.state.data
      data.expires = false;
      var edited = 0;
      if(this.state.dataOriginal != JSON.stringify(data)){
        edited=1;
      }
      this.setState({data:data,edited:edited})
    }

    showNow = (ev) => {
      var data = this.state.data
      data.starts = new Date(new Date().toISOString().split("T")[0]).getTime();
      var edited = 0;
      if(this.state.dataOriginal != JSON.stringify(data)){
        edited=1;
      }
      this.setState({data:data,edited:edited})
    }

    updateData = (ev) => {
      var data = this.state.data
      if(ev.target.type == "checkbox"){
        data[ev.target.name] = ev.target.checked
      } else if(ev.target.type == "date"){
        data[ev.target.name] = new Date(ev.target.value).getTime()
      } else {
        data[ev.target.name] = ev.target.value
      }

      var edited = 0;
      // for(const [key, value] of Object.keys(data)){
      if(this.state.dataOriginal != JSON.stringify(data)){
        edited=1;
      }
      this.setState({data:data,edited:edited})
    }

    save = () => {
      const data = this.state.data;
      apiRequest("POST","announcement",{data}).then(res => {
        var data = res.data
        this.setState({ data: data,edited:0, dataOriginal: JSON.stringify(data), success:1 })
        setTimeout(()=>this.setState({success:0}),4500)
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 400) {
          this.setState({ error:1, errormsg:"One or more fields do not match settings schema."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        } else if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })
    }



    reset = () => {
      this.setState({data:JSON.parse(this.state.dataOriginal),edited:0})
    }

    render() {

      var tableData = []

      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      var {message, enabled, starts, expires} = this.state.data
      if(expires != false){
        expires = new Date(expires).toISOString().split('T')[0]
      }
      starts = new Date(starts).toISOString().split('T')[0]

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Announcement" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                    <Alert color="success" isOpen={this.state.success!=0?true:false} >
                      Successfully updated announcement. These changes will take effect immediately.
                    </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="">
                                      <Row className="mb-2">
                                        <Col xs={12} className="mb-2">
                                          <div className="custom-control custom-switch mb-2" dir="ltr">
                                              <Input type="checkbox" className="custom-control-input" id="customSwitch1" checked={enabled} name="enabled" onChange={this.updateData} />
                                              <Label className="custom-control-label" htmlFor="customSwitch1" >Show Announcement</Label>
                                          </div>
                                          <Label>Message</Label>
                                          <InputGroup>
                                            <Input type="text" placeholder="" value={message} min="0" name="message" onChange={this.updateData}/>
                                          </InputGroup>
                                        </Col>
                                        <Col md={6}>
                                          <Label className="">Show from</Label>
                                          <Row>
                                            <Col md={9}>
                                              <Input className="form-control" type="date" value={starts} name="starts" onChange={this.updateData} id="example-date-input" />
                                            </Col>
                                            <Col md={3}>
                                              <Button color="primary" className="w-100" onClick={this.showNow} outline>Now</Button>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col md={6}>
                                          <Label className="">Show until</Label>
                                          <Row>
                                            <Col md={9}>
                                              <Input className="form-control" type="date" value={expires} name="expires" onChange={this.updateData} id="example-date-input" />
                                            </Col>
                                            <Col md={3}>
                                              <Button color="primary" className="w-100" onClick={this.neverExpire} outline active={expires==false}>Doesn't expire</Button>

                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Button className="mr-2" onClick={this.save} color="primary" disabled={this.state.edited==0?true:false}>Save</Button>
                                      <Button color="warning" onClick={this.reset} outline disabled={this.state.edited==0?true:false}>Reset</Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Alert color="info" isOpen={this.state.edited!=0?true:false} >
                          <b>Changes have been made</b>
                        </Alert>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(withNamespaces()(Orders)));
