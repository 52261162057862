import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table,Nav,NavItem,NavLink, UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField, AvInput,AvRadioGroup,AvRadio } from "availity-reactstrap-validation";
import 'react-slidedown/lib/slidedown.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SlideDown } from "react-slidedown";
import classnames from 'classnames';
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

import { logoutUser, apiError } from '../../store/actions';

var fileDownload = require('js-file-download');

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [day,month,year].join('-');
}


class Reported extends Component {
  componentDidMount() {
    apiRequest("GET","reported").then(res => {
        return res.data
    }).then((data) => {

      this.setState({ data: data, loaded:1})
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Reported", link : "#" },
            ],
            activeTab:'0',
            isAlertOpen : false,
            modal_static : false,
            data : [],
            hidden:true,
            loaded:0,
            error:0,
            errormsg:"",
            trustedPlatform:false,
            modal_data:{
              id:"new",
              name:"",
              username:"",
              email:"",
              type:"admin"
            },
            modal_error:0,
            modal_delete:false,
            delete_error:0,
            delete_user:"",
            signedOut:0
        }
    }

    toggleTab = (tab) => {
      if (this.state.activeTab !== tab) {
        this.setState({
            activeTab: tab,
        });
      }
    }

    download = (id) =>{
      const report = this.state.data.find(el => el.id === id)
      var state = this.state.data
      var index = state.findIndex(el => el.id == id)
      state[index].downloading = true
      this.setState({data:state})
      apiRequest("GET",`reported/${id}/download`,undefined,{responseType: 'blob'}).then(res => {
        state[index].downloading = false
        this.setState({data:state})
        fileDownload(res.data,"report.zip");
      }).catch(err=>{
        
        state[index].downloading = undefined
        this.setState({data:state})
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 404) {
          this.setState({ error:1, errormsg:"File can't be found."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        } else if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })
    }

    viewed = (id) =>{
      const report = this.state.data.find(el => el.id === id)
      var state = this.state.data
      var index = state.findIndex(el => el.id == id)
      state[index].loading = true
      this.setState({data:state})
      apiRequest("POST",`reported/${report.id}/viewed`,{viewed: !report.viewed}).then(res => {
        if(res.error){
          state[index].loading = undefined
          this.setState({
              data: state,
              error:1, errormsg:"An error occured while updating state."
          });
          return
        }
        state[index].viewed = !report.viewed
        state[index].loading = undefined
        this.setState({data:state})
      }).catch(err=>{
        console.error(err)
        state[index].loading = undefined
        this.setState({data:state})
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 404) {
          this.setState({ error:1, errormsg:"Order cannot be found, try refreshing the page."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        } else if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      const formattedData = this.state.data.filter((el) => this.state.activeTab == el.viewed)
        return (
            <React.Fragment>
                <div  className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Reported" breadcrumbItems={this.state.breadcrumbItems} />
                        <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                          {this.state.errormsg}
                        </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                      <Nav tabs className="nav-tabs-custom mb-4">
                                        <NavItem>
                                            <NavLink onClick={() => { this.toggleTab('0'); }} className={classnames({ active: this.state.activeTab == '0' }, "font-weight-bold p-3")}>Not Viewed<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => (el.viewed==0)).length}</span></NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={() => { this.toggleTab('1'); }} className={classnames({ active: this.state.activeTab == '1' }, "p-3 font-weight-bold")}>Viewed<span className="ml-1 badge badge-pill badge-primary">{this.state.data.filter((el) => (el.viewed==1)).length}</span></NavLink>
                                        </NavItem>
                                      </Nav>
                                        <div  className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{borderCollapse:"collapse", borderSpacing : 0, width:"100%"}}>
                                                <thead  className="thead-light">
                                                    <tr>
                                                      <th></th>
                                                      <th>Email</th>
                                                      <th>Description</th>
                                                      <th>Created</th>
                                                      <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(formattedData.length == 0)?(
                                                      <tr><td colSpan={5} className="text-center">No reported files</td></tr>
                                                    ) :
                                                    formattedData.map((report, key) =>{
                                                        return <tr key={report.id}>
                                                            <td><Button outline color="primary" size="sm" disabled={(report.loading || false)} onClick={()=>this.viewed(report.id)}>{report.loading?<div style={{width:"10px", height:"10px"}}role="status" className="mx-4 spinner-grow"></div>:(this.state.activeTab=="0"?"Move to viewed":"Move to not viewed")}</Button></td>
                                                            <td>{report.email}</td>
                                                            <td>{report.description}</td>
                                                            <td>{formatDate(report.created)}</td>
                                                            <td><Button outline color="primary" size="sm" disabled={(report.downloading || false)} onClick={()=>this.download(report.id)}>{report.downloading?<div style={{width:"10px", height:"10px"}}role="status" className="mx-4 spinner-grow"></div>:"Download"}</Button></td>
                                                        </tr>
                                                      })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Reported));
