import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link,useParams } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLoginPin, apiError } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { isUserAuthenticated } from '../../helpers/backend_helper';
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {  username : "", pin : "" }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
      const search = this.props.location.search;
      const page = new URLSearchParams(search).get("page");
      this.props.checkLoginPin(values, this.props.history,page);
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        if(localStorage.getItem("trustedPlatform") == 0){
          this.props.history.push("/login"+this.props.location.search)
        }

        if(isUserAuthenticated()){
          this.props.history.push("/dashboard")
        }
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }

    render() {
        return (
            <React.Fragment>

                <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4}>
                    </Col>
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center mb-3">
                                                <div>
                                                    <div className="logo logo-dark"><img src={logodark} height="70" alt="logo"/></div>
                                                    <div className="logo logo-light"><img src={logolight} height="70" alt="logo"/></div>
                                                    <h5 className="mt-2">Trusted platform log in</h5>
                                                </div>

                                            </div>


                                            {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null }

                                            <div className="p-2 mt-2">
                                                <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="username">Username</Label>
                                                        <AvField name="username" value={this.state.username} type="text" className="form-control" id="username" validate={{required: true}} placeholder="Enter username"/>
                                                    </FormGroup>

                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="userpin">Pin or Password</Label>
                                                        <AvField name="pin" value={this.state.password} type="password" className="form-control" id="userpin" placeholder="Enter pin"/>
                                                    </FormGroup>

                                                    {/*}<div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div>*/}

                                                    <div className="mt-4 text-center">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </div>

                                                    {/*}<div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div>*/}
                                                </AvForm>
                                            </div>
                                            {/*<div className="mt-5 text-center">
                                                <a>Sign in with password instead</a>
                                            </div>*/}
                                            <div className="mt-5 text-center">
                                                <p>© {new Date().getFullYear()} Het Laser Lokaal</p>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={4}>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLoginPin, apiError })(Login));
