import React, { Component } from 'react';
import {Alert, Container, Card, CardBody, Row, Col } from "reactstrap";

//Import Calendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import ReactDOM from 'react-dom';
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/bootstrap/main.css";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Calendar", link : "#" },
            ],
            calendarWeekends: true,
            calendarEvents: [
            ],
            loaded:0,
            error:0
        }
    }

    componentDidMount(){
      apiRequest("GET","calendar").then(res => {
        return res.data
      }).then((data) => {
        this.setState({ calendarEvents: data, loaded:1 })
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }

      const EventDetail = ({ event, el }) => {
        const content = <div>{event.title}<span style={{display: "block", fontSize:"10px"}}>{event._def.extendedProps.description}</span></div>;
        ReactDOM.render(content, el);
        return el;
      };

      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Calendar" breadcrumbItems={this.state.breadcrumbItems} />
                <Row>
                  <Col xs={12}>
                      <Card>
                          <CardBody>
                              <FullCalendar
                                  defaultView="dayGridWeek"
                                  plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin ]}
                                  handleWindowResize={true}
                                  themeSystem="bootstrap"
                                  header={{
                                  left: "prev,next today",
                                  center: "title",
                                  right: "dayGridMonth,dayGridWeek,dayGridDay"
                                  }}
                                  firstDay={1}
                                  editable={false}
                                  droppable={false}
                                  eventLimit={true}
                                  selectable={false}
                                  resourceEditable={false}
                                  eventRender={EventDetail}
                                  events={this.state.calendarEvents}
                              />
                              <div style={{clear : "both"}}></div>
                          </CardBody>
                      </Card>
                  </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Calendar));
