import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
import errorImg from "../../assets/images/error-img.png";

class Error404 extends Component {
    render() {
        return (
            <React.Fragment>
              <div className="preloader">
                <div className="text-center loader">
                    <h1 className="font-weight-bold text-error mb-3"><img src={errorImg} alt="" className="error-img w-25"/></h1>
                    <h3 className="">Sorry, page not found</h3>
                    <div className="mt-5 text-center">
                        <a onClick={()=>this.props.history.goBack()} className="btn btn-primary waves-effect waves-light" >Go back</a>
                    </div>
                </div>
              </div>
            </React.Fragment>
        );
    }
}

export default Error404;
