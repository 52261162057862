import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { SET_TOKEN,GET_TOKEN, CHECK_LOGIN,  CHECK_LOGIN_PIN, LOGOUT_USER } from './actionTypes';
import { setToken,apiError, loginUserSuccessful, logoutUserSuccess, deleteToken } from './actions';
// import { setToken } from '../token/actions';
import history from "../../../history.js";
// AUTH related methods
import { postLogin,postLogout,PostToken } from '../../../helpers/backend_helper';
import jwt from 'jsonwebtoken'
//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history, page } }) {
  try {
    const response = yield call(postLogin, process.env.REACT_APP_BASE_URL+'auth/login', {username: user.username, password: user.password});
    // localStorage.setItem("authToken", JSON.stringify(response));
    // yield put(loginUserSuccessful(response.token));
    let decoded = yield call(jwt.decode, response.token)
    yield put(setToken({token:response.token,content:decoded}));
    
    history.push(page || "/dashboard");
  } catch (error) {
    console.error(error)
    yield put(apiError(error));
  }
}

function* tokenSet({ payload: { token } }) {
  try {
    let decoded = yield call(jwt.decode, token)
    yield put(setToken({token:token,content:decoded}));
  } catch (error) {
    console.error(error)
  }
}

function* tokenGet() {
  try {
    const response = yield call(PostToken, process.env.REACT_APP_BASE_URL+'auth/refreshtoken');
    let decoded = yield call(jwt.decode, response.token)
    yield put(setToken({token:response.token,content:decoded}));
  } catch (error) {
    console.error(error)
    yield put(setToken({token:undefined,content:undefined}));
    
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history,returnpath } }) {
  try {

    const response = yield call(postLogout, process.env.REACT_APP_BASE_URL+'auth/logout');
    yield put(deleteToken());
    yield put(logoutUserSuccess(response.token));
    
    if(returnpath != "/logout"){
      history.push('/login?page='+returnpath);
    } else {
      history.push('/login');
    }
  } catch (error) {
      yield put(apiError(error));
  }
}
export function* watchTokenGet() {
  yield takeEvery(GET_TOKEN, tokenGet)
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchTokenSet() {
  yield takeEvery(CHECK_LOGIN, tokenSet)
}
// export function* watchUserLoginPin() {
//     yield takeEvery(CHECK_LOGIN_PIN, loginUserPin)
// }

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchTokenGet),
        // fork(watchUserLoginPin),
    ]);
}

export default loginSaga;
