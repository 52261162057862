import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
  d.setMilliseconds(0);
  return new Date(d.setDate(diff));
}
function weekPercentage(){
  var date = new Date();
  var monday = getMonday(date)

  return Math.round((date.getTime()-monday.getTime())/6048000)
}

function monthPercentage(){
  var date = new Date();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  var startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  return Math.round((date.getTime()-startOfMonth.getTime())/(864000*new Date(year, month, 0).getDate()))
}
class EarningReports extends Component {
    state = {
        menu : false,
        series: [weekPercentage()],
        options : {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#5664d2'],
            stroke: {
                lineCap: 'round'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '70%',

                    },
                    track: {
                        margin: 0,
                        background: "#F2F2F2",
                        opacity:0.4
                    },

                    dataLabels: {
                        show: false
                    }
                }
            },
            states: {
              hover: {
                  filter: {
                      type: 'none',
                  }
              },
              active: {
                  filter: {
                      type: 'none',
                  }
              },
              normal: {
                  filter: {
                      type: 'none',
                  }
              },
            }
        },
        series2: [monthPercentage()],
        options2 : {
            chart: {
                sparkline: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1cbb8c'],
            stroke: {
                lineCap: 'round'
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '70%'
                    },
                    track: {
                        margin: 0,
                        background: "#F2F2F2",
                        opacity:0.4
                    },

                    dataLabels: {
                        show: false
                    }
                }
            },
            states: {
              hover: {
                  filter: {
                      type: 'none',
                  }
              },
              active: {
                  filter: {
                      type: 'none',
                  }
              },
              normal: {
                  filter: {
                      type: 'none',
                  }
              },
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                                <Card>
                                    <CardBody>

                                        <h4 className="card-title mb-4">Revenue reports</h4>
                                        <div className="text-center">
                                            <Row>
                                                <Col sm={6}>
                                                    <div>
                                                        <div className="mb-3">
                                                            <div id="radialchart-1" className="apex-charts">
                                                            <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="60" />
                                                            </div>
                                                        </div>

                                                        <p className="text-muted text-truncate mb-2">Weekly Revenue</p>
                                                        <h5>&euro;{this.props.data.weekly}</h5>
                                                    </div>
                                                </Col>

                                                <Col sm={6}>
                                                    <div className="mt-5 mt-sm-0">
                                                        <div className="mb-3">
                                                            <div id="radialchart-2" className="apex-charts">
                                                            <ReactApexChart options={this.state.options2} series={this.state.series2} type="radialBar" height="60" />
                                                            </div>
                                                        </div>

                                                        <p className="text-muted text-truncate mb-2">Monthly Revenue</p>
                                                        <h5>&euro;{this.props.data.monthly}</h5>
                                                    </div>
                                                </Col>

                                            </Row>

                                        </div>
                                    </CardBody>
                                </Card>
            </React.Fragment>
        );
    }
}

export default EarningReports;
