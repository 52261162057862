import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link,useParams } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';
import { isUserAuthenticated } from '../../helpers/backend_helper';

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {  username : "", password : "" }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
      const search = this.props.location.search;
      const page = new URLSearchParams(search).get("page");
        this.props.checkLogin(values, this.props.history,page);
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg")
        if(isUserAuthenticated()){
          this.props.history.push("/dashboard")
        }
    }

    componentDidUpdate(prevProps) {
      if(this.props.loginError == -1){
        this.props.history.push("/pages-404")
      }
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }

    render() {
        return (
            <React.Fragment>

                <div>
            <Container fluid className="p-0">
                <div className='mx-auto' style={{maxWidth:"500px"}}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center mb-3">
                                                <div>
                                                    <div className="logo logo-dark"><img src={logodark} height="70" alt="logo"/></div>
                                                    <div className="logo logo-light"><img src={logolight} height="70" alt="logo"/></div>
                                                </div>

                                            </div>


                                            {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null }

                                            <div className="p-2 mt-2">
                                                <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="username">Username</Label>
                                                        <AvField autoComplete="username" name="username" value={this.state.username} type="text" className="form-control" id="username" validate={{required:true}} placeholder="Enter username"/>
                                                    </FormGroup>

                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="userpassword">Password</Label>
                                                        <AvField  autoComplete="password" name="password" value={this.state.password} type="password" className="form-control" id="userpassword" validate={{required: true}} placeholder="Enter password"/>
                                                    </FormGroup>

                                                    {/*}<div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div>*/}

                                                    <div className="mt-4 text-center">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </div>

                                                    {/*}<div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div>*/}
                                                </AvForm>
                                            </div>

                                            <div className="mt-5 text-center">
                                                <p>© {new Date().getFullYear()} Het Laser Lokaal</p>
                                                <p>v{process.env.REACT_APP_VERSION}</p>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={4}>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        </div>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));
