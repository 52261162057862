import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";
import distinctColors from 'distinct-colors'

var palette = distinctColors({count:50,lightMin:35,lightMax:90,chromaMin:15,chromaMax:90}).map((el)=>el.hex())

function roundTo(value, decimals) {
  return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}
class SalesAnalytics extends Component {
    state = {
        series: this.props.data.values || [],
        options : {
            labels: this.props.data.labels || [],
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%'
                    },
                    expandOnClick: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
              show:false,
            },
            legend: {
                show: false,
            },
            colors: ['#025398', '#1cbb8c', '#fcb92c','#ff3d60','#f1734f',...palette],//['#025398', '#1cbb8c', '#fcb92c','#ff3d60','#f1734f', '#74788d'],
            states: {
              active: {
                  filter: {
                      type: 'none',
                  }
              },
              hover: {
                  filter: {
                    type: 'darken',
                    value: 0.35,
                  }
              },
            }
        },
        colors: ['#025398', '#1cbb8c', '#fcb92c','#ff3d60','#f1734f',...palette]//['#025398', '#1cbb8c', '#fcb92c','#ff3d60','#f1734f', '#74788d']
    }
    render() {
        return (
            <React.Fragment>
                                <Card>
                                    <CardBody>
                                        {/*}<div className="float-right">
                                            <select className="custom-select custom-select-sm">
                                                <option defaultValue>Apr</option>
                                                <option value="1">Mar</option>
                                                <option value="2">Feb</option>
                                                <option value="3">Jan</option>
                                            </select>
                                        </div>*/}
                                        <h4 className="card-title mb-4">Popular materials</h4>

                                        <div id="donut-chart" className="apex-charts">
                                        <ReactApexChart options={this.state.options} series={this.state.series} type="donut" height="230" />
                                        </div>

                                        <Row>
                                          {this.state.options.labels.slice(0,5).map((el,i)=>
                                            <Col xs={4} key={i}>
                                                <div className="text-center mt-4">
                                                    <p className="mb-2 text-truncate" title={el}><i style={{color:this.state.colors[i]}} className="mdi mdi-circle font-size-10 mr-1"></i>{el}</p>
                                                    <h5>{this.props.data.values[i]} %</h5>
                                                </div>
                                            </Col>
                                          )}
                                          <Col xs={4}>
                                              <div className="text-center mt-4">
                                                  <p className="mb-2 text-truncate" title={"Other"}><i style={{color:"#74788d"}} className="mdi mdi-circle font-size-10 mr-1"></i>Other</p>
                                                  <h5>{roundTo(this.props.data.values.slice(5).reduce((acc,val)=>acc+val,0),2)} %</h5>
                                              </div>
                                          </Col>

                                        </Row>
                                    </CardBody>
                                </Card>
            </React.Fragment>
        );
    }
}

export default SalesAnalytics;
