import { SET_TOKEN,GET_TOKEN,DELETE_TOKEN,CHECK_LOGIN, CHECK_LOGIN_PIN, LOGIN_USER_SUCCESSFUL, API_ERROR,LOGOUT_USER, LOGOUT_USER_SUCCESS } from './actionTypes';

export const checkLogin = (user, history, page) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, history, page }
    }
}
export const checkLoginPin = (user, history, page) => {
    return {
        type: CHECK_LOGIN_PIN,
        payload: { user, history, page }
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const logoutUser = (history,returnpath) => {
    return {
        type: LOGOUT_USER,
        payload: { history,returnpath }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}


export const setToken = (token) => {
  return {
      type: SET_TOKEN,
      payload: token
  }
}

export const getToken = (history) => {
  return {
      type: GET_TOKEN,
      payload: {
        history
      }
  }
}

export const deleteToken = () => {
    return {
        type: DELETE_TOKEN,
        // payload: { user, history, page }
    }
}
