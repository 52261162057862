export const CHECK_LOGIN = 'check_login';
export const CHECK_LOGIN_PIN = 'check_login_pin';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const API_ERROR = 'api_failed';


export const GET_TOKEN = 'get_token';
export const SET_TOKEN = 'set_token';
export const DELETE_TOKEN = 'delete_token';
export const GET_TOKEN_CONTENT = 'get_token_content';