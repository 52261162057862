import React, { Component } from "react";
import { Alert, Container, Row, Col } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";


import { logoutUser, apiError } from '../../store/actions';

import { getLoggedInUser,apiRequest } from '../../helpers/backend_helper';
class Dashboard extends Component {
  componentDidMount() {
    const authToken = getLoggedInUser();
    apiRequest("GET","dashboard").then(res => {
      return res.data
    }).then((data) => {
      this.setState({ data: data, loaded:1 })
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

  state = {
      loaded:0,
      error:0,
      data:null,
      breadcrumbItems : [
          { title : "Het Laser Lokaal", link : "/" },
          { title : "Dashboard", link : "/dashboard" },
      ],
      reports : [
          { icon : "ri-stack-line", title : "Sales Revenue", value : "€ 100.56", rate : "-2.4%", desc : "Compared to yesterday",arrow:"down" },
          { icon : "ri-store-2-line", title : "Visitors", value : "250", rate : "2", desc : "Right now" ,arrow:"up" },
          { icon : "ri-briefcase-4-line", title : "Average Price", value : "€ 40.24", rate : "", desc : "",arrow:"up" },
          { icon : "ri-briefcase-4-line", title : "Average Cutting time", value : "12:55 minutes", rate : "", desc : "",arrow:"up" },
          { icon : "ri-briefcase-4-line", title : "Conversion", value : "8", rate : "", desc : "",arrow:"up" },
          { icon : "ri-briefcase-4-line", title : "Open orders", value : "12", rate : "50 minutes", desc : "Estimated cutting time",arrow:"up" },
      ]
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      let admin = getLoggedInUser().role == "admin"
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xl={admin?8:12}>
                                <Row>
                                    <MiniWidgets reports={this.state.data.reports} />
                                </Row>

                                {/* revenue Analytics
                                <RevenueAnalytics  data={this.state.data.analytics}/> */}
                                <LatestTransactions data={this.state.data.orders}/>
                            </Col>
                            {admin?<>
                            <Col xl={4}>

                                {/* sales Analytics */}
                                <SalesAnalytics data={this.state.data.materialPopularity}/>

                                {/* earning reports */}
                                <EarningReports data={this.state.data.earnings}/>

                            </Col>
                            </>:""}
                        </Row>


                        <Row>
                            {/* sources <Sources/>*/}


                            {/* recent activity
                            <RecentlyActivity/>*/}

                            {/* revenue by locations
                            <RevenueByLocations/> */}

                        </Row>


                        <Row>
                           

                            {/* latest transactions
                            <LatestTransactions/>*/}
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Dashboard));
