import React, { Component } from 'react';
import { Alert,Table, Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledTooltip, Input, Label, Button,FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import classnames from 'classnames';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withRouter, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Orders extends Component {

    constructor(props) {
        super(props);
        //this.state.orderId = "-"
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Settings", link : "#" },
            ],
            activeTab:0,
            data:{},

            loaded:0,
            error:0,
            edited:0,
            success:0,
            errormsg:"",
            orderId:  this.props.match.params.id
        }
    }
    componentDidMount(){
      //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
      fetch("http://localhost:9800/printers").then( async (res) => {
        let data = await res.json()
        let printerSettings = JSON.parse(localStorage.getItem("printerSettings") || "{\"label\":\"\", \"packing_slip\":\"\", \"overview\":\"\", \"label_paper\":\"\", \"packing_slip_paper\":\"\", \"overview_paper\":\"\"}")
        

        let changes = false
        if(!data.includes(printerSettings.label)){
          printerSettings.label = ""
          changes = true
        }
        if(!data.includes(printerSettings.packing_slip)){
          printerSettings.packing_slip = ""
          changes = true
        }
        if(changes){
          localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
        }
        this.setState({printerSettings, data, loaded:1})
      }).catch(err=>{
        console.log(err)
        if (!err.response) {
          this.setState({ error:"Can't connect to printer API, make sure it's installed and running.", showReset: (localStorage.getItem("printerSettings") || "") != ""})
          return
        }
        
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }
    updateData = (ev) => {
      var data = this.state.data
      if(ev.target.name == "quantity" || ev.target.name == "discount"){
        data.bulkDiscount[parseInt(ev.target.id)][ev.target.name] = !isNaN(parseFloat(ev.target.value))?parseFloat(ev.target.value):""
      } else {
        data[ev.target.name] = !isNaN(parseFloat(ev.target.value))?parseFloat(ev.target.value):""
      }
      var edited = 0;
      if(this.state.dataOriginal != JSON.stringify(data)){
        edited=1;
      }
      this.setState({data:data,edited:edited})
    }

    reset = () => {
      localStorage.removeItem("printerSettings")
      this.setState({showReset:false})
    }

    labelPrinter = (ev) => {
      var printerSettings = this.state.printerSettings
      printerSettings.label = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    packingSlipPrinter = (ev) => {
      var printerSettings = this.state.printerSettings
      printerSettings.packing_slip = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    overviewPrinter = (ev) => {
      console.log(ev.target.value)
      var printerSettings = this.state.printerSettings
      printerSettings.overview = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    labelPrinterPaper = (ev) => {
      var printerSettings = this.state.printerSettings
      printerSettings.label_paper = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    packingSlipPrinterPaper = (ev) => {
      var printerSettings = this.state.printerSettings
      printerSettings.packing_slip_paper = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    overviewPrinterPaper = (ev) => {
      console.log(ev.target.value)
      var printerSettings = this.state.printerSettings
      printerSettings.overview_paper = ev.target.value
      this.setState({printerSettings:printerSettings})
      localStorage.setItem("printerSettings", JSON.stringify(printerSettings))
    }

    render() {
      var customer = this.state.data.customer

      var tableData = []

      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
            {this.state.showReset?<Button onClick={this.reset} color="primary">Disable Print companion</Button>:""}
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Settings" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                    <Alert color="success" isOpen={this.state.success!=0?true:false} >
                      Successfully updated variables. These changes will take effect immediately.
                    </Alert>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="">
                                      <h5>Printer settings</h5>
                                      <Row>
                                        <Col xs={4} className="mb-2">
                                          <Label>Label printer</Label>
                                          <InputGroup>
                                            <select onChange={this.labelPrinter} value={this.state.printerSettings.label} className="custom-select custom-select">
                                              <option value={""}>Print to PDF</option>
                                              {this.state.data.map((item, i) => (
                                                <option key={i} value={item}>{item}</option>
                                              ))}
                                            </select>
                                          </InputGroup>
                                          <Label className="mt-2">Label paper</Label>
                                          <InputGroup>
                                            <select onChange={this.labelPrinterPaper} value={this.state.printerSettings.label_paper} className="custom-select custom-select">
                                              <option value={"A4"}>A4</option>
                                              <option value={"A5"}>A5</option>
                                              <option value={"A6"}>A6</option>
                                              <option value={"1744907 4 in x 6 in"}>4in x 6in Label</option>
                                            </select>
                                          </InputGroup>
                                        </Col>
                                        <Col xs={4}  className="mb-2">
                                          <Label>Packing slip printer</Label>
                                          <InputGroup>
                                            <select onChange={this.packingSlipPrinter} value={this.state.printerSettings.packing_slip} className="custom-select custom-select">
                                              <option value={""}>Print to PDF</option>
                                              {this.state.data.map((item, i) => (
                                                <option key={i} value={item}>{item}</option>
                                              ))}
                                            </select>
                                          </InputGroup>
                                          <Label className="mt-2">Packing slip paper</Label>
                                          <InputGroup>
                                            <select onChange={this.packingSlipPrinterPaper} value={this.state.printerSettings.packing_slip_paper} className="custom-select custom-select">
                                              <option value={"A4"}>A4</option>
                                              <option value={"A5"}>A5</option>
                                              <option value={"A6"}>A6</option>
                                              <option value={"1744907 4 in x 6 in"}>4in x 6in Label</option>
                                            </select>
                                          </InputGroup>
                                        </Col>
                                        <Col xs={4}  className="mb-2">
                                          <Label>Material overview printer</Label>
                                          <InputGroup>
                                            <select onChange={this.overviewPrinter} value={this.state.printerSettings.overview} className="custom-select custom-select">
                                              <option value={""}>Print to PDF</option>
                                              {this.state.data.map((item, i) => (
                                                <option key={i} value={item}>{item}</option>
                                              ))}
                                            </select>
                                          </InputGroup>
                                          <Label className="mt-2">Overview Paper</Label>
                                          <InputGroup>
                                            <select onChange={this.overviewPrinterPaper} value={this.state.printerSettings.overview_paper} className="custom-select custom-select">
                                              <option value={"A4"}>A4</option>
                                              <option value={"A5"}>A5</option>
                                              <option value={"A6"}>A6</option>
                                              <option value={"1744907 4 in x 6 in"}>4in x 6in Label</option>
                                            </select>
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                      
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(withNamespaces()(Orders)));
