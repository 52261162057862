import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {useServiceWorker} from './helpers/useServiceWorker';
import store from './store';


const AppWrapper = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [newVersion, setNewVersion] = useState(false);
  useEffect(() => {
    if (showReload && waitingWorker) {
      setNewVersion(true)
      
    } else {
      setNewVersion(false)
    }
  }, [waitingWorker, showReload, reloadPage]);

  return(
    <>
      {newVersion && 
        <div className='bg-white position-fixed p-3 m-3 shadow-lg rounded' style={{left:0,bottom:0,zIndex:2000}}>
          A new version is available.
          <button style={{fontSize: "0.9rem"}} className='btn btn-primary btn-sm ml-2' onClick={() => reloadPage()}>Refresh</button>
        </div>
      } 
      <App />
    </>
    )
};

const app = (
  <Provider store={store}>
      <BrowserRouter>
          <AppWrapper />
      </BrowserRouter>
  </Provider>
)


ReactDOM.render(app, document.getElementById('root'));
