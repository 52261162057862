import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { connect } from "react-redux";
import {
  hideRightSidebar,
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType, changePreloader,
  changeTopbarTheme
} from "../../../store/actions";
//i18n
import { withNamespaces } from "react-i18next";

// users
// import avatar2 from '../../../assets/images/users/avatar-2.jpg';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            topbarTheme: this.props.topbarTheme,
        };
        this.toggle = this.toggle.bind(this);
        this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.setState({
          layoutType: this.props.layoutType,
          sidebarType: this.props.leftSideBarType,
          layoutWidth: this.props.layoutWidth,
          sidebarTheme: this.props.leftSideBarTheme,
          topbarTheme: this.props.topbarTheme
        });
      }
    }

    changeTopbarTheme() {
      if(this.state.topbarTheme == "dark"){
        this.props.changeTopbarTheme("light");
      } else {
        this.props.changeTopbarTheme("dark");
      }
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    render() {

 let name = "";
   if(localStorage.getItem("authToken"))
   {
        const obj = JSON.parse(localStorage.getItem("authToken"));
        const uNm = obj.name;
        name = uNm.charAt(0).toUpperCase() + uNm.slice(1);
   }
window.topState = this.state.topbarTheme
        return (

            <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block ">
                            <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect" id="page-header-user-dropdown">
                                {/*}<img className="rounded-circle header-profile-user mr-1" src={avatar2} alt="Header Avatar"/>*/}
                                <i className="ri-account-circle-line mr-1"></i>
                                <span style={{"verticalAlign":"super"}} className="d-none d-xl-inline ml-1 text-transform">{name}</span>
                                <i className=" ri-arrow-down-s-line d-none ml-1 d-xl-inline"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {/*<DropdownItem href="#"><i className="ri-user-line align-middle mr-1"></i> {this.props.t('Profile')}</DropdownItem>*/}
                                <DropdownItem href="#" onClick={this.changeTopbarTheme}><i className={this.state.topbarTheme=="light"?"ri-moon-line align-middle mr-1":"ri-sun-line align-middle mr-1"}></i>{this.state.topbarTheme=="light"?"Dark mode":"Light mode"}</DropdownItem>
                                {/*}<DropdownItem href="#"><i className="ri-wallet-2-line align-middle mr-1"></i> {this.props.t('My Wallet')}</DropdownItem>
                                <DropdownItem className="d-block" href="#"><span className="badge badge-success float-right mt-1">11</span><i className="ri-settings-2-line align-middle mr-1"></i> {this.props.t('Settings')}</DropdownItem>
                                <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle mr-1"></i> {this.props.t('Lock screen')}</DropdownItem>*/}
                                <DropdownItem divider />
                                <DropdownItem className="text-danger" href="/logout"><i className="ri-shut-down-line align-middle mr-1 text-danger"></i> {this.props.t('Logout')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  hideRightSidebar,
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader
})(withNamespaces()(ProfileMenu));
