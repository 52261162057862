import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { withNamespaces } from "react-i18next";
import { getToken } from './store/actions';
// Import scss
import "./theme.scss";
import history from "./history.js";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
      loading: true
    };
		// this.getLayout = this.getLayout.bind(this);
	}

 	/**
	 * Returns the layout
	 */
	// getLayout = () => {
	// 	let layoutCls = VerticalLayout;

	// 	switch (this.props.layout.layoutType) {
	// 		case "horizontal":
	// 			layoutCls = HorizontalLayout;
	// 			break;
	// 		default:
	// 			layoutCls = VerticalLayout;
	// 			break;
	// 	}
	// 	return layoutCls;
	// };


  componentDidMount() {
    this.props.getToken(this.props.history)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.setState({ loading: false });
    }
  }
	render() {
		// const Layout = this.getLayout();
    if(this.state.loading){
      return (
        <React.Fragment>
          <div className="preloader">
            <div className="loader">
              <div className="status">
                <div role="status" className="spinner-grow text-primary">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>);
    }
		return (
			<React.Fragment>
				<Router history={history}>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={VerticalLayout}
								orderNum={this.props.layout.orderNum}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								isAdminOnly={route.isAdminOnly}
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
    token: state.Login.token
	};
};


export default connect(mapStateToProps, {getToken})(withNamespaces()(App));
