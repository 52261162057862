import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import LoginPin from "../pages/Authentication/LoginPin";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";
// import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

// page mateirals
import Materials from "../pages/materials/materials";
import materialEdit from "../pages/materials/materialEdit";

//page Feedback
import Feedback from "../pages/Feedback/index";

// Pages Component
//Orders Pages
import Orders from "../pages/Orders/Orders";
import Orderdetails from "../pages/Orders/Orderdetails";
import Coupons from "../pages/Orders/Coupons";

//accounts
import Users from "../pages/Users/index";
import Customers from "../pages/Customers/index";
import Quotes from "../pages/Quotes/index";
import Reported from "../pages/Reported/index";

//Analytics
import Analytics from "../pages/Analytics/index";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility

import Error404 from "../pages/Utility/Error404";

//Variables
import Variables from "../pages/Variables/index"; 

//Settings
import Settings from "../pages/Settings/index"; 

//Announcement
import Announcement from "../pages/Announcement/index";

//Dog collars
import DogCollars from "../pages/Collars/index";

const authProtectedRoutes = [

	//Icons
	{ path: "/icons-remix", component: RemixIcons },
	{ path: "/icons-materialdesign", component: MaterialDesign },
	{ path: "/icons-dripicons", component: DripiIcons },
	{ path: "/icons-fontawesome", component: FontAwesome },

	{ path: "/orders/:id", component: Orderdetails },
	{ path: "/orders", component: Orders },
	{ path: "/coupons", component: Coupons, isAdminOnly:true },

	{ path: "/variables", component: Variables, isAdminOnly:true },
  { path: "/settings", component: Settings, isAdminOnly:false },
	{ path: "/announcement", component: Announcement, isAdminOnly:true },
	{ path: "/customers", component: Customers, isAdminOnly:true },
  { path: "/quotes", component: Quotes, isAdminOnly:true },
  { path: "/reported", component: Reported, isAdminOnly:true },


	//calendar
	{ path: "/calendar", component: Calendar },

	{ path: "/materials/:id", component: materialEdit, isAdminOnly:true },
	{ path: "/materials", component: Materials, isAdminOnly:true },

	{ path: "/feedback", component: Feedback, isAdminOnly:true },
	{ path: "/analytics", component: Analytics, isAdminOnly:true },
	{ path: "/collars", component: DogCollars, isAdminOnly:true },

	{ path: "/users", component: Users, isAdminOnly:true },

	{ path: "/dashboard", component: Dashboard },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	// { path: "/login/pin", component: LoginPin },
	{ path: "/login", component: Login },
	{ path: "/pages-404", component: Error404 },
];

export { authProtectedRoutes, publicRoutes };
