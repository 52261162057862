import React, { Component } from 'react';
import { Alert,Table, Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink, UncontrolledTooltip, Input, Label, Button,FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import classnames from 'classnames';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { withRouter, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';
import { withNamespaces } from "react-i18next";
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Orders extends Component {

    constructor(props) {
        super(props);
        //this.state.orderId = "-"
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Dog Collars", link : "#" },
            ],
            activeTab:0,
            data:[],
            loaded:0,
            error:0,
            orderId:  this.props.match.params.id
        }
    }
    componentDidMount(){
      //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
      apiRequest("GET",`orders/${this.state.orderId}`).then(res => {
        if(res.status == 200){
          return res.data
        }
      }).then((data) => {
        this.setState({ data: data, loaded:1 })
      })
      .catch(err=>{
        this.setState({error:1})
        alert(err);
        if (err.response && err.response.status) {
            throw err.response.data;
        } else {
          throw "An error occured, try again later"
        }
      })
    }

    render() {
      var customer = this.state.data.customer

      var tableData = []

      // <% var totalTime = 0; for (var i = 0; i < designs.length; i++) { %>
      //
      //   <tr>
      //     <td>
      //       <div class="custom-control custom-checkbox">
      //         <input type="checkbox" class="custom-control-input" id="customCheck1">
      //         <label class="custom-control-label" htmlFor="customCheck1"></label>
      //       </div>
      //     </td>
      //     <td><%= designs[i].file %></td>
      //     <td><img src="<%= designs[i].visualisation %>" /></td>
      //     <td><%= designs[i].material.materialName %><br> <%= designs[i].material.XY[0] %>x<%= designs[i].material.XY[0] %> cm</td>
      //     <td><%= minTommss((designs[0].estTime.raster+designs[0].estTime.engrave+designs[0].estTime.vector).toFixed(2)) %> minuten</td>
      //     <td>Snij- en graveerkosten: &euro; <%= designs[i].price.operations %><br /> Materiaal: &euro; <%= designs[i].price.material %> <br /></td>
      //     <td><i class="fas fa-arrow-down layers" data-id="<%=i%>"></i></td>
      //     <td><a href="/admin/admin/download/<%= id %>/<%= designs[i].file %>" download>Download</a></td>
      //   </tr>
      //   <tr data-id="<%=i%>" style="display:none;">
      //     <td colspan="3">
      //
      //     </td>
      //     <td colspan="5">
      //       <%var layers = designs[i].designColors;%>
      //       <table class="table design-colors text-center">
      //         <thead>
      //           <tr>
      //             <th>Kleur</th>
      //             <th>Vlakgraveren</th>
      //             <th>Lijngraveren</th>
      //             <th>Snijden</th>
      //           </tr>
      //         </thead>
      //         <% for (var i in layers) { %>
      //         <tr>
      //           <td>
      //             <div class="color-container mx-auto d-inline-block">
      //               <div class="color " title="<%=i%>" style="--c: <%=i%>" >
      //               </div>
      //             </div>
      //             <b class="inline-block" style="font-family:monospace; text-transform:upperCase;">&nbsp;<%=i%></b>
      //           </td>
      //           <td>
      //             <%if(parseInt(layers[i].raster)){%><i class="fas fa-check"></i><%}%>
      //           </td>
      //           <td>
      //             <%if(parseInt(layers[i].engrave)){%><i class="fas fa-check"></i><%}%>
      //           </td>
      //           <td>
      //             <%if(parseInt(layers[i].cut)){%><i class="fas fa-check"></i><%}%>
      //           </td>
      //         </tr>
      //         <%}%>
      //       </table>
      //     </td>
      //   </tr>
      // <% totalTime += (designs[0].estTime.raster+designs[0].estTime.engrave+designs[0].estTime.vector) }%>

      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
              <Alert color="danger">
                An error occured, try again later
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Dog Collar settings" breadcrumbItems={this.state.breadcrumbItems}/>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="">
                                      <h5>Design settings</h5>
                                      <Row>
                                      <Col xs={4} className="mb-2">
                                        <Label>Startup price (per design)</Label>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <InputGroupText>&euro;</InputGroupText>
                                          </InputGroupAddon>
                                          <Input placeholder="Startup price" />
                                        </InputGroup>
                                      </Col>
                                      <Col xs={4}  className="mb-2">
                                        <Label>Cutting Price (per minute)</Label>
                                        <InputGroup>
                                          <InputGroupAddon addonType="prepend">
                                            <InputGroupText>&euro;</InputGroupText>
                                          </InputGroupAddon>
                                          <Input placeholder="Cutting Price" />
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>/min</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>
                                      <Col xs={4} className="mb-2">
                                        <Label>Engraving Lineheight</Label>
                                        <InputGroup>
                                          <Input placeholder="Engraving Lineheight" />
                                          <InputGroupAddon addonType="append">
                                            <InputGroupText>mm</InputGroupText>
                                          </InputGroupAddon>
                                        </InputGroup>
                                      </Col>
                                      </Row>
                                      <Row className="border-top">
                                      <Col xs={6}>
                                      <h5 className="mt-2">Shipping settings</h5>
                                        <Row>
                                          <Col xs={4}>
                                            <Label>Size</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{'>'}</InputGroupText>
                                              </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                          <Col xs={8}>
                                            <Label>Shipping costs</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>&euro;</InputGroupText>
                                              </InputGroupAddon>
                                              <Input placeholder="Cost" />
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>&euro;</InputGroupText>
                                              </InputGroupAddon>
                                              <Input placeholder="Cost" />
                                            </InputGroup>
                                          </Col>
                                          <Col xs={12}>
                                            <Label>Operations multiplier - Fast</Label>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Fast" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>X</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col xs={6} className="border-left">
                                        <h5 className="mt-2">Bulk discount</h5>
                                        <Row>
                                          <Col xs={4}>
                                            <Label>Quantity</Label>
                                            <InputGroup className="mb-2">
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>{'>'}</InputGroupText>
                                              </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>{'>'}</InputGroupText>
                                            </InputGroupAddon>
                                              <Input placeholder="0" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>cm</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                          <Col xs={8}>
                                            <Label>Percentage Discount</Label>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Discount" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Discount" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Discount" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Discount" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                            <InputGroup className="mb-2">
                                              <Input placeholder="Discount" />
                                              <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                              </InputGroupAddon>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>
                                      </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

// <table class="table designs">
//   <thead>
//     <tr>
//       <th>Klaar</th>
//       <th>Bestand</th>
//       <th>Visualisatie</th>
//       <th>Materiaal</th>
//       <th>Snijtijd</th>
//       <th>Prijs</th>
//       <th>Lagen</th>
//       <th>Download</th>
//     </tr>
//   </thead>
//   <tbody>
//     <% var totalTime = 0; for (var i = 0; i < designs.length; i++) { %>
//       <tr>
//         <td>
//           <div class="custom-control custom-checkbox">
//             <input type="checkbox" class="custom-control-input" id="customCheck1">
//             <label class="custom-control-label" htmlFor="customCheck1"></label>
//           </div>
//         </td>
//         <td><%= designs[i].file %></td>
//         <td><img src="<%= designs[i].visualisation %>" /></td>
//         <td><%= designs[i].material.materialName %><br> <%= designs[i].material.XY[0] %>x<%= designs[i].material.XY[0] %> cm</td>
//         <td><%= minTommss((designs[0].estTime.raster+designs[0].estTime.engrave+designs[0].estTime.vector).toFixed(2)) %> minuten</td>
//         <td>Snij- en graveerkosten: &euro; <%= designs[i].price.operations %><br /> Materiaal: &euro; <%= designs[i].price.material %> <br /></td>
//         <td><i class="fas fa-arrow-down layers" data-id="<%=i%>"></i></td>
//         <td><a href="/admin/admin/download/<%= id %>/<%= designs[i].file %>" download>Download</a></td>
//       </tr>
//       <tr data-id="<%=i%>" style="display:none;">
//         <td colspan="3">
//
//         </td>
//         <td colspan="5">
//           <%var layers = designs[i].designColors;%>
//           <table class="table design-colors text-center">
//             <thead>
//               <tr>
//                 <th>Kleur</th>
//                 <th>Vlakgraveren</th>
//                 <th>Lijngraveren</th>
//                 <th>Snijden</th>
//               </tr>
//             </thead>
//             <% for (var i in layers) { %>
//             <tr>
//               <td>
//                 <div class="color-container mx-auto d-inline-block">
//                   <div class="color " title="<%=i%>" style="--c: <%=i%>" >
//                   </div>
//                 </div>
//                 <b class="inline-block" style="font-family:monospace; text-transform:upperCase;">&nbsp;<%=i%></b>
//               </td>
//               <td>
//                 <%if(parseInt(layers[i].raster)){%><i class="fas fa-check"></i><%}%>
//               </td>
//               <td>
//                 <%if(parseInt(layers[i].engrave)){%><i class="fas fa-check"></i><%}%>
//               </td>
//               <td>
//                 <%if(parseInt(layers[i].cut)){%><i class="fas fa-check"></i><%}%>
//               </td>
//             </tr>
//             <%}%>
//           </table>
//         </td>
//       </tr>
//     <% totalTime += (designs[0].estTime.raster+designs[0].estTime.engrave+designs[0].estTime.vector) }%>
//   </tbody>
//   <tfoot>
//     <tr>
//       <th></th>
//       <th></th>
//       <th></th>
//       <th></th>
//       <th><%=minTommss((totalTime).toFixed(2))%> minuten</th>
//       <th></th>
//       <th><a href="/admin/download/<%= id %>/all" download>Download alles</a></th>
//     </tr>
//   </tfoot>
// </table>

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(withNamespaces()(Orders)));
