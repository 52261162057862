import axios from 'axios';
import { getToken, setToken  } from '../store/auth/login/actions';
import store from '../store'
import history from "../history.js";
window.history1 = history;

const getLoggedInUser = () => {
  const user = store.getState().Login.content;
  return user
}

const getAccessToken = () => {
  const user = store.getState().Login.token;
  return user
}

let updating_refreshing_token = false
const INTERVAL_MS = 100;
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    return new Promise((resolve, reject) => {
      if(config._force || updating_refreshing_token == false){
        const token = store.getState().Login.token;
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
          config.withCredentials = true
        }
        resolve(config)
        return
      }
      let interval = setInterval(() => {
        if (updating_refreshing_token == false) {
          clearInterval(interval)
          const token = store.getState().Login.token;
          if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            config.withCredentials = true
          }
          resolve(config)
        } 
      }, INTERVAL_MS)
    })
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    let originalConfig = err.config;

    if (originalConfig.url !== "auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && originalConfig._retry != true) {
        originalConfig._retry = true;

        try {
          if(updating_refreshing_token == false){
            updating_refreshing_token = true;
            try{
              const rs = await instance({
                method: "POST",
                url: "auth/refreshtoken",
                _retry: true,
                _force: true
              });
    
              const { token } = rs.data;
              store.dispatch(setToken({token:token}))
            } catch (e) {
              console.error(e)
            }
            updating_refreshing_token = false
          }
          
          return instance(originalConfig);
        } catch (_error) {
          _error.config._retry = true;
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

const apiRequest = (method,endpoint, data, rest) => {
  
  return instance({
    method: method,
    url: process.env.REACT_APP_BASE_URL+"admin/"+endpoint,
    data: data,
    ...rest
  }).catch((e)=>{
    if(e.response && e.response.status == 401){
      if(window.location.pathname != "/logout"){
        history.push('/login?page='+window.location.pathname);
      } else {
        history.push('/login');
      }
      window.location.reload();
    }
    throw e
  })
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null && getLoggedInUser() !== undefined;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status ) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });
}

// Login Method
const postLogin = (url, data) => {
  return axios.post(url, data,{withCredentials: true}).then(response => {
      if (response.status === 400 || response.status === 500)
          throw response.data;
      return response.data;
  }).catch(err => {
      if (!err.response) {
        throw "Can't connect to admin API"
        return
      }
      var message;
      if(err.response.status == 403) {
        localStorage.setItem("trustedPlatform",0)
      }
      if (err.response && err.response.status ) {
          throw err.response.data;
      } else {
        throw "An error occured, try again later"
      }
  });
}

const PostToken = (url, data) => {
  return axios.post(url, data,{withCredentials: true}).then(response => {
      if (response.status === 400 || response.status === 500)
          throw response.data;
      return response.data;
  }).catch(err => {
      if (!err.response) {
        throw -1
        return
      }

      if(err.response.status == 401) {
        return {token: undefined}
      }
      var message;
      if (err.response && err.response.status ) {
          throw err.response.data;
      } else {
        throw "An error occured, try again later"
      }
  });
}

const postLogout = (url,) => {
  const token = store.getState().Login.token;
  return axios({
    method: "POST",
    url, 
    withCredentials: true,
    headers: {
    'Authorization': 'Bearer '+token,
    }
  }).then(response => {
      if (response.status === 400 || response.status === 500)
          throw response.data;
      return response.data;
  }).catch(err => {
    if (!err.response) {
      throw "Can't connect to admin API"
      return
    }
      var message;
      if(err.response.status == 403) {
        localStorage.setItem("trustedPlatform",0)
      }
      if (err.response && err.response.status ) {
        return  err.response.data;
      } else {
        return "An error occured, try again later"
      }
  });
}

// postForgetPwd
const postForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


export { apiRequest,getLoggedInUser,getAccessToken,PostToken, isUserAuthenticated, postRegister, postLogin,postLogout, postForgetPwd }
