import React from 'react'
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
// import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/solid'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAnglesLeft, faAngleLeft, faAnglesRight, faAngleRight, faSort, faSortUp, faSortDown, faUser, faTag } from '@fortawesome/free-solid-svg-icons'

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Button({ children, className, ...rest }) {
    return (
        <button
        type="button"
        className={
            classNames(
            "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200",
            className
            )}
        {...rest}
        >
        {children}
        </button>
    )
}
// bg-gray-50 dark:bg-gray-700 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
function PageButton({ children, className, ...rest }) {
    return (
        <button
        type="button"
        className={
            classNames(
            "btn btn-outline-primary align-middle",
            className
            )}
        {...rest}
        style={{lineHeight:1}}
        >
        {children}
        </button>
    )
}


// import { SortIcon, SortUpIcon, SortDownIcon } from './shared/Icons'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className=''>
      <span className="">
        <input
            type="text"
            className="form-control form-control-sm ml-0 my-1"
            value={value || ""}
            onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
            }}
            placeholder={`Search`}
        />
        </span>
    </div>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <div className='flex gap-x-2 items-baseline'>
      <span className="text-gray-700 dark:text-gray-200">{render("Header")}: </span>
      <label className="flex gap-x-2 items-baseline">
      <select
        className="cursor-pointer outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-gray-100 dark:hover:bg-gray-750"
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
      </label>
    </div>
  )
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={
        classNames(
          "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full ",
          status.startsWith("active") ? "bg-green-100 text-green-800" : null,
          status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
          status.startsWith("offline") ? "bg-red-100 text-red-800" : null,
        )
      }
    >
      {status}
    </span>
  );
};

export function NameWithColor({ value, column, row }) {
  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let color = [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ]
    return color.join(",")
    
  }

  return (
    <span
      className={
        classNames(
          "px-3 py-1 leading-wide font-bold text-sm rounded-full ",
          "bg-[color:rgba(var(--color),0.15)]",
          "text-[color:rgb(var(--color))]"
        )
      }
      style={{"--color":hexToRgb(row.original[column.colorAccessor])}}
    >
      {value}
    </span>
  );
};

export function AvatarCell({ value, column, row }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-gray-900 dark:text-gray-500">{value}</div>
        <div className="text-sm text-gray-500 dark:text-gray-300">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  )
}

export const Datatable = ({ columns, data, resetPage = null,...props }) => {
  let [sort,setSort] = React.useState({sortBy: null, dir: null})
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    filteredRows,
    ...rest
  } = useTable({
    columns,
    data,
    initialState: {
        pageSize:20
    },
    autoResetSortBy: false,
    autoResetPage: false,
  },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination,  // new
  )

  React.useEffect(()=>{
    const search = props.history.location.search;
    let params = new URLSearchParams(search)
    let desc = params.get("dir") === "desc"
    let page = params.get("page")
    let id = params.get("sortby")
    if(page){
      gotoPage(page)
      if(page >= pageCount) {
        params.delete("page")
        props.history.replace(props.history.location.pathname+"?"+params)
      }
    }
    if(id)
      rest.toggleSortBy(id,desc)
  },[])

  const [reset,setReset] = React.useState(null)
  React.useEffect(()=>{
    if(reset !== null)
      gotoPage(0)
    setReset(resetPage)
  },[resetPage])

  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(()=>{
    const search = props.history.location.search;
    let params = new URLSearchParams(search)
    if(loaded){
      params.set("page",state.pageIndex)
      props.history.replace(props.history.location.pathname+"?"+params)
    }
    setLoaded(true)
  },[state.pageIndex, state.pageCount])
  
  
  React.useEffect(()=>{
    let sortedColumn = rest.columns.filter((x)=>x.isSorted === true)[0]
    if(sortedColumn){
      if(sort.isSortedDesc !== sortedColumn.isSortedDesc || sort.id !== sortedColumn.id){
        const search = props.history.location.search;
        let params = new URLSearchParams(search)
        params.set("dir",sortedColumn.isSortedDesc?"desc":"asc")
        params.set("sortby",sortedColumn.id)
        props.history.replace(props.history.location.pathname+"?"+params)
        setSort({
          id: sortedColumn.id,
          isSortedDesc: sortedColumn.isSortedDesc
        })
      }
    } else {
      if(sort.isSortedDesc !== null || sort.id !== null){
        const search = props.history.location.search;
        let params = new URLSearchParams(search)
        params.delete("dir")
        params.delete("sortby")
        props.history.replace(props.history.location.pathname+"?"+params)
        setSort({
          id: null,
          isSortedDesc: null
        })
      }
    }
  },[rest.sortedRows])

  // Render the UI for your table
  return (
    <>
        <div className="">
            <div className="d-flex justify-content-between">
                {/* <label className='my-1'>
                    <span className="">Items Per Page</span>
                    <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    value={state.pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    >
                    {[10, 20, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                </label> */}
                <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                />
            </div>
            
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="overflow-x-auto d-flex flex-column">
        <div className="w-100">
          <div className="py-2 overflow-hidden align-middle inline-block w-100">
            <div className="overflow-auto border-b border-gray-200 dark:border-gray-600">
              <table {...getTableProps()} className="w-100 table dataTable divide-y divide-gray-200 dark:divide-gray-600  table-hover">
                <thead className="">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        // {let props = }
                        <th
                            scope="col"
                            className={`group px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                            
                            {...column.sortable != false?column.getHeaderProps(column.getSortByToggleProps()):column.getHeaderProps()}
                        //   onClick={}
                        >
                          <div className='h-100'>
                          <div className="d-flex items-center align-items-stretch justify-content-between">
                            <span className='flex-grow-1 text-nowrap'>{column.render('Header')}</span>
                            {/* Add a sort direction indicator */}
                            {column.sortable != false?
                            <span className='ml-2 align-self-center'>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <i className="fas fa-sort-down text-muted"></i>
                                : <i className="fas fa-sort-up text-muted"></i>
                              : (
                                  
                                  <i className="fas fa-sort text-muted"></i>
                              )}
                          </span>
                            :null}
                            
                          </div>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="divide-y divide-gray-200 dark:divide-gray-600"
                >
                  {page.map((row, i) => {  // new
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} onClick={row.original.clickEvent} role="button">
                        {row.cells.map(cell => {
                          return (
                            <th
                              {...cell.getCellProps()}
                              className="px-3 py-3 whitespace-nowrap"
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer"
                                ? <div className="text-sm text-gray-500 dark:text-gray-300">{cell.render('Cell')}</div>
                                : cell.render('Cell')
                              }
                            </th>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
                <thead className="">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                        
                          scope="col"
                          className="group px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps()}
                        >
                          <div className="d-flex items-center justify-content-between">
                            <span className='flex-grow-1'>{column.render('Header')}</span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="d-flex items-center justify-content-between">
        <div>
            <b>
            Showing {state.pageIndex*state.pageSize + 1} to {page.length + state.pageIndex*state.pageSize} of {filteredRows.length} entries
            </b>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-content-between">
          <div>
            <div className="btn-group" role="group">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                {/* <FontAwesomeIcon icon={faAnglesLeft} className="h-4 w-4 text-gray-700 dark:text-gray-200" aria-hidden="true" /> */}
                <i className="fas fa-angle-double-left"></i>
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                {/* <FontAwesomeIcon icon={faAngleLeft} className="h-4 w-4 text-gray-700 dark:text-gray-200" aria-hidden="true" /> */}
                <i className="fas fa-angle-left"></i>
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Next</span>
                {/* <FontAwesomeIcon icon={faAngleRight} className="h-4 w-4 text-gray-700 dark:text-gray-200" aria-hidden="true" /> */}
                <i className="fas fa-angle-right"></i>
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                {/* <FontAwesomeIcon icon={faAnglesRight} className="h-4 w-4 text-gray-700 dark:text-gray-200" aria-hidden="true" /> */}
                <i className="fas fa-angle-double-right"></i>
              </PageButton>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

