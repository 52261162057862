import printJS from "print-js";
import { toast } from "react-toastify/dist/react-toastify.js";
export default async function print({ url, onLoadingEnd, onError, type }) {
  let printerSettings;
  try {
    printerSettings = JSON.parse(localStorage.getItem("printerSettings"));
  } catch (e) {
    alert(e);
    console.error(e);
    return;
  }
  if (printerSettings && printerSettings[type]) {
    //Print to printer API
    let data = await (await fetch(url)).blob();
    var formdata = new FormData();
    formdata.append("file", data, `${printerSettings[type]}.pdf`);
    formdata.append("printer", printerSettings[type]);
    formdata.append("paperSize", printerSettings[type + "_paper"]);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    try {
      let printer = await fetch("http://localhost:9800/print", requestOptions);
      let data = await printer.json();
      if (printer.status == 200) {
        toast.success("Sent to printer");
      } else if (printer.status == 400) {
        toast.warning(`${data.error}`);
      } else {
        toast.error("Failed to print");
      }
      onLoadingEnd();
      return;
    } catch (error) {
      console.log(error);
      if (!error.response) {
        toast.warning(
          "Can't connect to print companion, make sure it's installed and running."
        );
      } else if (error.response.status == 500) {
        toast.error("Failed to print, due to an error in the print companion");
      }
    }
  }

  //Print to browser
  printJS({
    printable: url,
    onLoadingEnd: onLoadingEnd,
    onError: onError,
  });
}
