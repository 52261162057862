import React, { Component } from "react";

import { Alert, Col, Row, Card, CardBody, CardTitle, CardSubtitle, CardImg, CardText, CardHeader, CardImgOverlay, CardFooter, CardDeck, CardColumns, Container } from "reactstrap";

// import images
import { withRouter, Link, useParams } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { connect } from 'react-redux';
import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class UiCards extends Component {
  componentDidMount(){
    //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
    apiRequest("GET","feedback").then(res => {
      if(res.status == 200){
        return res.data
      }
    }).then((data) => {
      this.setState({ data: data, loaded:1 })
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Feedback", link : "#" },
            ],
            loaded:0,
            error:0,
            errormsg:"",
            data:[]
        };
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      const elements = this.state.data.feedback
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Feedback" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                              <h5 className="mb-4">
                                {(this.state.data.total || 0)+"/10"}
                              </h5>
                          <div className="card-columns">
                          {elements.map((value, index) => {
                            //return <Col mg={6} md={4} key={index}>
                              return  <Card key={index}>
                                    <CardBody>
                                        <CardTitle className="mt-0">{value.rating+"/10"}</CardTitle>
                                        <CardText style={{flexGrow:1}}>{value.message}</CardText>
                                        <span style={{fontSize:"10px"}}>{value.date}</span>
                                    </CardBody>
                                </Card>
                            //</Col>
                          })}
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(UiCards));
