import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table, UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import 'react-slidedown/lib/slidedown.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';


import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class Users extends Component {
  componentDidMount() {
    apiRequest("GET","users").then(res => {
        return res.data
    }).then((data) => {

      this.setState({ data: data, loaded:1, trustedPlatform: parseInt(localStorage.getItem("trustedPlatform"))?true:false })
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Users", link : "#" },
            ],
            isAlertOpen : false,
            modal_static : false,
            data : [],
            hidden:true,
            loaded:0,
            error:0,
            errormsg:"",
            trustedPlatform:false,
            modal_data:{
              id:"new",
              name:"",
              username:"",
              email:"",
              type:"standard"
            },
            modal_error:0,
            modal_delete:false,
            delete_error:0,
            delete_user:"",
            signedOut:0
        }
        this.addUser.bind(this);
    }

    // removeTrustedPlatform = () => {
    //   const authToken = getLoggedInUser();
    //   axios({
    //     method: 'DELETE', //you can set what request you want to be
    //     url: process.env.REACT_APP_BASE_URL+'trustedPlatform',
    //     headers: {
    //       'user': authToken.user,
    //       'id': authToken.id,
    //       'token': authToken.token
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     withCredentials: true
    //   }).then(res => {
    //     localStorage.setItem("trustedPlatform",0)
    //     this.setState({trustedPlatform:false})
    //   }).catch(err=>{
    //     if (!err.response) {
    //       this.setState({error:1, errormsg:"Can't connect to admin API, try again later."})
    //       setTimeout(()=>this.setState({modal_error:0}),1500)
    //       return
    //     }
        
    //     console.error(err.response.data)
    //     if (err.response.status === 500) {
    //       this.setState({error:1, errormsg:"An internal server error occured, try again later."})
    //       setTimeout(()=>this.setState({modal_error:0}),1500)
    //       return;
    //     }
    //     this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
    //     setTimeout(()=>this.setState({modal_error:0}),1500)
    //   })
    // }

    // addTrustedPlatform = () => {
    //   const authToken = getLoggedInUser();
    //   axios({
    //     method: 'POST', //you can set what request you want to be
    //     url: process.env.REACT_APP_BASE_URL+'trustedPlatform',
    //     headers: {
    //       'user': authToken.user,
    //       'id': authToken.id,
    //       'token': authToken.token
    //       // 'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //     withCredentials: true
    //   }).then(res => {
    //     localStorage.setItem("trustedPlatform",1)
    //     this.setState({trustedPlatform:true})
    //   }).catch(err=>{
    //     if (!err.response) {
    //       this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
    //       setTimeout(()=>this.setState({modal_error:0}),1500)
    //       return
    //     }
        
    //     console.error(err.response.data)
    //     if (err.response.status === 500) {
    //       this.setState({error:1, errormsg:"An internal server error occured, try again later."})
    //       setTimeout(()=>this.setState({modal_error:0}),1500)
    //       return;
    //     }
    //     this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
    //     setTimeout(()=>this.setState({modal_error:0}),1500)
    //   })
    // }

    addUser = (event, values) => {
      var data = this.state.modal_data
      if(data.password != data.confirm_password)
        this.setState({ modal_error: "Passwords do not match." })
      if(data.id == "new" && data.password == ""){
        this.setState({ modal_error: "New account needs a password." })
        return
      }
      if(data.name != "" && data.type != "" && data.username != "" && data.email != "" && data.password == data.confirm_password && data.pin == data.confirm_pin ){
        apiRequest("POST","users",{data}).then(res => {
          if(res.data.error == 1){
            this.setState({ modal_error: res.data.msg || "An error occured, Try again later." })
            return
          }
          //window.location = "/users"
          let allData = this.state.data;
          if(this.state.modal_data.id == "new"){
            allData.push(data)
          }else{
            allData[allData.findIndex((el)=>el.id==this.state.modal_data.id)] = data
          }
          this.setState({isAlertOpen:true,modal_error:false})
          setTimeout(() => {
              this.setState({
              modal_static: false
            })
          }, 1000);
        }).catch(err=>{
          if (!err.response) {
            this.setState({ modal_error:"Can't connect to admin API, try again later."})
            setTimeout(()=>this.setState({modal_error:0}),1500)
            return
          }
          
          console.error(err.response.data)
          if (err.response.status === 500) {
            this.setState({modal_error:"An internal server error occured, try again later."})
            setTimeout(()=>this.setState({modal_error:0}),1500)
            return;
          }
          this.setState({ modal_error:"An unknown error occured, try again later."})
          setTimeout(()=>this.setState({modal_error:0}),1500)
        })
      }
      //this.setState({ modal_static: false })
    }

    deleteUser = (id) => {
      apiRequest("DELETE","users",{data:this.state.delete_user}).then(res => {
          if(res.data.error == 1){
            this.setState({ delete_error: res.data.msg || "An error occured, Try again later." })
            return
          }
          let allData = [...this.state.data];
          var index = allData.findIndex((el)=>el.username==this.state.delete_user)
          allData.splice(index, 1);
          this.setState({data:allData,delete_error:0,modal_delete: false})
          //window.location = "/users"
      }).catch(err=>{
        this.setState({delete_error:0,modal_delete: false})
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })

      //this.setState({ modal_static: false })
    }
    signout = (id) => {
      apiRequest("DELETE","signout",{data:id}).then(res => {
        if(res.status == 200){
          if(res.data.error == 1){
            this.setState({ error: res.data.msg || "An error occured, Try again later." })
            return
          } else {
            this.setState({ signedOut: "User Logged out successfully." })
            const authToken = getLoggedInUser();
            if(authToken.username == id){
              window.location.reload(1);
            }
            setTimeout(() => {
                this.setState({
                signedOut: 0
              })
            }, 2000);
          }
        }
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
          setTimeout(()=>this.setState({error:0}),4500)
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
          return;
        }
        this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
        setTimeout(()=>this.setState({error:0}),1500)
      })

      //this.setState({ modal_static: false })
    }


    edit = (key) => {
      //Assign values to the variables
      var data = {...this.state.data[key]};
      data.password="";
      data.confirm_password="";
      data.pin = ""
      data.confirm_pin = ""
      this.setState({ modal_data: data,modal_static: true, isAlertOpen : false, hidden:true })
    }
    newUser = (key) => {
      //Assign values to the variables
      var data = {
        id:"new",
        name:"",
        username:"",
        email:"",
        password:"",
        confirm_password:"",
        pin:"",
        confirm_pin:"",
        type:"standard"
      };
      this.setState({ modal_data: data,modal_static: true, isAlertOpen : false, hidden:true })
    }

    updateData = (event,i) => {
      var data = {...this.state.modal_data}
      data[event.target.name] = i
      if(data.password == data.confirm_password)
        this.setState({ modal_error: 0 })
      this.setState({ modal_data: data})
    }

    renderSwitch(param) {
      switch(param) {
        case 'admin':
          return <div className="badge-soft-danger badge font-size-14">Admin</div>;
        case 'standard':
          return <div className="badge-soft-primary badge font-size-14">Standard</div>;
      }
      return;
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      const authToken = getLoggedInUser();
      const confirm_password = (value, ctx) => {
        if(this.state.modal_data.password != this.state.modal_data.confirm_password)
          return "error";
        return true;
      }

      const confirm_pin = (value, ctx) => {
        if(this.state.modal_data.pin != this.state.modal_data.confirm_pin)
          return "error";
        return true;
      }
        return (
            <React.Fragment>
                <div  className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Users" breadcrumbItems={this.state.breadcrumbItems} />
                    {this.state.error!=0?<Alert color="danger">
                      {this.state.error}
                    </Alert>:""}
                    {this.state.signedOut!=0?<Alert color="success">
                      {this.state.signedOut}
                    </Alert>:""}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <Link to="#" onClick={this.newUser} className="btn btn-success mb-2"><i  className="mdi mdi-plus mr-2"></i> Add User</Link>
                                        </div>
                                        <div  className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{borderCollapse:"collapse", borderSpacing : 0, width:"100%"}}>
                                                <thead  className="thead-light">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Username</th>
                                                        <th>Email</th>
                                                        <th>Account Type</th>
                                                        <th style={{width:"120px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.data.length == 0)?(
                                                      <tr><td colSpan={5} className="text-center">No users</td></tr>
                                                    ) :
                                                      this.state.data.map((user, key) =>
                                                              <tr key={key}>
                                                                  <td>{user.name}</td>
                                                                  <td>{user.username}</td>
                                                                  <td>{user.email}</td>
                                                                  <td>{this.renderSwitch(user.type)}</td>
                                                                  <td>
                                                                  <Link to="#" onClick={()=>{this.signout(user.username)}} className="mr-3 text-danger" id={"logout"+key}><i  className="mdi mdi-power font-size-18"></i></Link>
                                                                  <UncontrolledTooltip target={"logout"+key} placement="top">
                                                                      Logout everywhere
                                                                  </UncontrolledTooltip>
                                                                  <Link to="#" onClick={()=>{this.edit(key)}} className="mr-3 text-primary" id={"edit"+key}><i  className="mdi mdi-pencil font-size-18"></i></Link>
                                                                      <UncontrolledTooltip target={"edit"+key} placement="top">
                                                                          Edit
                                                                      </UncontrolledTooltip>
                                                                      {authToken.username!=user.username?<Link to="#" className="text-danger" onClick={() => this.setState({ modal_delete: 1,delete_user:user.username })} id={"delete"+key}><i className="mdi mdi-trash-can font-size-18"></i></Link>:""}
                                                                      {authToken.username!=user.username?<UncontrolledTooltip target={"delete"+key} placement="top">
                                                                          Delete
                                                                      </UncontrolledTooltip>:""}

                                                                  </td>
                                                              </tr>
                                                          )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Alert color={this.state.trustedPlatform?"warning":"primary"} className="transition-bg">
                          <Row>
                            <Col sm={10}>
                              <b style={{lineHeight:"34px"}}>{this.state.trustedPlatform?"Remove device from":"Add device to"} trusted platform list</b>
                            </Col>
                            <Col sm={2}>
                              <Button onClick={()=>{this.state.trustedPlatform?this.removeTrustedPlatform():this.addTrustedPlatform()}} className="w-100 transition-bg" color={this.state.trustedPlatform?"warning":"primary"} outline>{this.state.trustedPlatform?"Remove":"Add"}</Button>
                            </Col>
                          </Row>
                        </Alert> */}

                        <Modal
                          isOpen={this.state.modal_static}
                          toggle={this.tog_static}
                          backdrop="static"
                          centered
                          size = "lg"
                        >
                            <ModalHeader toggle={() => this.setState({ modal_static: false })}>
                            Add User
                            </ModalHeader>
                            <ModalBody>
                            <AvForm>
                              <Row>
                                <Col lg={12}>
                                <Alert color="success" isOpen={this.state.isAlertOpen}>
                                    {this.state.modal_data.id!="new"?"User updated":"User added"}
                                </Alert>
                                {this.state.modal_data.id=="new"?"":<div className="mb-2"><strong>Please note, changing these settings will sign out this user.</strong></div>}
                                <FormGroup>
                                  <Label htmlFor="name">Name</Label>
                                  <InputGroup>
                                    <AvInput
                                    autoComplete="off"
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        placeholder="Name"
                                        value={this.state.modal_data.name}
                                        onChange={this.updateData}
                                        required
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="username">Username</Label>
                                  <InputGroup>
                                    <AvInput
                                    autoComplete="off"
                                        name="username"
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        placeholder="Username"
                                        value={this.state.modal_data.username}
                                        onChange={this.updateData}
                                        required
                                    />
                                  </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                  <Label htmlFor="email">Email</Label>
                                  <InputGroup>
                                    <AvInput
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="Email"
                                        value={this.state.modal_data.email}
                                        onChange={this.updateData}
                                        required
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="password">Password</Label>
                                  <InputGroup>
                                    <AvInput
                                      autoComplete="new-password"
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        placeholder="Password"
                                        value={this.state.modal_data.password}
                                        onChange={this.updateData}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="confirm_password">Confirm password</Label>
                                  <InputGroup>
                                    <AvInput
                                        autoComplete="new-password"
                                        name="confirm_password"
                                        type="password"
                                        className="form-control"
                                        id="confirm_password"
                                        placeholder="Password"
                                        validate={{myValidation: confirm_password}}
                                        value={this.state.modal_data.confirm_password}
                                        onChange={this.updateData}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="pin">Pin</Label>
                                  <InputGroup>
                                    <AvInput
                                      autoComplete="new-password"
                                        name="pin"
                                        type="password"
                                        className="form-control"
                                        id="pin"
                                        placeholder="pin"
                                        value={this.state.modal_data.pin}
                                        onChange={this.updateData}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="confirm_pin">Confirm pin</Label>
                                  <InputGroup>
                                    <AvInput
                                        autoComplete="new-password"
                                        name="confirm_pin"
                                        type="password"
                                        className="form-control"
                                        id="confirm_pin"
                                        placeholder="pin"
                                        validate={{myValidation: confirm_pin}}
                                        value={this.state.modal_data.confirm_pin}
                                        onChange={this.updateData}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                  <Label htmlFor="type">Account type</Label>
                                    <AvRadioGroup name="type" required value={this.state.modal_data.type}>
                                    <div className="mb-2">
                                      <AvRadio  label={<div className="badge-soft-danger badge font-size-14">Admin</div>} value="admin" />
                                      </div>
                                      <div>
                                      <AvRadio label={<div className="badge-soft-primary badge font-size-14">Standard user</div>} value="standard" />
                                      </div>
                                    </AvRadioGroup>
                                </FormGroup>

                                  </Col>
                              </Row>
                              {this.state.modal_error!=0?<Alert color="danger">
                                {this.state.modal_error}
                              </Alert>:""}

                              <ModalFooter>
                                  <Button type="button" color="light" onClick={() => this.setState({ modal_static: false }) }>Cancel</Button>
                                  <Button type="submit" color="primary"  onClick={this.addUser}>{this.state.modal_data.id =="new"?"Add":"Update"}</Button>
                              </ModalFooter>

                            </AvForm>


                            </ModalBody>
                            </Modal>
                            <Modal
                              isOpen={this.state.modal_delete}
                              backdrop="static"
                              centered
                              size = "md"
                            >
                              <ModalHeader toggle={() => this.setState({ modal_delete: false })}>
                              Delete user
                              </ModalHeader>
                              <ModalBody>
                                Are you sure you want to delete <strong>{this.state.delete_user}</strong>?
                              </ModalBody>
                              {this.state.delete_error!=0?<Alert color="danger">
                                {this.state.delete_error}
                              </Alert>:""}
                              <ModalFooter>
                                  <Button type="button" color="light" onClick={() => this.setState({ modal_delete: false }) }>Cancel</Button>
                                  <Button type="submit" color="danger" onClick={this.deleteUser}>Delete</Button>
                              </ModalFooter>
                            </Modal>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Users));
