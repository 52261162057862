import React, { Component } from "react";

import { Alert,Col,ButtonGroup,Button, Row, Card, CardBody, CardTitle, CardImg, Container,Input,FormGroup } from "reactstrap";

// import images
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import "./datatables.scss";
import { Datatable } from "../../components/Datatable";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import { logoutUser, apiError } from '../../store/actions';
import Cookies from 'universal-cookie';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

const cookies = new Cookies();
class UiCards extends Component {
  componentDidMount(){
    apiRequest("GET","materials").then(res => {
        return res.data
    }).then((data) => {
      this.setState({ data: data, loaded:1, display:cookies.get('view') || "list" })
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

  constructor(props) {
      super(props);
      this.state = {
          breadcrumbItems : [
              { title : "Het Laser Lokaal", link : "/" },
              { title : "Materials", link : "#" },
          ],
          loaded:0,
          error:0,
          data:[],
          search:"",
          display:"list"
      };
  }
  search = (event) => {
    this.setState({search:event.target.value})
  }

  view = (view) => {
    this.setState({display:view})
    cookies.set('view', view, { path: '/' });
  }

  render() {
    if(this.state.loaded === 0){
      if(this.state.error){
        return (
          <React.Fragment>
          <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
            </div>
          </React.Fragment>);
      } else {
        return (
          <React.Fragment>
            <div className="preloader">
              <div className="loader">
                <div className="status">
                  <div role="status" className="spinner-grow text-primary">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>);
      }
    }
    const elements = this.state.data;

    const columns = [
      {
        Header: "",
        sortable: false,
        accessor: 'image',
        Cell: ({value})=>{
          return <img className="img-fluid" style={{width:"200px"}} src={process.env.REACT_APP_IMAGE_BASE_URL+value} alt={""} />
        }
      },{
        Header: "Material",
        accessor: 'materialGroup',
        Cell: ({value})=>{
          return <Link to={"/materials/"+encodeURIComponent(value)} className="text-dark font-weight-bold">{value}</Link>
        }
      },{
        Header: "Thicknesses",
        accessor: 'thickness',
        Cell: ({value}) => {
          let thicknesses = JSON.parse(value)
          thicknesses = thicknesses.join("mm, ");
          return thicknesses+"mm"
        }
      },{
        Header: "Paused",
        accessor: 'paused',
        numAccessor: 'num',
        Cell: ({value, row, column})=>{
          let num = row.original[column.numAccessor]
          if(value == 0) {
            return <div className="badge-soft-success badge font-size-14">Not paused</div>
          } else if (value == 1) {
            return <div className="badge-soft-danger badge font-size-14">Fully paused</div>
          } else {
            return <div className="badge-soft-warning badge font-size-14">Partially paused</div>
          }
        }
      },{
        Header: "Delivery",
        accessor: 'delivery',
        Cell: ({value, row, column}) => {
          if(value==0){
            return "Fast (1-2 Business days)"
          } else {
            return "Standard (4-5 Business days)"
          }
        }
      },{
        Header: "",
        sortable: false,
        accessor: 'shortcode',
        Cell: ({value}) => {
          return <i onClick={()=>navigator.clipboard.writeText(`[material name="${value}"]`)} className="ri ri-code-s-slash-line button text-primary align-middle"></i>
        }
      }
    ]

    let formattedData = this.state.data.map((el)=>{
      return {
        ...el,
        paused: el.paused == el.num?2:(el.paused == 0?0:1),
        clickEvent: (ev) => {
          if(ev.target.tagName == "BUTTON" || ev.target.tagName == "A" || ev.target.className.includes("button")) {
            return
          }
          this.props.history.push("/materials/"+encodeURIComponent(el.materialGroup))
        },
        shortcode: el.materialGroup
      }
    })

      return (
          <React.Fragment>
              <div className="page-content">
                  <Container fluid>

                  <Breadcrumbs title="Materials" breadcrumbItems={this.state.breadcrumbItems} />
                  <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                    {this.state.errormsg}
                  </Alert>
                  <Row>
                  <Col xs="3" className="mb-2">
                    <Link to={"/materials/new"}><Button color="success" ><i className="mdi mdi-plus mr-2"></i>New Material</Button></Link>
                      </Col>
                  <Col xs="6" className="mb-2">
                  {this.state.display === "grid"?
                    <FormGroup className="mb-0">
                      {/*<Label htmlFor="discountVal">Delivery time</Label>*/}
                      <Input className="h-100" name="Search" type="text" placeholder="Search" onChange={this.search}/>
                    </FormGroup>
                  :""}
                  </Col>
                  <Col xs="3" className="text-right mb-2">
                  <ButtonGroup>
                    <Button onClick={()=>{this.view("grid")}} color="primary" active={this.state.display === "grid"?true:false}><i className="mdi mdi-view-grid-outline"></i></Button>
                    <Button onClick={()=>{this.view("list")}} color="primary" active={this.state.display === "list"?true:false}><i className="mdi mdi-format-list-bulleted-square"></i></Button>
                  </ButtonGroup>
                      </Col>

                        {this.state.display === "grid"?
                        elements.filter((el) => {return el.materialGroup.toLowerCase().includes(this.state.search.toLowerCase())}).map((value, index) => {
                          return <Col mg={6} xl={2} md={3} sm={4} xs={6} key={index}>
                            <Link to={"/materials/"+value.materialGroup}>
                              <Card style={{"height":"calc(100% - 24px)"}} >
                                  <CardImg top className="img-fluid" src={process.env.REACT_APP_IMAGE_BASE_URL+value.image} alt={value.materialGroup} />
                                  <CardBody>
                                      <CardTitle className="mt-0">{value.materialGroup}</CardTitle>
                                  </CardBody>
                              </Card>
                            </Link>
                          </Col>
                        }):
                            <Col lg={12}>
                        <Card>
                            <CardBody className="">
                        {/* <MDBDataTable responsive data={tableData} entries={20} className="mt-4" /> */}
                          <Datatable history={this.props.history} columns={columns} data={formattedData}/>
                        </CardBody>
                    </Card>
                  </Col>}
                      </Row>
                  </Container>
              </div>
          </React.Fragment>
      );
  }
}

export default UiCards;
