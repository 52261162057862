import React, { Component } from "react";
import Fuse from "fuse.js";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Media,
  Alert,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//Import i18n
import { withNamespaces } from "react-i18next";

//Import Megamenu
// import MegaMenu from "./MegaMenu";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

//Import logo Images
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.png";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-light.png";

//Import Social Profile Images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";
import { apiRequest, getLoggedInUser } from "../../helpers/backend_helper";
function Avatar(props) {
  var fullName = props.name.split(" ");
  var initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  var color = "";
  switch (props.state) {
    case "new":
      color = "alert-danger avatar-title rounded-circle font-size-16";
      break;
    case "on-hold":
      color = "alert-primary avatar-title rounded-circle font-size-16";
      break;
    case "processing":
      color = "alert-warning avatar-title rounded-circle font-size-16";
      break;
    case "shipped":
    case "pickup":
      color = "alert-success avatar-title rounded-circle font-size-16";
      break;
    case "ready":
      color = "alert-info avatar-title rounded-circle font-size-16";
      break;
    case "archived":
      color = "alert-dark avatar-title rounded-circle font-size-16";
      break;
    default:
      color = "alert-primary avatar-title rounded-circle font-size-16";
      break;
  }
  return (
    <span className={color}>
      <strong>{initials.toUpperCase()}</strong>
    </span>
  );
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isSocialPf: false,
      menu: false,
      data: [],
      searchTerm: "",
      searchErr: 0,
      searchLoaded: 0,
      loading: 1,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  loadSearch = () => {
    this.setState({ loading: 1 });
    const authToken = getLoggedInUser();
    apiRequest("GET", "search")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error === 1) {
            this.setState({
              searchErr: res.data.msg || "Error loading search.",
              loading: 0,
            });
            return;
          } else {
            this.setState({
              data: new Fuse(res.data, {
                keys: [
                  "customer.send_estimates_to_email",
                  "customer.address1",
                  "customer.company_name",
                  "customer.firstname",
                  "customer.lastname",
                  "customer.city",
                  "customer.zipcode",
                  "order",
                ],
                ignoreLocation: true,
                includeScore: true,
                threshold: 0.2,
              }),
              searchLoaded: Math.floor(new Date().getTime() / 1000) + 30,
              loading: 0,
            });
          }
        } else {
          throw res;
        }
      })
      .catch((err) => {
        this.setState({ searchErr: "Error loading search." });
      });
  };

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  search = (val) => {
    // var input = $("#search")
    // var value = input.val().toLowerCase().replace(/\s+/g, '');
    // $(".grid .item").filter(function() {
    //   var containsText = $(this).find(".itemtitle").text().replace(/\s+/g, '').toLowerCase().indexOf(value) > -1 || $(this).attr("data-tag").toLowerCase().replace(/\s+/g, '').indexOf(value) > -1
    //   $(this).toggle(containsText)
    // });
    if (this.state.searchLoaded <= Math.floor(new Date().getTime() / 1000))
      this.loadSearch();

    var value = val.target.value.trim().replace(/\s+/g, "");
    if (value.length > 2) {
      this.setState({
        menu: true,
        searchTerm: value,
      });
    } else {
      this.setState({
        menu: false,
        searchTerm: "",
      });
    }
  };
  onFocus = () => {
    if (this.state.searchTerm.length > 2) this.setState({ menu: true });
  };
  filter = (el) => {
    var search = this.state.searchTerm.toLowerCase();
    return (
      el.name.replace(/\s+/g, "").toLowerCase().indexOf(search) > -1 ||
      el.order.toLowerCase().replace(/\s+/g, "").indexOf(search) > -1 ||
      el.date.replace(/\s+/g, "").toLowerCase().indexOf(search) > -1
    );
  };
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    let results = [];
    if (this.state.searchLoaded) {
      results = this.state.data.search(this.state.searchTerm);
    }

    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="#" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logosmdark} alt="" height="30" />
                    {/*src={logosmdark}*/}
                  </span>
                  <span className="logo-lg">
                    <img src={logodark} alt="" height="50" />
                    {/*src={logodark}*/}
                  </span>
                </Link>

                <Link to="#" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logosmlight} alt="" height="30" />
                    {/*src={logosmlight}*/}
                  </span>
                  <span className="logo-lg">
                    <img src={logolight} alt="" height="50" />
                    {/*src={logolight}*/}
                  </span>
                </Link>
              </div>

              <Button
                size="sm"
                color="none"
                type="button"
                onClick={this.toggleMenu}
                className="px-3 font-size-24 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="ri-menu-2-line align-middle"></i>
              </Button>

              <Dropdown
                isOpen={this.state.menu}
                toggle={() => {}}
                className="d-inline-block"
              >
                <DropdownToggle
                  tag="div"
                  className="py-0 btn noti-icon waves-effect"
                  id="page-header-notifications-dropdown"
                >
                  <Form className="app-search d-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        onFocus={this.onFocus}
                        onBlur={() => {
                          setTimeout(() => {
                            this.setState({
                              menu: false,
                            });
                          }, 200);
                        }}
                        onChange={this.search}
                        placeholder="Search for orders"
                      />
                      <span className="ri-search-line"></span>
                    </div>
                  </Form>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu-md p-0"
                  onClick={() => {
                    this.setState({ menu: true });
                  }}
                  aria-labelledby="page-header-notifications-dropdown"
                >
                  <div className="p-3">
                    <Row className="align-items-center">
                      <Col>
                        <h6 className="m-0">
                          {" "}
                          {this.props.t("Search")}{" "}
                          <span>({results.length})</span>
                        </h6>
                      </Col>
                      {/*}<div className="col-auto">
                                            <Link to="#" className="small"> {this.props.t('View All')}</Link>
                                        </div>*/}
                    </Row>
                  </div>
                  <SimpleBar style={{ maxHeight: "230px" }}>
                    {this.state.loading === 0
                      ? results.map((el, key) => (
                          <Link
                            key={key}
                            to={"/orders/" + el.item.order}
                            className="text-reset notification-item"
                          >
                            <Media>
                              <div className="avatar-sm mr-1">
                                <Avatar
                                  name={
                                    el.item.customer.firstname +
                                    " " +
                                    el.item.customer.lastname
                                  }
                                  state={el.item.state}
                                />
                              </div>
                              <Media body>
                                <h6 className="mt-0 mb-1">
                                  {this.props.t("Order") + " #" + el.item.order}
                                </h6>
                                <div className="font-size-12 text-muted">
                                  <p className="mb-1">
                                    {el.item.customer.firstname}{" "}
                                    {el.item.customer.lastname}
                                  </p>
                                  <p className="mb-0">
                                    <i className="mdi mdi-calendar"></i>{" "}
                                    {el.item.date}
                                  </p>
                                </div>
                              </Media>
                            </Media>
                          </Link>
                        ))
                      : ""}
                    {results.length === 0 && this.state.loading === 0 ? (
                      <div className="px-3 pb-3">
                        <Row className="align-items-center text-center">
                          <Col>
                            <span className="m-0 font-size-12 text-muted">
                              No results
                            </span>
                          </Col>
                          {/*}<div className="col-auto">
                                              <Link to="#" className="small"> {this.props.t('View All')}</Link>
                                          </div>*/}
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.loading === 1 ? (
                      <div className="px-3 pb-3">
                        <Row className="align-items-center text-center">
                          <Col>
                            <i className="mdi mdi-spin mdi-loading"></i>
                            <span className="m-0 ml-1 font-size-12 text-muted">
                              Loading
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="m-2">
                      {this.state.searchErr !== 0 ? (
                        <Alert color="danger">{this.state.searchErr}</Alert>
                      ) : (
                        ""
                      )}
                    </div>
                  </SimpleBar>
                </DropdownMenu>
              </Dropdown>
              {/*<MegaMenu/>*/}
            </div>

            <div className="d-flex">
              {/* <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button type="button" onClick={() => { this.setState({ isSearch: !this.state.isSearch }); }}  className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                <i className="ri-search-line"></i>
                            </button>
                            <div className={ this.state.isSearch === true ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                aria-labelledby="page-header-search-dropdown">

                                <Form className="p-3">
                                    <FormGroup className="m-0">
                                        <InputGroup>
                                            <Input type="text" className="form-control" placeholder={this.props.t('Search')}/>
                                            <InputGroupAddon addonType="append">
                                                <Button color="primary" type="submit"><i className="ri-search-line"></i></Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Form>

                            </div>
                        </div> */}

              {/*<LanguageDropdown/>*/}

              {/*<Dropdown isOpen={this.state.isSocialPf} toggle={() => this.setState({isSocialPf : !this.state.isSocialPf})} className="d-none d-lg-inline-block ml-1">
                            <DropdownToggle tag="button" className="btn header-item noti-icon waves-effect">
                                <i className="ri-apps-2-line"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg" right>
                                <div className="px-lg-2">
                                    <Row className="no-gutters">
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={github} alt="Github"/>
                                                <span>{this.props.t('GitHub')}</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={bitbucket} alt="bitbucket"/>
                                                <span>{this.props.t('Bitbucket')}</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={dribbble} alt="dribbble"/>
                                                <span>{this.props.t('Dribbble')}</span>
                                            </Link>
                                        </Col>
                                    </Row>

                                    <Row className="no-gutters">
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={dropbox} alt="dropbox"/>
                                                <span>{this.props.t('Dropbox')}</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={mail_chimp} alt="mail_chimp"/>
                                                <span>{this.props.t('Mail Chimp')}</span>
                                            </Link>
                                        </Col>
                                        <Col>
                                            <Link className="dropdown-icon-item" to="#">
                                                <img src={slack} alt="slack"/>
                                                <span>{this.props.t('Slack')}</span>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </DropdownMenu>
                        </Dropdown>

                        <div className="dropdown d-none d-lg-inline-block ml-1">
                            <Button color="none" type="button" className="header-item noti-icon waves-effect" onClick={this.toggleFullscreen}>
                                <i className="ri-fullscreen-line"></i>
                            </Button>
                        </div>*/}

              {getLoggedInUser().role == "admin" && (
                <NotificationDropdown history={this.props.history} />
              )}

              <ProfileMenu />

              {/*<div className="dropdown d-inline-block">
                            <Button color="none" onClick={this.toggleRightbar} type="button" className="header-item noti-icon right-bar-toggle waves-effect">
                                <i className="ri-settings-2-line"></i>
                            </Button>
                        </div>*/}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withNamespaces()(Header)
);
