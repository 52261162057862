import React, { Component } from "react";
import { Alert, Container, Row, Col,Card,CardBody,Button, ButtonGroup ,FormGroup,Label, Input} from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components

import "./style.scss"
import { apiRequest, getLoggedInUser } from "../../helpers/backend_helper";
import { logoutUser, apiError } from '../../store/actions';
import ReactApexChart from 'react-apexcharts';
function arrayEquals(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length == b.length &&
    a.every((val, index) => val[0] == b[index][0] && val[1] == b[index][1]);
}
function minTommss(minutes){
 var sign = minutes < 0 ? "-" : "";
 var hour = Math.floor(Math.abs(minutes/60))
 var min = Math.floor(Math.abs(minutes%60));
 var sec = Math.floor((Math.abs(minutes) * 60) % 60);
 return sign + (hour !=0 ? (hour < 10 ? "0" : "") + hour + ":" : "") + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
}
function arrayEquals1(a, b) {
  return Array.isArray(a) &&
    Array.isArray(b) &&
    a.length == b.length &&
    a.every((val, index) => val == b[index]);
}

class Apexlinecolumn extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key:1,
            series: this.props.data,
            options : {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    type: this.props.type,
                    zoom: {
                      enabled: false
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: this.props.type=="line"?true:false,
                    width: 2,
                    curve: 'straight'
                },
                labels:this.props.labels,
                colors: ["#01f39e","#c14b5b","#7d55ed","#a0ef4a","#0178f8","#ffe339","#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc","#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8","#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b"],
                xaxis: {
                  //categories:this.props.labels,
                  labels: {
                    formatter: function(val) {
                      return (val||"").split("/",1);
                    }
                  }
                  //max:,
                  //min:
                },
                yaxis: {
                  min:0,
                  max:function(max) { return max+2 },
                  tickAmount:5,
                  labels: {
                    formatter: (val) => {
                      if(this.props.type == "line"){
                        if (this.props.timey != undefined && this.props.timey == true) {
                          return minTommss(val||0)//(val||0).toFixed(2);
                        } else {
                          return (val||0).toFixed(2);
                        }

                      } else {
                        return (val||0).toFixed(0);
                      }
                    }
                  },
                },
                grid: {
                    borderColor: '#f1f1f1',
                },
                fill: {
                    opacity: 1

                },
                tooltip: {
                  x: {
                    formatter: (val,series) => {
                      if(this.props.type=="line"){
                        return (this.props.labels[val-1] || "").split("/").join("-");
                      } else {
                        return (val||"").split("/").join("-");
                      }
                    }
                  }
                }
            }
        };
    }
    componentDidUpdate(prevProps){
      if(!arrayEquals1(prevProps.data,this.props.data)){
        var options = {...this.state.options}
        options.labels = this.props.labels
        //options.xaxis.categories = this.props.labels
        this.setState({
          series:this.props.data,
          options:options,
          key:1
        });
      }
    }

    render() {

        return (
            <React.Fragment>
              <Card>
                  <CardBody>
                      {/*<div className="float-right d-none d-md-inline-block">
                          <ButtonGroup className="mb-2">
                              <Button size="sm" color="light" type="button">Today</Button>
                              <Button size="sm" color="light" active type="button">Weekly</Button>
                              <Button size="sm" color="light" type="button">Monthly</Button>
                          </ButtonGroup>
                      </div>*/}
                      <h4 className="card-title mb-4">{this.props.title}</h4>
                      <div>
                          <div id="line-column-chart" className="apex-charts" dir="ltr">
                          <ReactApexChart options={this.state.options} series={this.state.series} type={ this.props.type} height="200" />
                          </div>
                      </div>
                  </CardBody>
              </Card>
            </React.Fragment>
        );
    }
}
const test2 = [
  {
    category:"Acrylaat",
    name:"Acrylaat Blauw",
    thickness:[{
      name:"2mm",
      size:[{
        name:"30x30",
        num:5
      },{
        name:"60x30",
        num:8
      },{
        name:"60x60",
        num:4
      }]
    },{
      name:"3mm",
      size:[{
        name:"30x30",
        num:5
      },{
        name:"60x30",
        num:8
      },{
        name:"60x60",
        num:4
      }]
    },{
      size:[{
        name:"30x30",
        num:5
      },{
        name:"60x30",
        num:8
      },{
        name:"60x60",
        num:4
      }]
    }],
  }
];

// const test = {{}
//   {
//     name: "Acrylaat Blauw",
//     thickness: 2,
//     size: "30x30"
//   }]
// }
const test = [
  {
    category:"Acrylaat",
    material: "Acrylaat Blauw",
    thickness: 2,
    size: "30x30"
  },
  {
    category:"Rubber",
    material: "Acrylaat Oranje",
    thickness: 2,
    size: "30x30"
  },
  {
    category:"Potato",
    material: "Acrylaatblasd",
    thickness: 3,
    size: "30x30"
  },
  {
    category:"Acrylaat",
    material: "Acrylaat Blauw",
    thickness: 4,
    size: "30x30"
  },
  {
    category:"Acrylaat",
    material: "Acrylaat Blauw",
    thickness: 2,
    size: "60x30"
  }
]
class MaterialBar extends Component {

    constructor(props) {
      super(props);

      this.state = {
        key:1,
        selectedMaterial:-1,
        selectedCategory:-1,
        selectedThickness:-1,
        selectedSize:-1,
        category: this.mapData(this.props.data,"category"),
        material: this.mapData(this.props.data,"material"),
        thickness: this.mapData(this.props.data,"thickness"),
        size: this.mapData(this.props.data,"size"),
        options : {
          chart: {
            toolbar: {
                show: false,
            },
            type: 'bar',
            height: '400px',
            stacked: false,
            stackType: '100%',
            events: {
              dataPointSelection: this.chartEvent
            }
          },
          dataLabels: {
            enabled: false,
            offsetY: -20,
            formatter: function(val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
            },
            style: {
              colors: ['#ffffff']
            },
          },
          stroke: {
            width: 1,
            colors: ['transparent']
          },
          colors: ["#c14b5b","#01f39e","#7d55ed","#a0ef4a","#0178f8","#ffe339","#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc","#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8","#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b"],//["#f48494","#90e437","#956afa","#ffed32","#018dfc","#f2ca1b","#ab81f6","#4ea105","#e61bac","#19feb5","#fd2c64","#09e090","#db60af","#b6f371","#ae8ce6","#83be46","#fe96e3","#01b062","#e8536b","#38fcfe","#fb7418","#059bf8","#c7a311","#1aa9fd","#da611b","#07b2fe","#fe9947","#0bbefd","#fee66a","#3290dc","#f0c25f","#019fdd","#a48820","#65b4fc","#ffb372","#08cefd","#fe6a84","#08eef9","#ff739b","#76f6c3","#e687ba","#139e69","#e1affe","#75a048","#0be2ff","#d88151","#06d2da","#f5d584","#42c692","#c0e98e"],
          xaxis: {
            labels:{
              show:false
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
          },
          grid: {
            yaxis: {
              lines: {
                show: false
              }
            },
            borderColor: '#fffff',
          },
              //borderColor: '#f1f1f1',
          fill: {
              opacity: 1

          },
          plotOptions: {
            // bar: {
            //   rangeBarGroupRows:false,
            //   horizontal: true,
            // },
            bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
          },
          yaxis:{
            labels: {
              show: true,
              align: 'right',
              minWidth: 60,
            }
          },
          legend:{
            show:true,
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false
            },
            position:"top"
          },
          tooltip: {
          }
        },
        CatCols:["#c14b5b","#01f39e","#7d55ed","#a0ef4a","#0178f8","#ffe339","#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc","#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8","#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b"],
        matCols:["#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc","#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8","#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b","#c14b5b","#01f39e","#7d55ed","#a0ef4a","#0178f8","#ffe339"],
        ThicCols:["#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8","#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b","#c14b5b","#01f39e","#7d55ed","#a0ef4a","#0178f8","#ffe339","#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc"],
        sizeCols:["#528024","#866ac0","#bded86","#b65c94","#72ebba","#f98739","#00a9f8","#ab8c0c","#107dc5","#ffaf55","#0fb7f7","#866d0c","#62aaf4","#f9d67b","#11cbfd","#ba6637","#0cdfff","#f9a46d","#02d3da","#fa8594","#058354","#ff8daf","#87b258","#bfa24b","#c14b5b","#01f39e","#7d55ed","#a0ef4a","#0178f8","#ffe339","#9e76f0","#6ab91f","#0081e8","#f36708","#0097f9","#dab41e","#179df7","#c24805","#26f4fc","#ed1e55","#34f9b8","#c14496","#02c475","#f379c9","#358803","#d0a5fc","#018f45","#fb607f","#20ae78","#fca5e8"],
      }
    }
    componentDidUpdate(prevProps){
      if(!arrayEquals1(prevProps.data,this.props.data)){
        var options = {...this.state.options}
        options.labels = this.props.labels
        //options.xaxis.categories = this.props.labels
        this.setState({
          selectedMaterial:-1,
          selectedCategory:-1,
          selectedThickness:-1,
          selectedSize:-1,
          category: this.mapData(this.props.data,"category"),
          material: this.mapData(this.props.data,"material"),
          thickness: this.mapData(this.props.data,"thickness"),
          size: this.mapData(this.props.data,"size"),
        });
      }
    }

    deselect = (type) => {
      if(this.state.selectedMaterial != -1 && (type=="material" || type == "all")){
        window.ApexCharts.exec('Material', 'toggleDataPointSelection', this.state.selectedMaterial,0)

        this.setState({selectedMaterial:-1})
      }
      if(this.state.selectedSize != -1 && (type=="size" || type == "all")){
        window.ApexCharts.exec('Size', 'toggleDataPointSelection', this.state.selectedSize,0)
        this.setState({selectedSize:-1})
      }
      if(this.state.selectedThickness != -1 && (type=="thickness" || type == "all")){
        window.ApexCharts.exec('Thickness', 'toggleDataPointSelection', this.state.selectedThickness,0)
        this.setState({selectedThickness:-1})
      }
    }

    mapData = (data, attr) => {
      var mappedData = []
      for (var i in data) {
        var index = mappedData.findIndex((val)=>val.name == data[i][attr])
        if(index == -1){
          mappedData.push({
            name:data[i][attr],
            data: [{
              x:attr,
              y: 1
            }],
          })
        } else {
          mappedData[index].data[0].y += 1
        }
      }
      mappedData = mappedData.sort(function(a, b) {
        var nameA = !parseFloat(a.name)?a.name.toUpperCase():(typeof(a.name) == "number"?a.name:parseFloat(a.name.replace("x",""))); // ignore upper and lowercase
        var nameB = !parseFloat(b.name)?b.name.toUpperCase():(typeof(b.name) == "number"?b.name:parseFloat(b.name.replace("x",""))); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      return mappedData
    }

    chartEvent = (event, chartContext, config) => {
      if(chartContext.data.twoDSeriesX[0]=="category"){
        var materials = this.props.data.filter((el)=>{
          return el.category == this.state.category[config.seriesIndex].name ||config.selectedDataPoints[config.seriesIndex].length == 0
        })

        this.setState({
          material: this.mapData(materials,"material"),
          size: this.mapData(materials,"size"),
          thickness: this.mapData(materials,"thickness"),
          selectedCategory:(config.selectedDataPoints[config.seriesIndex].length?config.seriesIndex:-1)
        })
        this.deselect("all");

      } else if(chartContext.data.twoDSeriesX[0]=="material"){
        var materials = this.props.data.filter((el)=>{
          var category = ((this.state.category[this.state.selectedCategory] != undefined && el.category == this.state.category[this.state.selectedCategory].name)||this.state.selectedCategory == -1)
          return (el.material == this.state.material[config.seriesIndex].name || config.selectedDataPoints[config.seriesIndex].length == 0) && category
        })
        //materials = this.mapMaterial(materials)

        this.setState({
          size:this.mapData(materials,"size"),
          thickness:this.mapData(materials,"thickness"),
          selectedMaterial:(config.selectedDataPoints[config.seriesIndex].length?config.seriesIndex:-1)
        })
        this.deselect("size");
        this.deselect("thickness");

      } else if(chartContext.data.twoDSeriesX[0]=="thickness"){
        var materials = this.props.data.filter((el)=>{
          var category = ((this.state.category[this.state.selectedCategory] != undefined && el.category == this.state.category[this.state.selectedCategory].name)||this.state.selectedCategory == -1)
          var material = ((this.state.material[this.state.selectedMaterial] != undefined && el.material == this.state.material[this.state.selectedMaterial].name)||this.state.selectedMaterial == -1)
          return (el.thickness == this.state.thickness[config.seriesIndex].name ||config.selectedDataPoints[config.seriesIndex].length == 0) && material && category
        })
        this.setState({
          size: this.mapData(materials,"size"),
          selectedThickness:(config.selectedDataPoints[config.seriesIndex].length?config.seriesIndex:-1)
        })
        this.deselect("size");

      } else if(chartContext.data.twoDSeriesX[0]=="size"){
        this.setState({selectedSize:(config.selectedDataPoints[config.seriesIndex].length?config.seriesIndex:-1)})
      }
    }
    render() {

        return (
            <React.Fragment>
            <Card>
                <CardBody>
              <Row>
              <Col xl={12}><h4 className="card-title mb-4">{this.props.title}</h4></Col>
              <Col>

                        {/*<div className="float-right d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" type="button">Today</Button>
                                <Button size="sm" color="light" active type="button">Weekly</Button>
                                <Button size="sm" color="light" type="button">Monthly</Button>
                            </ButtonGroup>
                        </div>*/}

                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <div id="synced-charts">
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Category"},colors:["#FEB019","#FF4560","#775DD0","#008FFB","#00E396"]}} series={this.state.category} type="bar" />
                            {/*<ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Material"},colors:["#008FFB","#00E396","#FEB019","#FF4560","#775DD0"]}} series={this.state.material} type="bar" height="200" />
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Thickness"},colors:["#FEB019","#FF4560","#00E396","#775DD0","#008FFB"]}} series={this.state.thickness} type="bar" height="200" />
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Size"},colors:["#008FFB","#775DD0","#00E396","#FEB019","#FF4560"]}} series={this.state.size} type="bar" height="200" />*/}
                            </div>
                            </div>
                        </div>
              </Col>
              <Col>
                        {/*<div className="float-right d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" type="button">Today</Button>
                                <Button size="sm" color="light" active type="button">Weekly</Button>
                                <Button size="sm" color="light" type="button">Monthly</Button>
                            </ButtonGroup>
                        </div>*/}
                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <div id="synced-charts">
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Material"},legend:{show:false},colors:["#008FFB","#00E396","#FEB019","#FF4560","#775DD0"]}} series={this.state.material} type="bar"/>
                            </div>
                            </div>
                        </div>
              </Col>
              <Col>

                        {/*<div className="float-right d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" type="button">Today</Button>
                                <Button size="sm" color="light" active type="button">Weekly</Button>
                                <Button size="sm" color="light" type="button">Monthly</Button>
                            </ButtonGroup>
                        </div>*/}

                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <div id="synced-charts">
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Thickness"},colors:["#FEB019","#FF4560","#00E396","#775DD0","#008FFB"]}} series={this.state.thickness} type="bar"/>
                            </div>
                            </div>
                        </div>

              </Col>
              <Col>

                        {/*<div className="float-right d-none d-md-inline-block">
                            <ButtonGroup className="mb-2">
                                <Button size="sm" color="light" type="button">Today</Button>
                                <Button size="sm" color="light" active type="button">Weekly</Button>
                                <Button size="sm" color="light" type="button">Monthly</Button>
                            </ButtonGroup>
                        </div>*/}

                        <div>
                            <div id="line-column-chart" className="apex-charts" dir="ltr">
                            <div id="synced-charts">
                            <ReactApexChart options={{...this.state.options,chart:{...this.state.options.chart,id:"Size"},colors:["#008FFB","#775DD0","#00E396","#FEB019","#FF4560"]}} series={this.state.size} type="bar"/>
                            </div>
                            </div>
                        </div>

              </Col>
              </Row>
              </CardBody>
          </Card>
            </React.Fragment>
        );
    }
}



class Analytics extends Component {
  componentDidMount() {
    const startDate = new Date()
    var endDate = new Date();
        endDate.setDate(endDate.getDate()-28);
    this.setState({
      dateFrom:this.formatDate2(endDate),
      dateTill:this.formatDate2(startDate),
      groupBy:"week"
    })
    this.getAnalytics(this.formatDate2(endDate),this.formatDate2(startDate),"week")
  }
  state = {
      loaded:0,
      error:0,
      errormsg:"",
      data:null,
      timer:0,
      breadcrumbItems : [
          { title : "Het Laser Lokaal", link : "/" },
          { title : "Analytics", link : "/analytics" },
      ],
      dateFrom:"",
      dateTill:"",
      groupBy:"week"
    }

    getAnalytics = (dateFrom,dateTill,groupBy) => {
      apiRequest("POST","analytics",{
        from:dateFrom,
        till:dateTill,
        groupBy:groupBy
      }).then(res => {
        if(res.status == 200){
          return res.data
        }
      }).then((data) => {
        this.setState({ data: data,loaded:1})
      }).catch(err=>{
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    }
    formatDate = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [day,month,year].join('/');
  }

    formatDate2 = (date) => {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
          month = '0' + month;
      if (day.length < 2)
          day = '0' + day;

      return [year,month,day].join('-');
    }

    dateFromChange = (event) =>{
      var value = event.target.value;
      if(new Date(value).getTime() > new Date(this.state.dateTill).getTime()){
        this.setState({dateFrom:value,dateTill:""})

      } else {
        this.setState({dateFrom:value})
        if(this.state.dateTill!="" && value!=""){
          clearTimeout(this.state.timer)
          var timer = setTimeout(()=>this.getAnalytics(value,this.state.dateTill,this.state.groupBy),2000);
          this.setState({timer:timer})
        }
      }
    }

    dateTillChange = (event) =>{
      var value = event.target.value;
      this.setState({dateTill:value})
      if(this.state.dateFrom!="" && value!=""){
        clearTimeout(this.state.timer)
        var timer = setTimeout(()=>this.getAnalytics(value,this.state.dateTill,this.state.groupBy),2000);
        this.setState({timer:timer})
      }
    }

    groupChange = (val) =>{
      this.setState({groupBy:val})
      this.getAnalytics(this.state.dateFrom,this.state.dateTill,val)
    }
    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }

      const maxDate = this.formatDate2(new Date())
      const numOfSales = [{
          name:"",
          data: this.state.data.orderAnalytics.map((el)=> el[1]) || []
      }]
      const operationPopularity = [{
          name:"Cut",
          data: this.state.data.orderAnalytics.map((el)=> el[5][0] || 0) || []
      },{
          name:"Engrave",
          data: this.state.data.orderAnalytics.map((el)=> el[5][1] || 0) || []
      },{
          name:"Raster",
          data: this.state.data.orderAnalytics.map((el)=> el[5][2] || 0) || []
      }]
      const labels = this.state.data.orderAnalytics.map((el)=> el[0] || "")
      const numOfDesignsPerOrder = [{
          name:"",
          data: this.state.data.orderAnalytics.map((el)=> el[2]) || []
      }]
      const hours = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
      const popularityTime = [{
          name:"",
          data: this.state.data.popularityTime || []
      }]//.map((el,i)=>[hours[i,el])
      const time = [{
          name:"Estimated",
          data: this.state.data.orderAnalytics.map((el)=> el[4]) || []
      },{
          name:"Estimated average",
          data: this.state.data.orderAnalytics.map((el)=> el[3]) || []
      }]

      const values = [{
          name:"Revenue",
          data: this.state.data.orderAnalytics.map((el)=> el[7]) || []
      },{
          name:"Average order value",
          data: this.state.data.orderAnalytics.map((el)=> el[6]) || []
      },{
          name:"profit",
          data: this.state.data.orderAnalytics.map((el)=> el[8]) || []
      },{
          name:"costs",
          data: this.state.data.orderAnalytics.map((el)=> el[9]) || []
      }]
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Analytics" breadcrumbItems={this.state.breadcrumbItems} />
                    <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                      {this.state.errormsg}
                    </Alert>
                        <Row>
                        <Col xl="6" className="mb-2">
                        </Col>
                        <Col xl="6" className="mb-2">
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label for="exampleEmail">From</Label>
                                <Input type="date" name="dateFrom" id="exampleEmail" placeholder="with a placeholder" max={maxDate} value={this.state.dateFrom} onChange={this.dateFromChange}/>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label for="exampleEmail">Till</Label>
                                <Input type="date" name="dateTill" id="exampleEmail" placeholder="with a placeholder" min={this.state.dateFrom} max={maxDate} value={this.state.dateTill} onChange={this.dateTillChange}/>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <Label for="exampleEmail">Group by</Label>
                              <ButtonGroup className="mb-2 w-100">
                                <Button color="primary" onClick={()=>this.groupChange("day")} active={this.state.groupBy=="day"?true:false}>Day</Button>
                                <Button color="primary" onClick={()=>this.groupChange("week")} active={this.state.groupBy=="week"?true:false}>Week</Button>
                                <Button color="primary" onClick={()=>this.groupChange("month")} active={this.state.groupBy=="month"?true:false}>Month</Button>
                              </ButtonGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl={4} >
                          <Apexlinecolumn data={numOfSales} title="Number of orders" labels={labels} type="bar"/>
                          <Apexlinecolumn data={numOfDesignsPerOrder} title="Average number of designs per order" labels={labels} type="line"/>

                            {/* revenue Analytics
                            // <RevenueAnalytics  data={this.state.data.analytics}/>*/}
                        </Col>
                        <Col xl={4}>
                          <Apexlinecolumn data={popularityTime} labels={hours} title="Popularity throughout day" type="bar"/>
                          <Apexlinecolumn data={time} title="Production time" labels={labels} timey={true} type="line"/>

                            {/* revenue Analytics
                            // <RevenueAnalytics  data={this.state.data.analytics}/>*/}
                        </Col>
                        <Col xl={4}>
                          <Apexlinecolumn data={operationPopularity}  labels={labels} title="Operation popularity" type="bar" />
                          <Apexlinecolumn data={values} title="Order revenue" labels={labels} type="line"/>

                            {/* revenue Analytics
                            // <RevenueAnalytics  data={this.state.data.analytics}/>*/}
                        </Col>
                        </Row>


                        <Row>
                        <Col xl={12}>
                            <MaterialBar data={this.state.data.materialPopularity} title="Material Popularity" labels={labels} type="line"/>
                        </Col>
                            {/* sources <Sources/>*/}


                            {/* recent activity
                            <RecentlyActivity/>*/}

                            {/* revenue by locations
                            <RevenueByLocations/> */}

                        </Row>


                        <Row>

                            {/* latest transactions
                            <LatestTransactions/>*/}
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Analytics));
