import React, { Component } from "react";

import { Button,Input,InputGroup,InputGroupAddon,InputGroupText,Alert,Col, Row, Card, CardBody,Container, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
// import images
// import { Link } from "react-router-dom";
import Error404 from '../../components/Common/Error404';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import { connect } from 'react-redux';
// import { logoutUser, apiError } from '../../store/actions';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';

class UiCards extends Component {
  componentDidMount(){
    //document.getElementsByClassName("pagination")[0].classList.add("pagination-rounded");
    // if(this.props.match.params.id != "new"){
      apiRequest("GET",`material/edit/${encodeURIComponent(decodeURIComponent(this.props.match.params.id))}`).then(res => {
        return res.data
      }).then((data) => {
        // categories
        if(this.props.match.params.id !== "new"){
          let body=[],headers=[],vertical=[]

          if(data.data.length){
            let sizes = data.data[0].sizes
            for(var i in sizes){
              headers.push({
                Name:sizes[i].Name||"",
                X:sizes[i].X,
                Y:sizes[i].Y
              })
            }
            for(i in data.data){
              vertical.push({
                paused:data.data[i].paused,
                thickness:data.data[i].thickness,
                cutspeed:data.data[i].cutspeed,
                engraveSpeed:data.data[i].engraveSpeed,
                passes:data.data[i].passes
              })
              sizes = data.data[i].sizes
              body.push(sizes.map((el)=>el.price))
            }
          } else {
            window.location = "/materials"
          }
          this.setState({
            category:data.data[0].category,
            vertical:vertical,
            headers:headers,
            data:body,
            image:data.data[0].image,
            imageCache:data.data[0].image,
            materialGroup:data.data[0].materialGroup,
            delivery:data.data[0].delivery,
            ...data.data[0].extra,
            loaded:1,
            breadcrumbItems:[
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Materials", link : "/materials" },
                { title : data.data[0].materialGroup, link : "#" },
            ],
            categories:data.categories,
            materialGroups:data.materialGroups,
          })
        } else {
          this.setState({
            categories:data.categories,
            materialGroups:data.materialGroups,
            loaded:1,
          })
        }
      }).catch(err=>{
        
        console.error(err)
        if (!err.response) {
          this.setState({ error:"Can't connect to admin API, try again later."})
          return
        }
        
        console.error(err.response.data)
        if (err.response.status == 404) {
          this.setState({error:404})
          return;
        } else if (err.response.status === 500) {
          this.setState({ error:"An internal server error occured, try again later."})
          return;
        }
        this.setState({ error:"An unknown error occured, try again later."})
      })
    // } else {
    //   this.setState({loaded:1 })
    // }
  }
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Materials", link : "/materials" },
                { title : "New", link : "#" },
            ],
            loaded:0,
            error:0,
            errormsg:"",
            headers: [{Name:'',X:'',Y:''}],
            data:[
              [''],
            ],
            vertical:[
              {paused:0,thickness:'',cutspeed:'',engraveSpeed:''},
            ],
            top:1,
            bot:1,
            double:0,
            image:'',
            category:'',
            materialGroup:'',
            delivery:'0',
            categories:[],
            materialGroups:[],
            imageCache:"",
            modal_delete:false,
            material:this.props.match.params.id
        };

        this.removeThickness = this.removeThickness.bind(this);
        this.removeSize = this.removeSize.bind(this);
        this.updateVertical = this.updateVertical.bind(this);
        this.updateHeader = this.updateHeader.bind(this);
    }
    addThickness = () => {
      let data = this.state.data;
      let vertical = this.state.vertical;
      //push data to the varible
      vertical.push({paused:0,thickness:'',cutspeed:'',engraveSpeed:''})
      data.push(new Array(this.state.data[0].length).fill(''))
      this.setState({data:data,vertical:vertical})
    }
    addSize = () => {
      let data = this.state.data;
      let headers = this.state.headers;
      //push data to the varible
      headers.push({Name:'',X:'',Y:''})
      for(var i in data){
        data[i].push('')
      }
      this.setState({data:data,headers:headers})
    }

    removeThickness = (event) => {
      const data = this.state.data;
      const vertical = this.state.vertical;

      let x = event.target.getAttribute('data-x')
      if(vertical.length!==1){
        data.splice(x, 1);

        vertical.splice(x, 1);
        this.setState({data:data,vertical:vertical})
      }
    }
    removeSize = (event) => {
      let x = event.target.getAttribute('data-x')
      let data = this.state.data;
      let headers = this.state.headers;
      //push data to the varible
      if(headers.length!==1){
        headers.splice(x, 1);
        for(var i in data){
          data[i].splice(x, 1);
        }
        this.setState({data:data,headers:headers})
      }
    }
    addMaterial = () => {

    }
    update = (event) => {
      if(event.target !== undefined){
        let x = event.target.getAttribute('data-x')
        if(x == "delivery"){
          this.setState({[x]:event.target.value})
        } else {
          this.setState({[x]:event.target.value})
        }

      }
    }

    updateData = (event) => {
      let x = event.target.getAttribute('data-x')
      let y = event.target.getAttribute('data-y')
      let data = this.state.data;
      data[x][y] = event.target.value
      this.setState({data:data})
    }

    updateHeader = (event) => {
      let x = event.target.getAttribute('data-x')
      let y = event.target.getAttribute('data-y')
      let data = this.state.headers;
      data[x][y] = event.target.value
      this.setState({headers:data})
    }

    updateVertical = (event) => {
      let x = event.target.getAttribute('data-x')
      let y = event.target.getAttribute('data-y')
      let data = this.state.vertical;
      if(y === "paused") {
        data[x][y] = event.target.checked?1:0
      } else {
        data[x][y] = event.target.value
      }
      this.setState({vertical:data})
    }

    save = () => {
      const body = this.state.data;
      var data = []
      for(var i in this.state.data){
        data.push({
          category:this.state.category,
          ...this.state.vertical[i],
          delivery:this.state.delivery,
          image:this.state.image,
          sizes:this.state.headers.map(function(el,j){
            return {price:body[i][j],...el}
          }),
          extra:{
            top:this.state.top,
            bot:this.state.bot,
            double:this.state.double,
          },
          materialGroup:this.state.materialGroup
        })
      }

        apiRequest("POST",`material/${this.props.match.params.id}`,{data}).then(res => {
          if(res.status === 200){
            window.location = "/materials"
            return res.data
          }
        }).catch(err=>{
          if (!err.response) {
            this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
            setTimeout(()=>this.setState({error:0}),4500)
            return
          }
          
          console.error(err.response.data)
          if (err.response.status == 404) {
            this.setState({ error:1, errormsg:"Material cannot be found, try refreshing the page."})
            setTimeout(()=>this.setState({error:0}),1500)
            return;
          } else if (err.response.status === 500) {
            this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
            setTimeout(()=>this.setState({error:0}),1500)
            return;
          }
          this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
        })
    }

    delete = () => {
        apiRequest("DELETE",`material/${this.props.match.params.id}`).then(res => {
          if(res.status === 200){
            window.location = "/materials"
            return res.data
          }
        }).catch(err=>{
          console.error(err)
          if (!err.response) {
            this.setState({ error:1, errormsg:"Can't connect to admin API, try again later."})
            setTimeout(()=>this.setState({error:0}),4500)
            return
          }
          
          console.error(err.response.data)
          if (err.response.status == 404) {
            this.setState({ error:1, errormsg:"Material cannot be found, try refreshing the page."})
            setTimeout(()=>this.setState({error:0}),1500)
            return;
          } else if (err.response.status === 500) {
            this.setState({ error:1, errormsg:"An internal server error occured, try again later."})
            setTimeout(()=>this.setState({error:0}),1500)
            return;
          }
          this.setState({ error:1, errormsg:"An unknown error occured, try again later."})
          setTimeout(()=>this.setState({error:0}),1500)
        })
    }
    materialGroup = (value, ctx) => {
      return true;
    }
    clickimg = () => {
      this.setState({imageCache:this.state.image})
    }
    render() {
      if(this.state.loaded === 0){
        if(this.state.error === 404){
          return <Error404/>
        } else if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
              <Alert color="danger">
                {this.state.error}
              </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
      const image = this.state.imageCache;
      const materialGroup = (value, ctx) => {
        if(value === "")
          return " ";
        if(value === "new")
          return `Material name cannot be '${value}'`;
        if(this.state.materialGroups.map((el)=>el.toLowerCase()).includes(value.toLowerCase()))
          return "Material already exists";
        return true;
      }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                      <Breadcrumbs title="Materials" breadcrumbItems={this.state.breadcrumbItems} />
                      <Alert color="danger" isOpen={this.state.error!=0?true:false} >
                        {this.state.errormsg}
                      </Alert>
                      <AvForm onValidSubmit={this.save}>
                      <Row>
                          <Col lg={12}>
                              <Card>
                                  <CardBody>
                                    <Row>
                                      <Col md={2}>
                                      <img className="w-100" alt="material" src={process.env.REACT_APP_IMAGE_BASE_URL+image} onClick={this.clickimg}/>
                                      </Col>
                                      <Col md={10}>
                                      <Row>
                                        <Col md={3}>
                                          <FormGroup className="">
                                            <Label htmlFor="MaterialName">Material name</Label>
                                            <AvField validate={{myValidation: materialGroup}} name="MaterialName" type="text" className="form-control" placeholder="Material name" data-x="materialGroup" value={this.state.materialGroup} onChange={this.update} />
                                          </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                          <FormGroup className="">
                                            <Label htmlFor="discountVal">Category</Label>
                                            <Typeahead id="Category" options={this.state.categories} defaultSelected={[this.state.category]} emptyLabel="New Catergory" name="discountVal" placeholder="Category" inputProps={{"data-x":"category"}} onInputChange={(value,event) => {this.setState({category:value})}} onChange={(value,event) => {if(value.length!==0){this.setState({category:value[0]})}}} />
                                          </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                          <FormGroup className="">
                                            <Label htmlFor="imgurl">Image URL</Label>
                                            <AvInput name="imgurl" type="text" className="form-control" placeholder="Image URL" data-x="image" value={this.state.image} onChange={this.update} required />
                                          </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                          <FormGroup className="">
                                            <Label htmlFor="delivery">Delivery time</Label>
                                            <Input type="select" name="delivery" id="exampleSelect" value={this.state.delivery} data-x="delivery" onChange={this.update} required>
                                              <option value="1">Standard</option>
                                              <option value="0">Fast</option>
                                            </Input>
                                        </FormGroup>
                                        </Col>
                                      </Row>
                                      </Col>
                                    </Row>
                                    <hr className="mb-4 mt-2" />
                                    <Row>
                                      <Col md={3} style={{justifyContent: "flex-end",flexDirection: "column",display: "flex"}}>
                                      <FormGroup className="mb-2" >
                                      <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="px-1 border-0 material-tag-bg" style={{width:"62px"}}>
                                          <span className="m-auto" style={{lineHeight: 0}}>Paused</span>
                                        </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" className="border-0 material-tag-bg" value="Thickness" disabled/>
                                        <Input type="text" className="border-0 material-tag-bg" value="Cut" disabled/>
                                        <Input type="text" className="border-0 material-tag-bg" value="Engrave" disabled/>
                                        {/*<Input type="text" className="border-0 bg-white" value="passes" disabled/>*/}

                                      </InputGroup>
                                      </FormGroup>
                                      </Col>
                                      {this.state.headers.map((data, key) =>
                                        <Col key={key}>
                                          <FormGroup className="mb-2">
                                            {/*<Label htmlFor="discountVal">Delivery time</Label>*/}
                                            <AvInput className="mb-1" name={"Name"+key} type="text" placeholder="Name" data-x={key} data-y="Name" value={data.Name} onChange={this.updateHeader} required/>
                                            <InputGroup>
                                              <AvInput name={"X"+key} type="number" placeholder="X" value={data.X} data-x={key} data-y="X" onChange={this.updateHeader} required/>
                                              <AvInput name={"Y"+key} type="number" placeholder="Y" value={data.Y} data-x={key} data-y="Y" onChange={this.updateHeader} required/>
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>
                                      )}
                                      <div className="flex-01">
                                        <i className="fas fa-minus m-auto hiddenspacer"></i>
                                      </div>
                                    </Row>
                                    {this.state.vertical.map((data, x) =>
                                      <Row key={x}>
                                      <Col md={12}>
                                      <hr className="mb-2 mt-0 " />
                                      </Col>
                                        <Col md={3}>
                                          <FormGroup className="mb-2">
                                            <InputGroup>
                                              <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                  <div className="text-center custom-control custom-switch" dir="ltr">
                                                      <Input type="checkbox" className="custom-control-input" id={"paused"+x} name="test" data-x={x} data-y="paused" checked={data.paused} onChange={this.updateVertical} />
                                                      <Label className="custom-control-label" htmlFor={"paused"+x}></Label>
                                                  </div>
                                                </InputGroupText>
                                              </InputGroupAddon>

                                              <AvInput type="text" name={"thickness"+x} placeholder="Thickness" data-x={x} data-y="thickness" value={data.thickness} onChange={this.updateVertical} required/>
                                              <AvInput type="text" name={"Cut"+x} placeholder="Cut" data-x={x} data-y="cutspeed" value={data.cutspeed} onChange={this.updateVertical} required/>
                                              <AvInput type="text" name={"Engrave"+x} placeholder="Engrave" data-x={x} data-y="engraveSpeed" value={data.engraveSpeed} onChange={this.updateVertical} required/>
                                              {/*<Input type="text" placeholder="passes" data-x={x} data-y="passes" value={data.passes} onChange={this.updateVertical}/>*/}
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>
                                        {this.state.data[x].map((data, y) =>
                                          <Col key={y}>
                                            <FormGroup className="mb-2">
                                            <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                              <InputGroupText>
                                                &euro;
                                              </InputGroupText>
                                            </InputGroupAddon>
                                                <AvInput name={x+""+y} type="text" className="form-control" data-x={x} data-y={y} placeholder="Price" value={data} onChange={this.updateData} required/>
                                                </InputGroup>
                                          </FormGroup>
                                          </Col>
                                        )}
                                        <div className="flex-01">
                              						<i className="fas fa-minus m-auto removeRow" data-x={x} onClick={this.removeThickness}></i>
                              					</div>
                                      </Row>
                                    )}
                                    <Row>
                                      <Col md={12}>
                                      <hr className="mb-2 mt-0 " />
                                      </Col>
                                      <Col md={3}>

                                      </Col>
                                      {this.state.data[0].map((data, y) =>
                                        <Col key={y} className="text-center">
                                          <i className="fas fa-minus m-auto removeCol" data-x={y} onClick={this.removeSize}></i>
                                        </Col>
                                      )}
                                      <div className="flex-01">
                                        <i className="fas fa-minus m-auto hiddenspacer"></i>
                                      </div>
                                    </Row>
                                    <Button color="primary" onClick={this.addThickness} className=" waves-effect waves-light mr-1">Add thickness</Button>
                                    <Button color="primary" onClick={this.addSize} className=" waves-effect waves-light mr-1">Add Size</Button>
                                    <hr className="my-3" />
                                    <Row>
                                      <Col md={4}>
                                        <div className="text-center custom-control custom-switch mb-2" dir="ltr">
                                            <Input type="checkbox" className="custom-control-input" id="customSwitchTop" name="test" checked={this.state.top} onChange={(e) => { this.setState({ top: !this.state.top?1:0 }) }}/>
                                            <Label className="custom-control-label" htmlFor="customSwitchTop">Protective film Top</Label>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="text-center custom-control custom-switch mb-2" dir="ltr">
                                            <Input type="checkbox" className="custom-control-input" id="customSwitchBot" name="test" checked={this.state.bot}  onChange={(e) => { this.setState({ bot: !this.state.bot?1:0 }) }} />
                                            <Label className="custom-control-label" htmlFor="customSwitchBot">Protective film Bottom</Label>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="text-center custom-control custom-switch mb-2" dir="ltr">
                                            <Input type="checkbox" className="custom-control-input" id="customSwitchDouble" name="test" checked={this.state.double} onChange={(e) => { this.setState({ double: !this.state.double?1:0 }) }} />
                                            <Label className="custom-control-label" htmlFor="customSwitchDouble">Double-sided Adhesive foil</Label>
                                        </div>
                                      </Col>
                                    </Row>
                                     <FormGroup>
                                    <Button color="primary" type="submit" className="waves-effect waves-light mr-1">Save</Button>
                                    {this.state.material !== "new"?<Button color="danger" onClick={() => this.setState({ modal_delete: true })} className="waves-effect waves-light mr-1">Delete</Button>:""}
                                     </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                      </AvForm>
                    </Container>
                    <Modal
                      isOpen={this.state.modal_delete}
                      backdrop="static"
                      centered
                      size = "md"
                    >
                      <ModalHeader toggle={() => this.setState({ modal_delete: false })}>
                      Delete material
                      </ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete this material?
                      </ModalBody>
                      <ModalFooter>
                          <Button type="button" color="light" onClick={() => this.setState({ modal_delete: false }) }>Cancel</Button>
                          <Button type="submit" color="danger" onClick={this.delete}>Delete</Button>
                      </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

export default UiCards;
