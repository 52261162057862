import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class LatestTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu : false,
            data:this.props.data || [],
            loaded:0,
            error:0
        }
    }

    clickEv = (to) => {
      this.props.history.push(to)
    }

    render() {
        const data = {
            columns:  [
              {
                text: "Order number",
                dataField: "order",
              },
              {
                text: "Customer",
                dataField: "customer",
              },
              {
                text: "Company",
                dataField: "company",
              },
              {
                text: "Order date",
                dataField: "orderDate",
              },
              {
                text: "Due date",
                dataField: "dueDate",
              },
              {
                text: "Price",
                dataField: "price",
              },
              {
                text: "Status",
                dataField: "status",
              },
            ],
            rows: this.state.data.map((el,i)=>{
              var color;
              var text;
              switch(el.status.toLowerCase()){
                case "new":
                  color = "badge-soft-danger badge font-size-14";
                  text= "New";
                  break;
                case "processing":
                  color = "badge-soft-warning badge font-size-14";
                  text= "Processing";
                  break;
                case "shipped":
                  color = "badge-soft-success badge font-size-14";
                  text= "Shipped";
                  break;
                case "pickup":
                  color = "badge-soft-success badge font-size-14";
                  text= "Picked up";
                  break;
                default:
                  color = "badge-soft-dark badge font-size-14";
                  text= el.status.charAt(0).toUpperCase() + el.status.slice(1);
                  break;
              }
              return{
                id:i,
              order:<Link to={"/orders/"+el.order} className="text-dark font-weight-bold">{el.order}</Link>,
              customer:el.customer,
              company:el.company,
              orderDate:el.orderDate,
              dueDate:el.dueDate,
              price:`€ ${el.price || '0.00'}`,
              status:<div className={color}>{text}</div>,
              link:"/orders/"+el.order
            }})
      };

      const options = {
        // pageStartIndex: 0,
        hideSizePerPage: true,
        hidePageListOnlyOnePage: false,
        sizePerPageList :
          [ {
            text: '5th', value: 7
          }, {
            text: '10th', value: 12
          }, {
            text: 'All', value: data.rows.length
          } ]

      };
      const rowEvents = {
        onClick: (e, row, rowIndex) => {
          this.clickEv(row.link)
        }
      };

        return (
            <React.Fragment>
                                <Card key="1">
                                    <CardBody>
                                        {/*<Dropdown isOpen={this.state.menu} toggle={() => this.setState({menu : !this.state.menu})} className="float-right">
                                            <DropdownToggle tag="i" className="arrow-none card-drop">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right>

                                                <DropdownItem>Sales Report</DropdownItem>

                                                <DropdownItem>Export Report</DropdownItem>

                                                <DropdownItem>Profit</DropdownItem>

                                                <DropdownItem>Action</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>*/}

                                        <h4 className="card-title mb-4">Open Orders</h4>

                                        <div className=" overflow-auto">
                                          <div className='container-fluid'>
                                          <BootstrapTable
                                            keyField='id'
                                            data={ data.rows }
                                            columns={ data.columns }
                                            rowEvents={ rowEvents }
                                            pagination={ paginationFactory(options) }
                                            hover
                                          /></div>
                                        </div>
                                    </CardBody>
                                </Card>
            </React.Fragment>
        );
    }
}

export default withRouter(LatestTransactions);
