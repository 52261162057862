import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table,UncontrolledTooltip, Alert, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup,InputGroup,InputGroupAddon,InputGroupText } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField, AvInput,AvRadioGroup,AvRadio } from "availity-reactstrap-validation";
import 'react-slidedown/lib/slidedown.css'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { SlideDown } from "react-slidedown";

import { logoutUser, apiError } from '../../store/actions';

import { apiRequest, getLoggedInUser } from '../../helpers/backend_helper';
class Customers extends Component {
  componentDidMount() {
    apiRequest("GET","customers").then(res => {
        return res.data
    }).then((data) => {

      this.setState({ data: data, loaded:1})
    }).catch(err=>{
      if (!err.response) {
        this.setState({ error:"Can't connect to admin API, try again later."})
        return
      }
      
      console.error(err.response.data)
      if (err.response.status === 500) {
        this.setState({ error:"An internal server error occured, try again later."})
        return;
      }
      this.setState({ error:"An unknown error occured, try again later."})
    })
  }

    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Het Laser Lokaal", link : "/" },
                { title : "Customers", link : "#" },
            ],
            isAlertOpen : false,
            modal_static : false,
            data : [],
            hidden:true,
            loaded:0,
            error:0,
            errormsg:"",
            trustedPlatform:false,
            modal_data:{
              id:"new",
              name:"",
              username:"",
              email:"",
              type:"admin"
            },
            modal_error:0,
            modal_delete:false,
            delete_error:0,
            delete_user:"",
            signedOut:0
        }
    }

    render() {
      if(this.state.loaded == 0){
        if(this.state.error){
          return (
            <React.Fragment>
            <div  className="page-content">
            <Alert color="danger">
              {this.state.error}
            </Alert>
              </div>
            </React.Fragment>);
        } else {
          return (
            <React.Fragment>
              <div className="preloader">
                <div className="loader">
                  <div className="status">
                    <div role="status" className="spinner-grow text-primary">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>);
        }
      }
        return (
            <React.Fragment>
                <div  className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Customers" breadcrumbItems={this.state.breadcrumbItems} />
                    {this.state.error!=0?<Alert color="danger">
                      {this.state.error}
                    </Alert>:""}
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div  className="table-responsive mt-3">
                                            <Table className="table-centered datatable dt-responsive nowrap " style={{borderCollapse:"collapse", borderSpacing : 0, width:"100%"}}>
                                                <thead  className="thead-light">
                                                    <tr>
                                                        <th>Number of orders</th>
                                                        <th>Email</th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.data.length == 0)?(
                                                      <tr><td colSpan={5} className="text-center">No customers</td></tr>
                                                    ) :
                                                      this.state.data.map((customer, key) =>{
                                                              return <tr key={key}>
                                                                  <td>{customer.num}</td>
                                                                  <td>{customer.email}</td>
                                                                  <td>{customer.firstname} {customer.lastname}</td>
                                                              </tr>
                                                      })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({ statee: state })

export default withRouter(connect(mapStateToProps, { logoutUser, apiError })(Customers));
